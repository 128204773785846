/* eslint-disable no-unused-vars */
import * as IucnActions from '../actions';
import * as IucnServices from '../newServices';

export const iucnRequest = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { id } = state.get('iucnReducer').get('iucnForm');
    try {
      dispatch(IucnActions.fetchingIucnRequest());
      const data = await IucnServices.apiIucn.cbc(id);
      if (data !== null && typeof data.ident !== 'undefined') {
        dispatch(IucnActions.fetchingIucnSuccess(data));
      } else {
        dispatch(IucnActions.fetchingIucnFailure('Se genero un error consultando la información de UICN por favor consulte al administrador!'));
      }
    } catch (error) {
      dispatch(IucnActions.fetchingIucnFailure(error.message));
    } finally {
      //console.log('iucnRequest finally', state.get('iucnReducer'));
    }
  };
};

export const iucnFormChange = (name, value) => {
  return async (dispatch, getState) => {
    // const state = getState().get('iucnReducer'); // getState() normally without immutable
    //console.log('iucnFormChange', name, value);
    try {
      dispatch(IucnActions.setIucnFormChange(name, value));
    } catch (error) {
      console.log(error.message);
    }
  };
};
