/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { map, get, difference, keys, union, fill, indexOf } from 'lodash';
import UIkit from 'uikit';

import Table from './Table';
import { T } from '../../../services/UtilService';
import DataSheet from './../DataSheet';

class TableWithoutStructure extends Component {

  constructor() {
    super();
    this.state = {
      header: null,
      body: [],
    };
    this.header = null;
    this.body = [];
  }

  componentDidMount() {
    const { text, label } = this.props;

    map(get(text, `${label}.${label + 'Atomized'}`), (value, key) => {
      this.header = union(this.header, difference(keys(value.measurementOrFact), ['_id']));
      this.body.push(fill(Array(this.header.length), ''));
      map(value.measurementOrFact, (v, k) => {
        this.body[key][indexOf(this.header, k)] = v;
      });
    });

    this.onMount(function callback(newName) {
      this.setState({ header: this.header, body: this.body });
    });
  }

  addElement(e) {
    e.preventDefault();
    const { body, header } = this.state;
    body.push(fill(new Array(header.length), ''));

    this.setState({
      body,
      modal: (
        <DataSheet.Modal id="a1" title="Hola" open>

        </DataSheet.Modal>
      ),
    });
  }

  render() {
    const { text, label } = this.props;
    const { header, body, modal } = this.state;

    return (
      <form action="">
        <textarea
          className="uk-textarea uk-margin"
          rows="5"
          defaultValue={text[label][label + 'Unstructured']}
        />
        <hr className="uk-margin-remove" />
        <div className="uk-overflow-auto uk-margin-bottom">
          <table className="uk-table uk-table-small uk-table-striped">
            <thead>
              <tr>
                {
                  map(header, (title, key) => (
                    <th key={key} className="uk-width-small">{T(title)}</th>
                  ))
                }
                <th className="uk-width-small"></th>
              </tr>
            </thead>
            <tbody>
              {
                map(body, (value, key) => (
                  <tr key={key}>
                    {
                      map(value, (v, k) => (
                        <td key={k} className="uk-text-break">{v}</td>
                      ))
                    }
                    <td>
                      <ul className="uk-iconnav">
                        {/* Estos span eran a, pero se quitaron por un warning, confirmar si sirven*/}
                        <li><span title="Editar" data-uk-tooltip="delay: 1000" uk-icon="icon: file-edit"></span></li>
                        <li><span title="Eliminar" onClick={() => { UIkit.modal.confirm('¿Está seguro de que desea eliminar permanentemente el elemento especificado?').then(() => { window.alert('Se ha eliminado ' + key); }); }} data-uk-tooltip="delay: 1000" uk-icon="icon: trash"></span></li>
                      </ul>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <button onClick={(e) => { this.addElement(e); }} className="uk-button uk-button-default uk-width-1-1 uk-margin-small-bottom">añadir</button>
        {modal && modal}
      </form>
    );
  }
}

function util(k, x, campo, columns) {
  // console.log("----------------------- Texto sin estructurar + TABLA")
  // console.log(k)
  // console.log(x)
  // console.log(campo)
  // console.log(columns)

  const t = k.substring(0, k.length - 13);
  let u;
  if (x[t + 'Unstructured'] !== undefined) {
    u = (<p>{x[t + 'Unstructured']}</p>);
  }
  // console.log("Se va a enviar a la tabla")
  // console.log(t)
  // console.log(x[t+"Atomized"])
  const e = Table.util(null, x[t + 'Atomized'], columns, campo);

  return (<div>{u}{e}</div>);
}

TableWithoutStructure.util = util;

export default TableWithoutStructure;
