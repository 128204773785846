import React, { Component } from 'react';

import DataSheet from '../../molecules/DataSheet';

class Activity extends Component {
  render() {
    return (
      <div>
        <DataSheet title="Actividad">
          <ul className="uk-list uk-list-divider uk-padding-small uk-margin-remove-top">
            <li>
              <div className="uk-width-1 uk-flex uk-flex-between">
                <span className="uk-text-tertiary">Usuario</span>
                <span className="uk-text-tertiary uk-text-small">18 DE MAYO DE 2018</span>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores quia distinctio facere quidem,
                ducimus nesciunt omnis suscipit voluptatum voluptas ipsam aut odit, cupiditate necessitatibus placeat, impedit fugiat nemo. Inventore, excepturi.
              </p>
            </li>
            <li>
              <div className="uk-width-1 uk-flex uk-flex-between">
                <span className="uk-text-tertiary">Usuario</span>
                <span className="uk-text-tertiary uk-text-small">18 DE MAYO DE 2018</span>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores quia distinctio facere quidem,
                ducimus nesciunt omnis suscipit voluptatum voluptas ipsam aut odit, cupiditate necessitatibus placeat, impedit fugiat nemo. Inventore, excepturi.
              </p>
            </li>
          </ul>
        </DataSheet>
      </div>
    );
  }
}

export default Activity;
