import React, { Component } from 'react';

import CommentItem from '../molecules/CommentItem';

class CommentList extends Component {
  render() {
    return (
      <div>
        <CommentItem />
        <div className="uk-card uk-card-default uk-margin-top">
          <div className="uk-grid-collapse" data-uk-grid="">
            <div className="uk-padding-small uk-padding-remove-bottom uk-grid-small" data-uk-grid="">
              <div>
                <img width="40" src="/static/css/images/slide.png" alt="" />
              </div>
              <div className="uk-flex uk-flex-column">
                <span className="uk-text-small">Author</span>
                <span className="uk-text-muted uk-text-small">@author</span>
              </div>
            </div>
            <div className="uk-width-1 uk-padding-small">
              <textarea className="uk-textarea uk-margin-small" rows="2" placeholder="Escribe aquí tu comentario"></textarea>
              <button className="uk-button uk-button-small uk-button-secondary uk-float-right">publicar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommentList;
