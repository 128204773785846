/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { map, differenceWith, isEqual, findKey, isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';

import Filters from '../Filters';
/* Dispatchers */
import { filtersFormChange } from '../../../dispatchers';

class Taxonomy extends Component {
  constructor(props) {
    super(props);

    this.query = [];
    this.values =
      {
        'Reino': [
          { id: 'kingdom=animalia', label: 'Animales', idQ: 'Animales', labelQ: 'Reino', value: '' },
          { id: 'kingdom=plantae', label: 'Plantas', idQ: 'Plantas', labelQ: 'Reino', value: '' },
          { id: 'kingdom=fungi', label: 'Hongos', idQ: 'Hongos', labelQ: 'Reino', value: '' },
          { id: 'kingdom=bacteria', label: 'Bacterias', idQ: 'Bacterias', labelQ: 'Reino', value: '' },
          { id: 'kingdom=Protozoa', label: 'Protozoos', idQ: 'Protozoos', labelQ: 'Reino', value: '' },
          { id: 'kingdom=archaea', label: 'Arquea', idQ: 'Arquea', labelQ: 'Reino', value: '' },
          { id: 'kingdom=Viruses', label: 'Virus', idQ: 'Virus', labelQ: 'Reino', value: '' },
        ],
        'Grupo de animales': [
          { label: 'Mamíferos', id: 'class=Mammalia' },
          { label: 'Aves', id: 'class=Aves' },
          { label: 'Reptiles', id: 'class=Reptilia' },
          { label: 'Anfibios', id: 'class=Amphibia' },
          { label: 'Peces', id: 'class=Actinopterygii&class=Actinopteri&class=Sarcopterygii&class=Elasmobranchii&class=Chondrichthyes' },
          { label: 'Insectos', id: 'class=Insecta' },
          { label: 'Escarabajos', id: 'order=Coleoptera' },
          { label: 'Mariposas', id: 'order=Lepidoptera' },
          { label: 'Hormigas', id: 'family=Formicidae' },
          { label: 'Abejas', id: 'family=Apidae&family=Colletidae&family=Halictidae' },
          { label: 'Moscas y zancudos', id: 'order=Diptera' },
          { label: 'Arácnidos', id: 'class=Arachnida' },
          { label: 'Moluscos', id: 'phylum=Mollusca' },
          { label: 'Decápodos', id: 'order=Decapoda' },
          { label: 'Equinodermos', id: 'phylum=Echinodermata' },
          { label: 'Esponjas', id: 'phylum=Porifera' },
          { label: 'Corales y afines', id: 'class=Anthozoa&class=Hydrozoa' },
        ],
        'Grupo de plantas': [
          { label: 'Orquídeas', id: 'family=Orchidaceae' },
          { label: 'Magnolias y afines', id: 'family=Magnoliaceae&family=Podocarpaceae&family=Myristicaceae' },
          { label: 'Palmas', id: 'family=Arecaceae' },
          { label: 'Frailejones', id: 'genus=Carramboa,Coespeletia,Espeletia,Espeletiopsis,Libanothamnus,Paramiflos,Ruilopezia,Tamania' },
          { label: 'Cactus', id: 'family=Cactaceae' },
          { label: 'Bromelias, labiadas y pasifloras', id: 'family=Bromeliaceae&family=Labiatae&family=Passifloraceae' },
          { label: 'Fanerógamas', id: 'family=Chrysobalanaceae,Dichapetalaceae,Lecythidaceae' },
          { label: 'Helechos y afines', id: 'class=Polypodiopsida&class=Lycopodiopsida&class=Equisetopsida&class=Psilotopsida&class=Marattiopsida' },
          { label: 'Zamias', id: 'family=Zamiaceae' },
          { label: 'Musgos y afines', id: 'phylum=Bryophyta&phylum=Hepaticophyta&phylum=Anthocerophyta&phylum=Marchantiophyta' },
        ],

      }
    ;

    this.state = {
      data: null,
      search: props.search,
      modified: false,
      values: this.values,
    };
  }
  activeFilters(data) {
    map(data, (item) => {
      const i = findKey(this.state.values, (o) => { return o.id === decodeURIComponent(item); });
      if (i >= 0) {
        this.handleFilter(this.state.values[i].label);
      }
    });
  }
  componentWillMount() {
    // this.props.onRef(this)
  }

  componentDidMount() {
  }

  handleFilter(value) {
    const { onFiltersFormChange } = this.props;
    this.query.push(value);
    this.props.count(this.query);
    this.setState({ data: this.query });
    onFiltersFormChange(value);
  }

  deleteFilter(value) {
    const { onFiltersFormChange } = this.props;
    const position = findKey(this.query, value);
    this.query.splice(position, 1);
    this.props.count(this.query);
    this.setState({ data: this.query });
    onFiltersFormChange(value);
  }

  cleanFilters() {
    this.query = [];
    this.child.handleDrop(false);
    this.props.count(this.query);
    this.setState({ data: this.query });
  }
  /*
  activeFilters(data) {
    const available = ['media_type']
    map(data, (v) => {
      const sp = split(v, '=')
      findIndex(available, (o) => {
        if (o === sp[0]) {
          if (this.state.values) {
            const i = findKey(this.state.values, (o) => { return o.id === sp[0] + '=' + decodeURI(sp[1]) })
            if (i >= 0) {
              this.handleFilter(this.state.values[i])
            }
          }
        }
      })
    })

    this.setState({ modified: true })
  }*/

  render() {

    console.log(this.state.values);

    return (
      <Filters.Base onRef={ref => { this.child = ref; }} title="Taxonomía" handlerFilter={this.state.data} func={(value) => this.deleteFilter(value)} empty={isEmpty(this.state.values)}>
        <div className="uk-child-width-1-1 uk-grid-collapse" data-uk-grid="">

          {
            map(this.state.values, (v, k) =>{
              return <div key={k}
                className="uk-grid-collapse uk-flex-between uk-flex-middle uk-text-small"
              >
                {k!=='' &&
                <h4 className="uk-margin-small-top uk-margin-small-bottom">{k}</h4>
                }
                {
                  map((differenceWith(v, this.query, isEqual)), (value, key) =>
                  {
                    return <label
                      key={key}
                      style={{
                        background: 'url(/images/fondo.png) no-repeat',
                        backgroundSize: value.fraction + '% 100%',
                        cursor: 'pointer',
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        paddingLeft: 2.5,
                        display: 'block',
                      }}
                      onClick={(e) => { e.preventDefault(); this.handleFilter(value); }}
                      title={value.label}
                    >
                      <input className="uk-checkbox uk-margin-small-right" type="checkbox" />{value.label}
                      <span className="uk-float-right">
                        <NumberFormat value={value.value} displayType="text" thousandSeparator />
                      </span>
                    </label>;
                  }
                  )
                }
              </div>;
            }
            )
          }
        </div>
      </Filters.Base>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onFiltersFormChange: (value) => dispatch(filtersFormChange(value)),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(Taxonomy));
