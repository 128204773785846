/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { map/*, filter*/ } from 'lodash';
// import {TileLayer, LayersControl} from 'react-leaflet';

// import License from '../../atoms/License';
import DataSheet from '../../molecules/DataSheet';
import UserList from '../../molecules/lists/UserList';
import OrganizationList from '../../molecules/lists/OrganizationList';

// import * as UtilService from '../../../services/UtilService';
// import * as MapService from '../../../services/MapService';
import * as AlbumService from '../../../services/AlbumService';


class Communiy extends Component {


  constructor(props) {
    super(props);
    this.state = {
      id: null,
    };
  }


  componentWillMount() {
    console.log('voy a consultar: ');
    console.log(this.props);
    AlbumService.getOrgs(this.props.data._id).then(organizaciones => {
      console.log('Lista de organizaciones');
      console.log(organizaciones);
      this.setState({ organizaciones });
      /*
        Buscar la fichas del album
      CatalogService.getRelatedRecords(data.hierarchy[0].family).then(related => {
        this.setState({ related })
      })
      */
    });

  }
  // https://api.gbif.org/v2/map/occurrence/adhoc/1/0/1@1x.png?style=orange.marker&bin=square&squareSize=256&locale=en&q=Pristimantis%20palmeri&advanced=false&srs=EPSG%3A4326


  render() {
    const { data } = this.props;// locale=en&taxonKey=2425033&advanced=false&srs=EPSG%3A4326
    console.log('Los datos que llegan son: ');
    console.log(data);

    console.log('Las organizaciones son: ');
    console.log(this.state.organizaciones);

    return (
      <div>
        <div className="uk-container uk-width-5-6@l uk-width-2-3@xl uk-margin-top">
          <div className="uk-grid-small" data-uk-grid="">
            <div className="uk-width-1-3 uk-padding-right">
              <div className="uk-card uk-card-default">
                <ul className="menu uk-list uk-list-large uk-margin-remove-bottom uk-margin-rigth uk-padding" data-uk-switcher="connect: #comunidad">
                  <li>
                    <a className="uk-link-reset menu">Organizaciones</a>
                  </li>
                  <li>
                    <a className="uk-link-reset menu">Colaboradores</a>
                  </li>
                  <li>
                    <a className="uk-link-reset menu">Participantes</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="uk-width-2-3 menu uk-switcher uk-padding uk-padding-remove-top uk-padding-remove-right" id="comunidad">
              <DataSheet scroll="organizaciones" title="Organizaciones" className="uk-margin-remove-top">
                <OrganizationList organizations={this.state.organizaciones} />
              </DataSheet>
              <DataSheet scroll="revisores" title="Colaborador" className="uk-margin-remove-top">
                <UserList users={data.usuarios} rol='colaborador' />
              </DataSheet>
              <DataSheet scroll="participante" title="Participante" className="uk-margin-remove-top">
                <UserList users={data.usuarios} rol='participante' />
              </DataSheet>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Communiy;
