/* eslint-disable no-unused-vars */
import * as LoginActions from '../actions';
import * as LoginServices from '../newServices';

export const loginRequest = () => {
  return async (dispatch, getState) => {
    const state = getState(); // getState() normally without immutable
    const loginForm = state.get('loginReducer').get('loginForm');
    try {
      dispatch(LoginActions.fetchingLoginRequest());
      const data = await LoginServices.apiLogin.login(loginForm);
      console.log('data', data);
      dispatch(LoginActions.fetchingLoginSuccess(data));
    } catch (error) {
      dispatch(LoginActions.fetchingLoginFailure(error.message));
    }
  };
};

export const logoutRequest = login => {
  return async (dispatch, getState) => {
    // const state = getState.get('loginReducer'); // getState() normally without immutable
    try {
      dispatch(LoginActions.fetchingLogoutRequest());
      const data = await LoginServices.apiLogin.getUser(login);
      dispatch(LoginActions.fetchingLogoutSuccess(data));
    } catch (error) {
      dispatch(LoginActions.fetchingLogoutFailure(error.message));
    }
  };
};

export const loginFormChange = (name, value) => {
  return async (dispatch, getState) => {
    // const state = getState.get('loginReducer'); // getState() normally without immutable
    try {
      dispatch(LoginActions.setLoginFormChange(name, value));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const loginFieldError = fieldError => {
  return async (dispatch, getState) => {
    // const state = getState.get('loginReducer'); // getState() normally without immutable
    try {
      dispatch(LoginActions.setLoginFieldError(fieldError));
    } catch (error) {
      console.log(error.message);
    }
  };
};
