import { fromJS } from 'immutable';
import * as UserActions from '../typesActions';
import dayjs from 'dayjs';

const INITIAL_STATE = fromJS({
  userForm: {
    username: '',
    password: '',
    confirm: '',
    email: '',
    name: '',
    lastname: '',
    photo: 'http://placehold.it/640x480',
    provider: 'local',
    roles: [ '5d7fd678bf1de61a61342e01' ],
    status: 1,
    date: '' + dayjs().format('YYYY[-]MM[-]DD'),
    last_login: '' + dayjs().format('YYYY[-]MM[-]DD'),
    social: {},
  },
  loading: false,
  error: '',
  data: {},
});

const userReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UserActions.FETCHING_CREATE_USER_REQUEST: {
      return state
        .set('loading', true);
    }

    case UserActions.FETCHING_CREATE_USER_SUCCESS: {
      const { data } = payload;
      return state
        .set('loading', false)
        .set('error', '')
        .set('data', data);
    }

    case UserActions.FETCHING_CREATE_USER_FAILURE: {
      const { data } = payload;
      return state
        .set('loading', false)
        .set('data', data);
    }

    case UserActions.SET_USER_FORM_CHANGE: {
      const { name, value } = payload;
      const userForm = (typeof state.get('userForm').password === 'undefined') ? state.get('userForm').toJS() : state.get('userForm');
      console.log('SET_USER_FORM_CHANGE', userForm);
      return state
        .set('userForm', { ...userForm, [name]: value });
    }

    case UserActions.SET_USER_FIELD_ERROR: {
      return state
        .set('error', payload.error);
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
