import { URL_NEW_API_DEV } from '../config/const';
import endpoints from '../config/endpoints';

const wait = () => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, 3000);
});

const fetchParams = (method, data = '') => {
  const body = data ? { body: JSON.stringify(data) } : {};
  return {
    method,
    headers: apiHeaders,
    credentials: 'same-origin',
    ...body,
  };
};

let apiHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: '',
};

export const apiLogin = {
  logout: async ({ token }) => {
    apiHeaders = { ...apiHeaders, Authorization: token };
    try {
      const response = await wait();
      if (!response.ok) {
        throw new Error(response.status_message);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (error) {
      console.error(error);
    }
  },
  login: async (login) => {
    try {
      const response = await fetch(`${URL_NEW_API_DEV}${endpoints.login.signIn}`, fetchParams('POST', { ...login }));
      console.log('login response', response);
      if (!response.ok) {
        throw new Error(response.status_message);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (error) {
      console.error(error);
    }
  },
  recovery: async (recovery) => {
    try {
      const response = await fetch(`${URL_NEW_API_DEV}${endpoints.login.recovery}`, fetchParams('POST', { ...recovery }));
      if (!response.ok) {
        throw new Error(response.status_message);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (error) {
      console.error(error);
    }
  },
  password: async (password) => {
    try {
      const response = await fetch(`${URL_NEW_API_DEV}${endpoints.login.reset}`, fetchParams('POST', { ...password }));
      if (!response.ok) {
        console.log('entro por aca');
        throw new Error(response.status_message);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (error) {
      console.error(error);
    }
  },
};
