/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { USER } from '../config/const';
import { URL_IMG_UICN } from '../config/const';

/* Dispatchers */
import { iucnFormChange, iucnRequest } from '../dispatchers';


import { map } from 'lodash';


/* Components */
import GenericPage from '../components/templates/GenericPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import Summary from '../components/organisms/file/Summary';
import Detail from '../components/organisms/file/Detail';
import Multimedia from '../components/organisms/file/Multimedia';
import RiesgoExtincion from '../components/organisms/file/RiesgoExtincion';
import Slider from '../components/molecules/Slider';
import UICNSlotMini from '../components/atoms/UICNSlotMini';
import * as CatalogService from '../services/CatalogService';
import Loading from '../components/atoms/Loading';
import * as UtilService from '../services/UtilService';

/* Style Components */
import { ContainerUicn } from './styled';

/* Defined Constants */
const uicnImages = {
  NE: URL_IMG_UICN+'NE.png',
  DD: URL_IMG_UICN+'DD.png',
  LC: URL_IMG_UICN+'LC.png',
  NT: URL_IMG_UICN+'NT.png',
  VU: URL_IMG_UICN+'VU.png',
  EN: URL_IMG_UICN+'EN.png',
  CR: URL_IMG_UICN+'CR.png',
  EW: URL_IMG_UICN+'EW.png',
  EX: URL_IMG_UICN+'EX.png',
};

class File extends Component {
  constructor() {
    super();
    this.state = {
      select: 0,
      related: null,
      data: null,
      uicnId: null,
      user: {},
    };
  }

  componentDidMount() {
    const { onIucnFormChange } = this.props;
    const user = JSON.parse(localStorage.getItem(USER));
    const { match } = this.props;
    this.setState({ user, uicnId: match.params.id });
    onIucnFormChange('id', match.params.id);

    this.cargarPagina(match.params.id);
  }

  // componentWillReceiveProps(nextProps) {
  //   console.log('Intentando cargar pagina nueva', nextProps.match.params.id);
  //   // this.cargarPagina(nextProps.match.params.id);
  //   // window.location.href='/file/'+nextProps.match.params.id+'/summary';
  // }

  cargarPagina(id) {
    const { onIucnRequest } = this.props;
    CatalogService.getRecordById(this.props.match.params.id).then(data => {
      this.setState({ data });
      if (data.hierarchy && data.hierarchy.length>0){
        CatalogService.getRelatedRecords(data.hierarchy[0].family).then(related => {
          this.setState({ related });
        });
      }
    }).catch(err =>{
      console.log("Error cargando la ficha: ", err)
      alert("La ficha no existe")
    });

    // Call fields uicn - veda
    onIucnRequest();

    this.activeTab(this.props.match.params.tab);
  }

  goTo(form) {
    this.props.history.push('/edit/' + this.props.match.params.id + '/' + form);
  }

  activeTab(tab, changeUrl = true, id = null) {
    let tabIndex, title, url;
    switch (tab) {
      case 'summary':
        tabIndex = 0;
        title = 'Resumen';
        url = '/file/' + this.props.match.params.id + '/summary';
        break;
      case 'details':
        tabIndex = 1;
        title = 'Detalles';
        url = '/file/' + this.props.match.params.id + '/details';
        break;
      case 'multimedia':
        tabIndex = 2;
        title = 'Multimedia';
        url = '/file/' + this.props.match.params.id + '/multimedia';
        break;
      case 'riesgo':
        tabIndex = 3;
        title = 'Riesgo de Extinción';
        url = '/file/' + this.props.match.params.id + '/riesgo';
        break;
      default:
        tabIndex = 0;
        title = 'Resumen';
        url = '/file/' + this.props.match.params.id + '/summary';
        break;
    }

    // changeUrl && window.history.replaceState({}, title + '- Catálogo de la biodiversidad', url + this.props.location.search);
    this.setState({ select: tabIndex });
  }

  getAmenaza(data) {
    let x = UtilService.route(data, ['threatStatusApprovedInUse', 'threatStatus', 0, 'threatStatusAtomized', 'threatCategory', 'measurementValue']);
    let x2 = UtilService.route(data, ['threatStatusApprovedInUse', 'threatStatus', 0, 'threatStatusAtomized', 'threatCategory']);
    console.log("Amenaza: ", x2)
    x = x.replace(')', '');
    const ps = x.split(' (');
    return ps;
  }
  getTipoAmenaza(data) {
    let x = UtilService.route(data, ['threatStatusApprovedInUse', 'threatStatus', 0, 'threatStatusAtomized', 'threatCategory', 'measurementType']);
    if (x==="MADS"){
      return "Nacional"
    }
    if (x==="UICN"){
      return "Global"
    }
    return x;
  }

  getListaAmenazas(data){
    let lista = UtilService.route(data, ['threatStatusApprovedInUse', 'threatStatus']);
    lista = map(lista, (v, k)=>{
      if (v['threatStatusAtomized'] !== undefined && v['threatStatusAtomized']['appliesTo'] !== undefined && v['threatStatusAtomized']['threatCategory']['measurementValue']!==''){
        return {
          appliesTo: v['threatStatusAtomized']['appliesTo'],
          value: v['threatStatusAtomized']['threatCategory']['measurementValue'],
        }
      }
    })
    return lista
  }

  render() {
    const { data } = this.props;
    // let cbc = '';
    let imageId = '';
    let imageUrl = '';
    let uicnId = '';

    if (typeof data !== 'undefined' && data !== null && Object.entries(data).length !== 0) {
      if((Object.keys(data).length !== 0)) {
        //console.log('data render', data);
        if (typeof data.ident !== 'undefined') {
          const { ident, assessment, _id } = data;
          // cbc = ident.CBC;
          uicnId = _id;
          imageId = assessment.category;
          imageUrl = uicnImages[imageId];
        }
      }
    }
    
    return (<div>
      {(this.state.data && <GenericPage
        titlep="Catálogo de la biodiversidad"
        header={<Header />}
        footer={<Footer />}
        background={UtilService.route(this.state.data, ['imageInfo', 'mainImage'])}
      >
        <div className="uk-background-linear uk-section">
          <div className="uk-container uk-width-5-6@l uk-width-2-3@xl" style={{ marginTop: '60vh' }}>
            <div className="uk-flex uk-flex-right">
              <div className="uk-margin-small uk-overlay-primary" style={{ padding: '5px 10px', 'font-size': '13px', 'text-align': 'right' }}>Fotografía: {UtilService.route(this.state.data, ['imageInfo', 'license'])} {UtilService.route(this.state.data, ['imageInfo', 'rightsHolder'])}</div>
            </div>
            <div className="uk-card uk-card-default">
              <div className="uk-card-body">
                <i>
                  <span className="uk-text-tertiary uk-margin-remove uk-h1">{UtilService.route(this.state.data, ['taxonRecordNameApprovedInUse', 'taxonRecordName', 'scientificName', 'canonicalName', 'simple'])}</span>
                </i>
                <span className="uk-margin-left" style={{ fontWeight: 200 }}>{UtilService.route(this.state.data, ['taxonRecordNameApprovedInUse', 'taxonRecordName', 'scientificName', 'canonicalAuthorship', 'simple'])}</span>
                {UtilService.route(this.state.data, ['taxonRecordNameApprovedInUse', 'taxonRecordName', 'scientificName', 'publishedln', 'simple']) !== '' &&
                  <span className="uk-color-primary uk-text-large" style={{ margin: '0px 5px' }} uk-icon="icon: info" title={UtilService.route(this.state.data, ['taxonRecordNameApprovedInUse', 'taxonRecordName', 'scientificName', 'publishedln', 'simple'])}></span>
                }
                {
                  map(this.getListaAmenazas(this.state.data), (x, key) =>
                    x &&
                    <UICNSlotMini key={key} name="Datos Insuficientes" id={x.value} select={x.value} containerBase="uk-text-center uk-text-middle  thread-badge" content={false} tooltip={x.appliesTo['country']} />
                  )
                }
              </div>

              <div className="uk-card-footer">
                <ul data-uk-switcher={`connect: .uk-switcher; active: ${this.state.select}`} data-uk-grid="">
                  <button onClick={() => this.activeTab('summary')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 0 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Resumen</button>
                  <button onClick={() => this.activeTab('details')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 1 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Detalles</button>
                  <button onClick={() => this.activeTab('multimedia')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 2 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Multimedia</button>
                  {uicnId !== '' && (
                    <button onClick={() => this.activeTab('riesgo')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 3 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Riesgo de extinción</button>
                  )}
                  { this.state.user && <div className="uk-inline">
                    <button type="button" className="uk-button uk-button-link uk-text-tertiary uk-text-bold" style={{ textDecoration: 'none' }}>Editar</button>
                    <div uk-dropdown="" className="uk-dropdown uk-dropdown-bottom-left">
                      <ul className="uk-nav uk-dropdown-nav">
                        <li><a href="javascript:void(0);" onClick={ () => this.goTo('lifeForm') }>LifeForm</a></li>
                        <li><a href="javascript:void(0);" onClick={ () => this.goTo('invasiveness') }>Invasiveness</a></li>
                        <li><a href="javascript:void(0);" onClick={ () => this.goTo('behavior') }>Behavior</a></li>
                        <li><a href="javascript:void(0);" onClick={ () => this.goTo('dispersal') }>Dispersal</a></li>
                        <li><a href="javascript:void(0);" onClick={ () => this.goTo('legislation') }>Legislation</a></li>
                      </ul>
                    </div>
                  </div>}
                </ul>
              </div>
            </div>
          </div>
          <ul className="uk-switcher">
            <Summary data={this.state.data} />
            <Detail data={this.state.data} />
            <Multimedia data={this.state.data} />
            {(uicnId !== '' && this.state.select === 3) && (
              <RiesgoExtincion data={this.state.data} />
            )}
          </ul>
          <div className="uk-section uk-padding-remove uk-background-tertiary uk-margin-top uk-flex uk-flex-center">
            <span className="uk-text-default" style={{ margin: 5 }} uk-icon="icon: bolt; ratio: 1.5"></span>
          </div>
          <div className="uk-section uk-section-small">
            <div className="uk-container uk-width-5-6@l uk-width-2-3@xl">
              <div className="uk-flex uk-flex-column uk-margin">
                <h2 className="uk-margin-left">
                  Fichas relacionadas
                </h2>
                <div className="uk-width-1-4 uk-width-small@s uk-background-primary" style={{ height: 2 }} />
              </div>
              {this.state.data.hierarchy && this.state.data.hierarchy.length > 0 &&
                <Slider data={this.state.related} link={'/search/full?family=' + this.state.data.hierarchy[0].family} />
              }
            </div>
          </div>
        </div>
      </GenericPage>) || <div className="uk-flex uk-flex-center uk-flex-middle uk-padding uk-text-default" data-uk-height-viewport="offset-top: true"><Loading /></div>}
    </div>
    );
  }
}

File.propTypes = {
  iucnForm: PropTypes.shape({
    id: PropTypes.string,
  }),
  data: PropTypes.object.isRequired,
  onIucnFormChange: PropTypes.func.isRequired,
  onIucnRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  //console.log('mapStateToProps file data', state.get('iucnReducer').get('iucnForm'));
  return {
    data: state.get('iucnReducer').get('data'),
    iucnForm: state.get('iucnReducer').get('iucnForm'),
    loading: state.get('iucnReducer').get('loading'),
    error: state.get('iucnReducer').get('error'),
  };
};

const mapDispatchToProps = dispatch => ({
  onIucnFormChange: (name, value) => dispatch(iucnFormChange(name, value)),
  onIucnRequest: () => dispatch(iucnRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(File));
