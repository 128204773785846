import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { URL_SIB_COLOMBIA,URL_LISTAS, URL_PORTAL, URL_CATALOGO, URL_COLECCIONES, URL_STATICS_LOGOS_CANALES_PNG} from '../../config/const';

class Footer extends Component {
  render() {
    return (<div>

      <div className="uk-section uk-section-xsmall" style={{
        backgroundColor: '#00292b',
      }}
      >
        <div className="uk-container uk-width-5-6@l uk-width-2-3@xl">
          <div className="uk-child-width-expand@s uk-flex uk-flex-middle" data-uk-grid="data-uk-grid">
            <div className="uk-text-center uk-text-left@s">
              <a href='https://biodiversidad.co' target="_blank" rel="noopener noreferrer">
                <img src="/static/css/images/sib-logo.svg" alt="" width="300" />
              </a>
            </div>
            <div >
              <div className="uk-flex uk-flex-center uk-flex-right@s uk-flex-middle uk-light uk-grid-small">
                <div>
                  <Link to="/static/sobre_el_portal">Sobre el portal</Link>
                </div>
                <div className="uk-text-lead">|</div>
                <div>
                  <Link to="/static/condiciones_de_uso">Condiciones de uso</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*

      <div className="uk-section uk-section-xsmall uk-section-secondary uk-padding-top-remove uk-text-center" style={{
        backgroundColor: '#000000',
        paddingTop: 5,
        paddingBottom: 5
      }}>
        Creative Commons 4.0 · 2015
      </div>
      */}
    </div>);
  }
}

export default Footer;
