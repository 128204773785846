/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import UIkit from 'uikit';

import Offcanvas from '../organisms/Offcanvas';

class LogicPage extends Component {

  constructor() {
    super();
    this.state = {
      menu: true,
      browser: true,
    };
  }

  componentDidMount() {
    UIkit.offcanvas('#my-id') !== undefined && UIkit.offcanvas('#my-id').show();

    const _this = this;
    UIkit.util.on('#my-id', 'beforeshow', function () {
      _this.setState({ menu: false });
    });

    UIkit.util.on('#my-id', 'beforehide', function () {
      _this.setState({ menu: true });
    });

    const browser = JSON.parse(localStorage.getItem('browser'));
    if (browser === null) {
      setTimeout(() => {
        this.setState({ browser: false });
        localStorage.setItem('browser', true);
      }, 10000);
    } else {
      this.setState({ browser: false });
    }
  }

  componentWillUnmount() {
    UIkit.offcanvas('#my-id').hide();
  }

  render() {

    const { titlep, className, headercustom, sidebar, children, footer } = this.props;

    return (
      <div className={cx('uk-scope uk-offcanvas-content', className)} >
        <Helmet>
          <title>{titlep}</title>
        </Helmet>
        <headercustom.type
          search={headercustom.props.search}
          withSidebar={headercustom.props.withSidebar}
          menu={() => this.setState({ menu: !this.state.menu })}
          openAdvanceSearch={() => { this.setState({ menu: false }); }}
          {...headercustom.props}
        />
        <div className="uk-background-muted" onClick={(e) => { e.preventDefault(); }} >
          <Offcanvas>
            {sidebar}
          </Offcanvas>
          <div className={this.state.menu ? 'width-small' : ''}>
            <div className="uk-padding-small" >{/*data-uk-height-viewport="offset-top: true"*/}
              {children}
            </div>
            {footer}
          </div>
        </div>

      </div>
    );
  }
}

export default LogicPage;
