/* eslint-disable no-unused-vars */
import * as ResetActions from '../actions';
import * as ResetServices from '../newServices';

export const resetRequest = () => {
  return async (dispatch, getState) => {
    const state = getState(); // getState() normally without immutable
    const resetForm = state.get('resetReducer').get('resetForm');
    console.log('resetRequest', resetForm);
    try {
      dispatch(ResetActions.fetchingResetRequest());
      const data = await ResetServices.apiLogin.password(resetForm);
      dispatch(ResetActions.fetchingResetSuccess(data));
    } catch (error) {
      dispatch(ResetActions.fetchingResetFailure(error.message));
    }
  };
};

export const resetFormChange = (name, value) => {
  return async (dispatch, getState) => {
    // const state = getState.get('loginReducer'); // getState() normally without immutable
    try {
      dispatch(ResetActions.setResetFormChange(name, value));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const resetFieldError = error => {
  return async (dispatch, getState) => {
    // const state = getState.get('loginReducer'); // getState() normally without immutable
    try {
      dispatch(ResetActions.setResetFieldError(error));
    } catch (err) {
      console.log(err.message);
    }
  };
};
