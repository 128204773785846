/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';

// Components
import GenericPage from '../components/templates/GenericPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import Summary from '../components/organisms/organizacion/Summary';
import Loading from '../components/atoms/Loading';

// Services
import * as UtilService from '../services/UtilService';
import * as OrganizacionService from '../services/OrganizacionService';

class Organization extends Component {
  constructor() {
    super();
    this.state = {
      select: 0,
      related: null,
      data: null,
    };
  }

  componentWillMount() {
    const { match } = this.props;
    OrganizacionService.getId(match.params.id).then(data => {
      // console.log('Consultando OrganizacionService');
      // console.log(data);
      this.setState({ data });
    });
  }

  render() {
    return (<div>
      {(this.state.data && <GenericPage
        titlep="Catálogo de la biodiversidad"
        header={<Header />}
        footer={<Footer />}
        background={UtilService.route(this.state.data, ['imagen'])}
      >
        <div className="uk-background-linear uk-section">
          <div className="uk-container uk-width-5-6@l uk-width-2-3@xl" >
            <div className="uk-card uk-card-default">
              <div className="uk-card-body">
                <i>
                  <span className="uk-text-tertiary uk-margin-remove uk-h1">{UtilService.route(this.state.data, ['nombre'])}</span>
                </i>
              </div>
              {
                this.state.data.referencia &&
                <div className="uk-overlay uk-padding-remove uk-position-top-right">
                  <span className="uk-background-primary uk-text-default uk-text-bold uk-text-small" style={{ padding: 3, marginRight: 1 }}>
                             ★
                  </span>
                  <span className="uk-background-primary uk-text-default uk-text-bold uk-text-small" style={{ padding: 3 }}>
                    REFERENCIA
                  </span>
                </div>
              }
            </div>
          </div>
          <Summary data={this.state.data} />
        </div>
      </GenericPage>) || <div className="uk-flex uk-flex-center uk-flex-middle uk-padding uk-text-default" data-uk-height-viewport="offset-top: true"><Loading /></div>}
    </div>
    );
  }
}

export default Organization;
