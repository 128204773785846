/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { map } from 'lodash';

import { T, extraerColumnas } from '../../../services/UtilService';

class TextsWithoutStructure extends Component {
  render() {
    return (
      <div>Hello from TextsWithoutStructure!</div>
    );
  }
}

function util(k, x) {
  // console.log("----------------------- Texto sin estructurar + Textos")
  // console.log(k)
  // console.log(x)

  const t = k.substring(0, k.length - 13);
  let u;
  if (x[t + 'Unstructured'] !== undefined) {
    u = (<p>{x[t + 'Unstructured']}</p>);
  }
  // console.log("Se va a enviar la lista de textos")
  // console.log(t, "original", x[t + "Atomized"])

  let e;

  const k0 = extraerColumnas(x[t + 'Atomized'], ['_id', 'ancillaryData', 'measurementOrFact']);

  // console.log(k0)

  e = map(k0, (val0, key0) => {
    const val = x[t + 'Atomized'][val0];
    const key = val0;
    // console.log("#TEXTO SOLO")
    // console.log(val)
    // console.log(typeof val, "{{{")
    // console.log(key)
    if (typeof val === 'object') {

      if(Array.isArray(val)){
        // console.log("Este es un arreglo", val)
        return (<div key={key}><h3>{T(key)}</h3><p>{val}</p></div>);
      }else{
        const vals = extraerColumnas(val, ['_id', 'ancillaryData', 'measurementOrFact']);
        // console.log("val")
        // console.log(val)
        // console.log("vals")
        // console.log(vals)
        const es = map(vals, (val1, key1) => {
          // console.log("TOCA IMPRIMIR: ")
          // console.log(val)
          // console.log(val1)
          // console.log(val[val1])
          // console.log("El tipo es X: ", typeof val[val1])
          // console.log("Lo largo de X es: ",  val[val1].length)
          // let ks = keys(val[val1])



          if (
            (typeof val[val1] === 'object')
                && val[val1] !== null
                && val[val1].length>0){
            return (<div><h3>{T(val1)}</h3> {map(val[val1], (val2, key2) => {
              // /console.log("############## TERCER NIVEL: ")
              // /console.log(typeof val2)
              // /console.log(val2)
              if (typeof val2 === 'object') {
                const ks2 = extraerColumnas(val2, ['_id', 'ancillaryData', 'measurementOrFact']);

                // <h4>{T(key2)}</h4>
                return (<div>
                  {
                    map(ks2, (val3, key3) => {
                      // console.log("MAP")
                      // console.log(ks2)
                      // console.log(val3)

                      // console.log(typeof val2[val3])
                      // console.log(val2[val3])
                      return (<span><h4>{T(val3)}</h4>{val2[val3]} </span>);
                    })
                  }
                </div>);
              }
              if (val2 !== '') {
                return (<div><h4>{T(key2)}</h4><p>{val2}</p></div>);
              }
            })}</div>);
          }
          else if (typeof val[val1] !== 'object' && val[val1]!==''){
            return (<div><h3>{T(val1)}</h3><p>{val[val1]}</p></div>);
          }
          // else if (val[val1].length>0)
          //  return (<div><h3>{T(val1)}</h3><p>{val[val1]}</p></div>)

          // console.log("Hay un problema con ", val[val1])

          return null;
        });
        return es;
      }
    }
    if (val!==''){
      return (<div key={key}><h3>{T(key)}</h3><p>{val}</p></div>);
    }
  });

  return (<div>{u}{e}</div>);
}

TextsWithoutStructure.util = util;

export default TextsWithoutStructure;
