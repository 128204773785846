/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { map /* split /*, filter*/ } from 'lodash';
// import {TileLayer, LayersControl} from 'react-leaflet';

// import License from '../../atoms/License';
import DataSheet from '../../molecules/DataSheet';

// import * as UtilService from '../../../services/UtilService';
// import * as MapService from '../../../services/MapService';
// import * as AlbumService from '../../../services/AlbumService';
// import OrganizationList from '../../molecules/lists/OrganizationList';


class Summary extends Component {


  constructor(props) {
    super(props);
    this.state = {
      id: null,
    };
  }


  componentWillMount() {
    console.log('voy a consultar: ');
    console.log(this.props);
  }
  // https://api.gbif.org/v2/map/occurrence/adhoc/1/0/1@1x.png?style=orange.marker&bin=square&squareSize=256&locale=en&q=Pristimantis%20palmeri&advanced=false&srs=EPSG%3A4326


  render() {
    const { data } = this.props;// locale=en&taxonKey=2425033&advanced=false&srs=EPSG%3A4326
    console.log('Los datos que llegan son al resumen de la organización: ');
    console.log(data);


    return (
      <div>
        <div className="uk-container uk-width-5-6@l uk-width-2-3@xl uk-margin-top">
          <div className="uk-grid-small" data-uk-grid="">
            <div className="uk-width-1">
              <DataSheet scroll="descripcion" title="">
                <img className="uk-align-left uk-margin-remove-adjacent" src={data.logo} alt="" />
                { map(data.descripcion.split('\n'), (d)=>
                  <p>{d}</p>
                )}
              </DataSheet>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Summary;
