/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { URL_STATICS } from '../../config/const';
import { get, map } from 'lodash';


import Amenaza from '../atoms/Amenaza';
import UICNSlotIcon from '../atoms/UICNSlotIcon';
import * as CatalogService from '../../services/CatalogService';


class Card extends Component {
  constructor() {
    super();
    this.state = {
      hover: false,
    };
  }

  imagen(record) {
    console.log();
    if (get(record, ['imageInfo', 'thumbnailImage']))
      return get(record, ['imageInfo', 'thumbnailImage']);
    else if (get(record, ['imageInfo', 'mainImage']))
      return get(record, ['imageInfo', 'mainImage']);
    return URL_STATICS+'sib-resources/images/catalogo/miniatura/png/t-anfibios.png';
  }

  nombre(d){
    let lista = map(d.commonNames, (v, k)=>{
      if (v.language==='Español'){
        return v.name;
      }
    });
    lista = map(d.commonNames, (v, k)=>{
      return v.name;
    });
    return lista[0];
  }

  parte1(s){
    if (s===undefined || s===null)
      return '';
    const x = s.split(' ');
    let r = x[0];
    if (x[1]!==undefined){
      r = x[0]+' '+x[1];
    }
    return r;
  }
  parte2(s){
    if (s===undefined || s===null)
      return '';
    const x = s.split(' ');
    if (x[2]!==undefined){
      return x.slice(2, x.length-1).join(' ');
    }
    return '';
  }
  filtro(t){
    console.log("El t es: ", t)
    let lista = []
    if (t!==undefined){
      for(let i=0;i<t.length;i++){
        if (t[i].threatStatusAtomized.threatCategory.measurementValue!==undefined && t[i].threatStatusAtomized.appliesTo.country!==undefined){
          let id = t[i].threatStatusAtomized.threatCategory.measurementValue.toUpperCase()
          let nivel = t[i].threatStatusAtomized.appliesTo.country.toLowerCase()
          lista.push(<UICNSlotIcon id={id} tooltip={nivel+' '+id} select={id} />)
        }
      }
    }
    return lista
  }

  render() {
    const { data } = this.props;
    // console.log(data)
//          <Link className="uk-link-reset" to={`/file/${data._id}/summary`}><i><h4 className="uk-text-tertiary uk-margin-remove" style={{ fontWeight: 200 }}>{this.parte1(data.scientificNameSimple)}</h4></i></Link>
//          <Link className="uk-link-reset" to={`/file/${data._id}/summary`}><span className="uk-text-small uk-text-small">{this.parte2(data.scientificNameSimple)}</span></Link>
    const parte1 = get(data, "taxonRecordNameApprovedInUse.taxonRecordName.scientificName.canonicalName.simple", this.parte1(data.scientificNameSimple))
    let parte2 = ""
    if (data && data.scientificNameSimple!==undefined){
      get(data, "taxonRecordNameApprovedInUse.taxonRecordName.scientificName.canonicalAuthorship.simple", data.scientificNameSimple.replace(parte1, ''))
    }
    let amenazas = this.filtro(get(data, 'threatStatusApprovedInUse.threatStatus'))
    return (
      <div className="uk-position-small uk-card uk-card-default uk-flex uk-flex-column uk-flex-center" >
        {typeof data.imageInfo !== 'undefined' && <Link to={`/file/${data._id}/summary`} className="uk-text-center">
          <img className="uk-margin-auto uk-card-img" src={CatalogService.getThumb(this.imagen(data))} alt="" />
        </Link>}
        <div className="uk-padding-small uk-inline">
          <Link className="uk-link-reset" to={`/file/${data._id}/summary`}><i><h4 className="uk-text-tertiary uk-margin-remove" style={{ fontWeight: 200 }}>{parte1}</h4></i></Link>
          <Link className="uk-link-reset" to={`/file/${data._id}/summary`}><span className="uk-text-small uk-text-small">{parte2}</span></Link>
          <span style={{"margin":"0 3px"}}>{amenazas}</span>
          {
            /*<Amenaza data={data} small={true}/>*/
          }
        </div>
        <Link className="uk-link-reset" to={`/file/${data._id}/summary`}><div className="uk-padding-small uk-padding-remove-top uk-padding-remove-bottom uk-background-tertiary uk-text-default uk-text-small" >{this.nombre(data)}</div></Link>
      </div>

    );
  }
}

export default Card;
