import * as FiltersActions from '../actions';
import * as FiltersServices from '../newServices';

export const searchExpedienteRequest = values => {
  return async (dispatch) => {
    dispatch(FiltersActions.searchInit());
    try {
      const data = await FiltersServices.apiFilter.search(values);
      if (Array.isArray(data)) {
        dispatch(FiltersActions.searchSuccess(data));
      } else {
        dispatch(FiltersActions.searchError(data));
      }
    } catch (error) {
      dispatch(FiltersActions.searchError('Se genero un error al buscar los expedientes por favor consulte al administrador!'));
    }
  };
};

export const filtersFormChange = (value) => {
  return async (dispatch) => {
    try {
      dispatch(FiltersActions.filterChange(value));
    } catch (error) {
      console.log(error);
    }
  };
};
