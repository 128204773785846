import styled from 'styled-components';

const Container = styled.div`
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  .error {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    color: red;
  }
`;

const LinkStyled = styled.a`
  pointer-events: ${(props) => props.active ? 'none' : 'auto'}
`;

/* background-image: url(${(props) => props.image}); */
const ContainerUicn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
`;

const Paragraph = styled.p`
  text-align: justify;
`;

const TableStyled = styled.table`
  .head {
    border-bottom: 2px solid #ff7847;
    border-top: 2px solid #ff7847;
  }

  .body {
    border-bottom: 2px solid #ff7847;
  }
`;

export { Container, ContainerUicn, Paragraph, TableStyled, LinkStyled };
