/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import {map} from 'lodash';

class UserCard extends Component {
  render() {
    const { data } = this.props;


    return (<div className="uk-card uk-card-default" >
      <div className="uk-padding-small uk-text-center">
        {data.photo &&
        <img className="uk-margin-auto uk-width-1-1" src={data.photo} alt="" />
        }
        {!data.photo &&
        <img className="uk-margin-auto uk-width-1-1" src="/static/css/images/slide.png" alt="" />
        }
        <span className="uk-text-primary uk-margin-top">{data.ubicacion}</span>
        <div>
          <span className="uk-margin-small-right" data-uk-icon="icon: mail"></span>
          <a className="uk-text-tertiary">{data.email}</a>
        </div>
      </div>
    </div>);
  }
}

export default UserCard;
