import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import {map} from 'lodash';

class Albums extends Component {
  render() {
    return (<div>
              Administración de álbums
    </div>);
  }
}

export default Albums;
