/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import map from 'lodash/map';

import Contact from '../molecules/Contact';

class ContactsGrid extends Component {
  render() {
    const { data, grid, minified } = this.props;
    return !minified
      ? (<div className={`uk-child-width-${grid} uk-grid-small`} data-uk-grid="data-uk-grid">
        {map(data, (contact, key) => (<Contact key={key} data={contact} />))}
      </div>)
      : map(data, (contact, key) => (<span key={key}>
        {
          (
            typeof contact.firstName !== 'undefined'
              ? contact.firstName
              : '') + ' ' + (
            typeof contact.lastName !== 'undefined'
              ? contact.lastName
              : '')
        },&nbsp;
      </span>));
  }
}

export default ContactsGrid;
