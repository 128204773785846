/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';

class UserList extends Component {
  render() {
    const data = this.props;
    return (<div>
      { map(data.users, (v, k)=>{
        if (v.rol===data.rol)
          return <div className="uk-grid-small" data-uk-grid="" key={k}>
            <div className="uk-width-1-3" >
              <Link to={'/user/'+v.usuario.username}>
                {v.usuario.photo &&
                <img className="uk-margin-auto uk-width-1-1" src={v.usuario.photo} alt="" />
                }
                {!v.usuario.photo &&
                <img className="uk-margin-auto uk-width-1-1" src="/static/css/images/slide.png" alt="" />
                }
              </Link>
            </div>
            <div className="uk-width-2-3" key={k}>
              <Link to={'/user/'+v.usuario.username}>
                <p>{v.usuario.name} {v.usuario.lastname}</p>
              </Link>
            </div>
          </div>;
      }
      ) }
    </div>);

  }
}

export default UserList;
