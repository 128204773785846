import { URL_API_UICN } from '../config/const';
import endpoints from '../config/endpoints';

const fetchParams = (method, data = '') => {
  const body = data ? { body: JSON.stringify(data) } : {};

  return {
    method,
    headers: apiHeaders,
    credentials: 'same-origin',
    ...body,
  };
};

const apiHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: '',
};

export const apiIucn = {
  cbc: async (id) => {
    try {
      const response = await fetch(`${URL_API_UICN}${endpoints.uicn.getData}/${id}`, fetchParams('GET'));
      if (!response.ok || response.status === 204 || response.status === 403 || response.status === 404 || response.status === 500 ) {
        return response.statusText;
      }
      const data = await response.json();
      if(data === null) {
        return data;
      }
      if (typeof data.error !== 'undefined') {
        return data.error;
      }
      return data;
    } catch (error) {
      console.error(error);
    }
  },
};
