/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import UIkit from 'uikit';

import * as CatalogService from '../../../services/CatalogService';

class TextWithoutStructure extends Component {

  constructor() {
    super();
    this.state = {
      _id: '',
      id_record: '',
      created: '',
      id_user: '',
      version: 0,
      state: '',
      element: '',
      abstract: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { text, id } = this.props;
    this.onMount(function callback(newName) {
      this.setState({
        _id: id,
        id_record: '',
        created: '',
        id_user: 'mmorales@idesoluciones.com',
        version: 0,
        state: '',
        element: '',
        abstract: text,
      });
    });
  }

  handleChange(e) {
    this.setState({ abstract: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { id, label } = this.props;
    CatalogService.setField(id, this.state, 'abstract').then(data => {
      UIkit.notification({
        message: '<span uk-icon=\'icon: check\'></span> Agregado correctamente',
        status: 'success',
        timeout: 2000,
      });
      UIkit.modal(`#${label}`) && UIkit.modal(`#${label}`).hide();
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        {this.state.abstract !== '' &&
          <textarea
            className="uk-textarea uk-margin-bottom"
            rows="5"
            defaultValue={this.state.abstract}
            onChange={this.handleChange}
          />
        }
        <div className="uk-text-right">
          <button className="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Cancelar</button>
          <button className="uk-button uk-button-primary uk-text-bold" type="submit">Guardar</button>
        </div>
      </form>
    );
  }
}

function util(k, x) {
  // console.log("----------------------- Texto sin estructurar")
  // console.log(k)
  // console.log(x)
  let t = k.substring(0, k.length - 13);
  if (x[t + 'Unstructured'] !== undefined) {
    return (<p>{x[t + 'Unstructured']}</p>);
  }
  t = k.substring(0, k.length - 14);
  if (x[t + 'Unstructured'] !== undefined) {
    return (<p>{x[t + 'Unstructured']}</p>);
  }
  return (<p>{x}</p>);
}

TextWithoutStructure.util = util;

export default TextWithoutStructure;
