import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Enlace = ({ data }) => {
  const { url } = data;
  const { iucn } = url;

  return (
    <div className="uk-margin">
      <div id={'enlace'} className={'uk-card uk-card-default uk-panel uk-padding uk-text-break'} style={{ backgroundColor: 'rgba(195, 195, 195, 0.37)', padding: '0px 40px' }}>
        <div className="uk-padding-small type_hierarchy">
          <div className="uk-child-width-1-1">
            <li>
              <div className="uk-child-width-1-2 uk-grid" data-uk-grid="">
                <span className="uk-text-bold uk-first-column">Evaluación en la Lista Roja Global de la UICN:</span>
                <a href={iucn} rel="noopener noreferrer" target="_blank">{iucn}</a>
              </div>
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

Enlace.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.get('iucnReducer').get('data'),
});


export default connect(
  mapStateToProps,
  null
)(withRouter(Enlace));
