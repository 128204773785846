/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { map } from 'lodash';

import License from '../../atoms/License';

import * as UtilService from '../../../services/UtilService';
import * as MapService from '../../../services/MapService';

class Summary extends Component {


  constructor(props) {
    super(props);
    this.state = {
      id: null,
    };
  }

  contarImagenes(d){
    let conteo = 0;
    for(let i=0;i<d.ancillaryDataApprovedInUse.ancillaryData.length;i++){
      const v = d.ancillaryDataApprovedInUse.ancillaryData[i];
      if(v.source){
        const f = v.source.toLowerCase().split('.');
        // console.log("tipo");
        // console.log(f);
        if (
          f[f.length-1]==='jpg' ||
          f[f.length-1]==='png' ||
          f[f.length-1]==='gif'
        ){
          conteo++;
        }
      }
    }
    return conteo;
  }

  slideshow(data){
    const d = data;

    // console.log("Cuantas imágenes vienen")
    // console.log(d.ancillaryDataApprovedInUse.ancillaryData)

    if (this.contarImagenes(d)===0){
      return null;
    }



    return (
      <div className="uk-card uk-card-default">
        <div className="uk-padding-small">
          <span className="uk-h4 uk-text-tertiary uk-text-bold">Imágenes</span>
          <div className="uk-position-relative uk-margin-small-top" data-uk-slideshow="animation: fade">
            <ul className="uk-slideshow-items">
              { d.ancillaryDataApprovedInUse.ancillaryData && (map(d.ancillaryDataApprovedInUse.ancillaryData, (v, k)=> {
                // console.log("Slideshow: ", v)
                return (
                  <li key={k}>
                    <img src={v.source} alt="" uk-cover="" />
                  </li>);
              }))}
            </ul>
            <ul className="uk-thumbnav uk-margin-small-top">
              { d.ancillaryDataApprovedInUse.ancillaryData && (map(d.ancillaryDataApprovedInUse.ancillaryData, (v, k)=> {
                // console.log("Slideshow 2: ", v)
                return (<li key={k} uk-slideshow-item={k} style={{ marginRight: 5 }}><a><img src={v.source} width="100" alt="" /></a></li>);
              }))}
            </ul>
          </div>
        </div>
      </div>);

  }
  // https://api.gbif.org/v2/map/occurrence/adhoc/1/0/1@1x.png?style=orange.marker&bin=square&squareSize=256&locale=en&q=Pristimantis%20palmeri&advanced=false&srs=EPSG%3A4326


  componentWillMount(){
    const consultaMapa = ['scientificName=Ox'];// +UtilService.route(data, ["taxonRecordNameApprovedInUse", "taxonRecordName", "scientificName", "canonicalName", "simple"])]
    /*
    MapService.getOccurrenceList(consultaMapa).then(data => {
      console.log(data);
      this.setState({
        dataMap: data.values,
      });

    });*/

  }

  render() {
    const { data } = this.props;// locale=en&taxonKey=2425033&advanced=false&srs=EPSG%3A4326

    return (
      <div className="uk-container uk-width-5-6@l uk-width-2-3@xl uk-margin-top">
        <div className="uk-grid-small" data-uk-grid="">
          <div className="uk-width-2-3">
            <div className="uk-card uk-card-default">
              <div className="uk-padding-small" >
                <img src={UtilService.route(data, ['imageInfo', 'mainImage'])} alt="Imagen principal" />
              </div>
            </div>
            {UtilService.route(data, ['abstractApprovedInUse', 'abstract']) !== '' && <div className="uk-card uk-card-default uk-margin-top">
              <div className="uk-padding-small" >
                <span className="uk-h4 uk-text-tertiary uk-text-bold">Resumen</span>
                <p>
                  {UtilService.route(data, ['abstractApprovedInUse', 'abstract'])}
                </p>
              </div>
            </div>}
            {/*
            <div className="uk-card uk-card-default uk-height-large">
              { this.state.dataMap &&
                <LiveMap data={this.state.dataMap} popup={(data) => this.openPopup(data)} />
              }
            </div>
            */}
            
            {UtilService.route(data, ['abstractApprovedInUse', 'abstract']) === '' && UtilService.route(data, ['fullDescriptionApprovedInUse','fullDescription','fullDescriptionUnstructured']) !== '' && <div className="uk-card uk-card-default uk-margin-top">
              <div className="uk-padding-small" >
                <span className="uk-h4 uk-text-tertiary uk-text-bold">Resumen</span>
                <p>
                  {UtilService.route(data, ['fullDescriptionApprovedInUse','fullDescription','fullDescriptionUnstructured'])}
                </p>
              </div>
            </div>}
            {UtilService.route(data, ['abstractApprovedInUse', 'abstract']) === '' && UtilService.route(data, ['fullDescriptionApprovedInUse','fullDescription','fullDescriptionUnstructured']) === '' && UtilService.route(data, ['habitatsApprovedInUse', 'habitats', 'habitatUnstructured']) !== '' && <div className="uk-card uk-card-default uk-margin-top">
              <div className="uk-padding-small" >
                <span className="uk-h4 uk-text-tertiary uk-text-bold">Resumen</span>
                <p>
                  {UtilService.route(data, ['habitatsApprovedInUse', 'habitats', 'habitatUnstructured'])}
                </p>
              </div>
            </div>}
          </div>
          <div className="uk-width-1-3">
            <div className="uk-card uk-card-default uk-padding-small uk-flex uk-flex-center">
              <License style={{ width: 100, backgroundColor: 'white' }} id="http://creativecommons.org/licenses/by-nc/4.0/legalcode" />
            </div>
            {UtilService.route(data, ['commonNamesAtomizedApprovedInUse', 'commonNamesAtomized']) !== '' && <div className="uk-card uk-card-default uk-margin-top">
              <div className="uk-padding-small">
                <span className="uk-h4 uk-text-tertiary uk-text-bold">Nombres comunes (es)</span>
                <ul className="uk-list uk-list-bullet">
                  {
                    map(UtilService.route(data, ['commonNamesAtomizedApprovedInUse', 'commonNamesAtomized']), (value, key) => {
                      return value.language === 'Español' ? <li key={key} title={value.language} data-uk-tooltip>{value.name}</li> : null;
                    })
                  }
                </ul>
              </div>
            </div>}
            <div className="uk-card uk-card-default uk-margin-top">
              <div className="uk-padding-small">
                <span className="uk-h4 uk-text-tertiary uk-text-bold">Taxonomía</span>
                <ul className="uk-list uk-list-divider">
                  <li><div className="uk-child-width-1-2" data-uk-grid=""><span className="uk-text-bold">Reino</span><span>{UtilService.route(data, ['hierarchy', 0, 'kingdom'])}</span></div></li>
                  <li><div className="uk-child-width-1-2" data-uk-grid=""><span className="uk-text-bold">Phylum</span><span>{UtilService.route(data, ['hierarchy', 0, 'phylum'])}</span></div></li>
                  <li><div className="uk-child-width-1-2" data-uk-grid=""><span className="uk-text-bold">Clase</span><span>{UtilService.route(data, ['hierarchy', 0, 'classHierarchy'])}</span></div></li>
                  <li><div className="uk-child-width-1-2" data-uk-grid=""><span className="uk-text-bold">Orden</span><span>{UtilService.route(data, ['hierarchy', 0, 'order'])}</span></div></li>
                  <li><div className="uk-child-width-1-2" data-uk-grid=""><span className="uk-text-bold">Familia</span><span>{UtilService.route(data, ['hierarchy', 0, 'family'])}</span></div></li>
                  <li><div className="uk-child-width-1-2" data-uk-grid=""><span className="uk-text-bold">Género</span><span className="scientificName">{UtilService.route(data, ['hierarchy', 0, 'genus'])}</span></div></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    /*
<div className="uk-card uk-card-default uk-margin-top">
              <div className="uk-padding-small">
                <span className="uk-h4 uk-text-tertiary uk-text-bold">Editores</span>
                <table className="uk-table uk-table-hover uk-table-middle uk-table-divider uk-table-small uk-margin-remove">
                  <tbody>
                    <tr>
                      <td><img width="50" src="http://localhost:3000/static/css/images/slide.png" alt="" /></td>
                      <td>Chelsea Otakan</td>
                    </tr>
                    <tr>
                      <td><img width="50" src="http://localhost:3000/static/css/images/slide.png" alt="" /></td>
                      <td>James Anderson</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="uk-card uk-card-default uk-margin-top">
              <div className="uk-padding-small">
                <span className="uk-h4 uk-text-tertiary uk-text-bold">Actividad reciente</span>
                <table className="uk-table uk-table-hover uk-table-middle uk-table-divider uk-table-small uk-margin-remove">
                  <tbody>
                    <tr>
                      <td><img className="uk-preserve-width" width="50" src="http://localhost:3000/static/css/images/slide.png" alt="" /></td>
                      <td>
                        Brunch this weekend?<br />
                        <span className="uk-text-small uk-text-muted">Brendan Lim - ll be in your neighborhood doing errands</span>
                      </td>
                    </tr>
                    <tr>
                      <td><img width="50" src="http://localhost:3000/static/css/images/slide.png" alt="" /></td>
                      <td>
                        Summer BBQ 4<br />
                        <span className="uk-text-small uk-text-muted">to me, Scott, Jennifer--Wish I could come, but I & apos; m out</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="uk-card uk-card-default uk-margin-top">
              <div className="uk-padding-small">
                <span className="uk-h4 uk-text-tertiary uk-text-bold">Colecciones en línea</span>
                <table className="uk-table uk-table-hover uk-table-middle uk-table-divider uk-table-small uk-margin-remove">
                  <tbody>
                    <tr>
                      <td><span className="uk-icon-button" uk-icon="icon: image"></span></td>
                      <td>
                        Aves de Colombia<br />
                        <span className="uk-text-small uk-text-muted">Jan 20, 2014</span>
                      </td>
                    </tr>
                    <tr>
                      <td><span className="uk-icon-button" uk-icon="icon: image"></span></td>
                      <td>
                        Aves de Colombia<br />
                        <span className="uk-text-small uk-text-muted">Jan 20, 2014</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>*/
  }
}

export default Summary;
