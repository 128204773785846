/* eslint-disable react/prop-types */
import React, { Component } from 'react';

class FBoolean extends Component {

  constructor(props) {
    super(props);
    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(event) {
    this.props.onDataChange({ path: this.props.path, value: event.target.value });
  }

  render() {
    return (
      <div>
        {this.props.value.title} <input type="checkbox" name={this.props.value.name} value={this.props.value.value} onClick={this.updateValue} />
      </div>
    );
  }
}

export default FBoolean;
