import React, { Component } from 'react';

import NotFoundBoo from '../components/atoms/NotFoundBoo';
import GenericPage from '../components/templates/GenericPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';

export class NotFound extends Component {
  render() {
    return (
      <GenericPage titlep="Catálogo de la Biodiversidad" header={<Header />} footer={<Footer />}>
        <NotFoundBoo />
      </GenericPage>
    );
  }
}

export default NotFound;
