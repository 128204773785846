/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
// import { map, head, keys, findIndex, concat } from 'lodash';

// import Plinian from '../../molecules/Plinian';
import { T } from '../../../services/UtilService';

class PlinianClass extends Component {
  render() {
    return (
      <div>Hello from PlinianClass!</div>
    );
  }
}

function appliesTo(k, x){
  if(x===undefined) return ;

  let locality, municipality, county, stateProvince, country;

  if([undefined, '', null].indexOf(x.locality)===-1)
    locality = T('locality') + ': ' + x.locality;
  if([undefined, '', null].indexOf(x.municipality)===-1)
    municipality = T('municipality') + ': ' + x.municipality;
  if([undefined, '', null].indexOf(x.county)===-1)
    county = T('county') + ': ' + x.county;
  if([undefined, '', null].indexOf(x.stateProvince)===-1)
    stateProvince = T('stateProvince') + ': ' + x.stateProvince;
  if([undefined, '', null].indexOf(x.country)===-1){
    if(x.country==='Global'){
      country = x.country;
    }else{
      country = T('country') + ': ' + x.country;
    }
  }
  const appliesTo = <li><div className="uk-child-width-1-2" data-uk-grid="">
    <span className="uk-text-bold">{T('appliesTo')}</span><span>{country} {stateProvince} {county} {municipality} {locality}</span>
  </div></li>;
  return appliesTo;
}

function apendiceCITES(k, x){
  if([undefined, '', null].indexOf(x)!==-1) return ;
  if(x.length===0) return ;
  return <li><div className="uk-child-width-1-2" data-uk-grid="">
    <span className="uk-text-bold">{T('apendiceCITES')}</span><span>{x}</span>
  </div></li>;
}

function util(k, x) {
  // console.log("Este es el bloque de texto y luego llaves nombre valor TODO [", k, "]")
  // console.log(x)

  return (<div><p>Contenido por defecto de PlinianClass</p></div>);
}

PlinianClass.util = util;
PlinianClass.appliesTo = appliesTo;
PlinianClass.apendiceCITES = apendiceCITES;

export default PlinianClass;
