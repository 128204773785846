/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable no-case-declarations */
import React, { Component } from 'react';
import { map, findIndex, isEmpty, keys, values, compact, flatten } from 'lodash';

import { T } from '../../../services/UtilService';
// import References from '../../atoms/References';
import Ancillary from '../../atoms/Ancillary';
import Plinian from '../../molecules/Plinian';
import DataSheet from '../../molecules/DataSheet';
import ContactsGrid from '../../organisms/ContactsGrid';

class Detail extends Component {

  constructor() {
    super();
    this.notShow = [
      '_id',
      '__t',
      'id_record',
      'id_user',
      'state',
      'element',
      '__v',
      'created',
      'version',
    ];
  }

  validValue(value) {
    return (value !== '' && value !== null && value !== undefined && !isEmpty(value)) || typeof value === 'boolean' || typeof value === 'number';
  }

  cantidadHijosValidaAncillaryData(objeto) {
    let r = false
    if (
      objeto &&
      objeto.ancillaryData &&
      objeto.ancillaryData.length > 0 &&
      objeto.ancillaryData[0].source &&
      objeto.ancillaryData[0].source.length > 0
    ){
      r = true
    }
    if (
      objeto &&
      objeto.ancillaryData &&
      objeto.ancillaryData.additionalInformation > 0 &&
      objeto.ancillaryData[0].additionalInformation &&
      objeto.ancillaryData[0].additionalInformation.length > 0
    ){
      r = true
    }
    return r
  }

  cantidadHijosValida(objeto) {
    // console.log("cantidadHijosValida", objeto)
    if (typeof objeto !== 'object') {
      // console.log("Soy diferentes de object")
      if (this.validValue(objeto)) {
        // console.log("retornando 1")
        if (findIndex(this.notShow, (o) => { return o === objeto; }) < 0) {
          return 1;
        } else {
          return 0;
        }
      } else {
        // console.log("retornando 0")
        return 0;
      }
    } else if (objeto !== null && !isEmpty(objeto)) {
      // console.log("Soy objeto")
      const datos = map(objeto, (value1, key1) => {
        // console.log("buscando en mis hijos ", value1)
        if (findIndex(this.notShow, (o) => { return o === key1; }) < 0) {
          return this.cantidadHijosValida(value1, key1);
        }
      });
      // console.log("los hijos son: ", datos)
      for (let i = 0; i < datos.length; i++) {
        // console.log("VALIDANDO ", datos[i])
        if (datos[i]) {
          // console.log("RETORNANDO TRUE")
          return 1;
        }
      }
      // console.log("RETORNANDO FALSE")
      return 0;
    }
  }

  /*

  commonNamesAtomizedApprovedInUse

                <div className="uk-padding-small">
                <span className="uk-h4 uk-text-tertiary uk-text-bold">Nombres comunes</span>
                <ul className="uk-list uk-list-bullet">
                  {
                    map(UtilService.route(data, ["commonNamesAtomizedApprovedInUse", "commonNamesAtomized"]), (value, key) => {
                      return value.language !== ''
                        ?
                        <li key={key} title={value.language} data-uk-tooltip>{value.name}</li>
                        :
                        <li key={key} >{value.name}</li>
                    })
                  }
                </ul>
              </div>

  */


  mapRows(value, key, id) {
    let k = '';
    if (key.substring) {
      k = key.substring(0, key.length - 13);
    }

    if (id === 0) {
      // console.log(key)
    }
    switch (key) {
      case 'ancillaryData':
        // console.log("---- Evaluando ", key, value)
        // https://docs.google.com/document/d/1bQ-qKuy-uizMFswN7Qpsbj8HQz26Juuhf4MmIiYN_2A/edit
        return (<blockquote key={key}>Cita bibliografica</blockquote>);




      case 'ancillaryDataApprovedInUse':
        //console.log('ancillaryDataApprovedInUse', value)
        let p = <Plinian type={k} data={value} />;
        
        //console.log("En detail las referencias son: ", p)
        return p

      case 'taxonRecordNameApprovedInUse':
        return <Plinian type={k} data={value} />;

      case 'hierarchyApprovedInUse':

        const v = value[0];
        const oValue = [{
          kingdom: v.kingdom,
          phylum: v.phylum,
          classHierarchy: v.classHierarchy,
          order: v.order,
          family: v.family,
          genus: 'i_'+v.genus,

          specificEpithet: 'i_'+v.specificEpithet,
          infraspecificEpithet: v.infraspecificEpithet,

          taxonRank: T(v.taxonRank),

          classification: v.classification,
          higherClassification: v.higherClassification,
          recommended: v.recommended,
          ancillaryData: v.ancillaryData,
        }];
        // parentTaxon: value.parentTaxon,

        return DataSheet.IgnoreCard.util(key, oValue, ['_id', 'ancillaryData'], true);

      case 'associatedPartyApprovedInUse':
        return <ContactsGrid data={value} grid="1-2" />;

      case 'lifeCycleApprovedInUse':
      case 'fullDescriptionApprovedInUse':
      case 'moreInformationApprovedInUse':
      case 'abstractApprovedInUse':
        return DataSheet.TextWithoutStructure.util(key, value);

      case 'habitatsApprovedInUse':
        return DataSheet.TableWithoutStructure.util('habitatApprovedInUse', value, 'measurementOrFact', ['measurementType', 'measurementValue']);

      case 'distributionApprovedInUse':

        // console.log("Original", key, value)
        // value[1] = value[0]


        return map(value, (value1, key1) => {
          //console.log("value1['distributionAtomized']", value1['distributionAtomized'])
          return [
            DataSheet.TextWithoutStructure.util(key, value1['distributionUnstructured']),
            DataSheet.Table.util(key, value1['distributionAtomized'], ['country', 'stateProvince', 'county']),
          ]
        });

      case 'identificationKeysApprovedInUse':
        return DataSheet.TextWithoutStructure.util(key, value.keys);

      case 'threatStatusApprovedInUse':
        //console.log("threatStatusApprovedInUse", key, value)
        return DataSheet.TablesWithoutStructure.util(key, value, 'threatCategory', ['measurementType', 'measurementValue']);

      case 'behaviorApprovedInUse':
      case 'ecologicalSignificanceApprovedInUse':
      case 'lifeFormApprovedInUse':
      case 'directThreatsApprovedInUse':
        return DataSheet.TableWithoutStructure.util(key, value, 'measurementOrFact', ['measurementType', 'measurementValue']);

      case 'environmentalEnvelopeApprovedInUse':
        return DataSheet.TableWithoutStructure.util(key, value, 'measurementOrFact', ['measurementType', 'measurementValue', 'measurementUnit']);

      case 'reproductionApprovedInUse':
        return DataSheet.TableWithoutStructure.util(key, value, 'measurementOrFact', ['measurementType', 'measurementValue', 'measurementDeterminedBy']);

      case 'molecularDataApprovedInUse':
        // return DataSheet.TableWithoutStructure.util(key, value, "measurementOrFact", ["measurementDeterminedBy"]);
        // return DataSheet.TableWithoutStructure.util(key, value, "relatedTo", [0]);
        return DataSheet.TableObjects.util(key, value, ['relatedTo']);

        // return DataSheet.ArrayListText.util(key, value);


      case 'territoryApprovedInUse':
      case 'dispersalApprovedInUse': // @ToDo: falta el campo distance que tiene un measurementOrFact
        return DataSheet.TextsWithoutStructure.util(key, value);


      case 'usesManagementAndConservationApprovedInUse':
        let val1Temp = []
        for(let i=0;i<value['usesAtomized'].length;i++)
        {
          let keys = Object.keys(value['usesAtomized'][i]).reverse()
          let t = {}
          for(let j=0;j<keys.length;j++){
            t[keys[j]] = value['usesAtomized'][i][keys[j]]
          }
          val1Temp.push(t)
        }
        const value1 = val1Temp;
        const value2 = value['managementAndConservation'];

        //console.log("=====================> usesManagementAndConservationApprovedInUse", value1, value2)

        const usos = DataSheet.ArrayListText.util('usesApprovedInUse', value1);
        const manejo = DataSheet.TableObjects.util('managementAndConservationApprovedInUse', value2, ['_id'], false);
        //console.log("Usos ", usos)
//        console.log("Manejo ", manejo.props.children)
        //console.log("Manejo ", value2)
        //console.log("Cantidad hijos manejo: ", this.cantidadHijosValida(value2))
        if (this.cantidadHijosValida(value2)){
          return <div>{usos} <h3>Manejo y conservación</h3> {manejo}</div>;
        }else{
          return <div>{usos}</div>;
        }

      case 'migratoryApprovedInUse':
      case 'feedingApprovedInUse':
      case 'legislationApprovedInUse':
      case 'invasivenessApprovedInUse':
      case 'populationBiologyApprovedInUse':
      case 'interactionsApprovedInUse':
        return DataSheet.TableObjects.util(key, value, ['_id'], false);

      case 'referencesApprovedInUse':
        return <ol><Plinian type={k} data={value} /></ol>;

      case 'endemicAtomizedApprovedInUse':
        // return DataSheet.ArrayListText.util(key, value);
        // console.log("->>>> ", key, value)
        return map(value, (value1, key1) => {
          // let xa = {"endemicAtomized":value[key1]}
          // console.log("voy a enviar ", value[key1])
          // console.log(xa)
          // return DataSheet.TableObjects.util("endemicApprovedInUse", {"endemicAtomized":value[key1]}, [], true);
          return DataSheet.TextsWithoutStructure.util('endemicApprovedInUse', { 'endemicAtomized': value[key1] });
        });

      // @ToDo: FALTAN
      case 'synonymsAtomizedApprovedInUse':
        return DataSheet.Table.util(key, value, [
          ['synonymName', 'canonicalName', 'simple'],
          ['synonymName', 'canonicalAuthorship', 'simple'],
          ['synonymName', 'publishedln', 'identifier'],
        ]);

      case 'commonNamesAtomizedApprovedInUse':
        return DataSheet.Table.util(key, value, ['language', 'name', ['usedIn', 'distributionUnstructured'], 'usedBy']);



      default:

        if (typeof value !== 'object') {
          if (this.cantidadHijosValida(value)) {
            if (typeof value === 'boolean') {
              return (
                <div key={key}>
                  <span className="uk-text-bold uk-margin-small-right ">{key}: </span>
                  <span>{value ? 'SI' : 'NO'}</span>
                </div>
              );
            }
            return (
              <div key={key}><span className="uk-text-bold uk-margin-small-right">{key}: </span><span>{value}</span></div>
            );
          } else {
            // console.log(this.validValue(value), key, value)
          }
        } else if (value !== null && !isEmpty(value)) {
          if (this.cantidadHijosValida(value)) {
            return (
              <div key={key} className="uk-margin-small-bottom">
                {typeof key !== 'number' && <span className="uk-text-bold uk-margin-small-right uk-title-divider">{key}</span>}
                <div className="uk-margin-left uk-margin-small-top">
                  {
                    map(value, (value1, key1) => {
                      if (findIndex(this.notShow, (o) => { return o === key1; }) < 0) {
                        return this.mapRows(value1, key1);
                      }
                    })
                  }
                </div>
              </div>
            );
          }
        }
        break;
    }
  }




  ordenarDetalles(o) {
    const r = [];
    // Nomenclaruta y clasificacíon
    /**/
    // r.push({ "taxonRecordNameApprovedInUse": o.taxonRecordNameApprovedInUse })
    r.push({ 'hierarchyApprovedInUse': o.hierarchyApprovedInUse });
    r.push({ 'abstractApprovedInUse': o.abstractApprovedInUse });
    r.push({ 'synonymsAtomizedApprovedInUse': o.synonymsAtomizedApprovedInUse });
    r.push({ 'commonNamesAtomizedApprovedInUse': o.commonNamesAtomizedApprovedInUse });

    // Descripción taxonómica
    r.push({ 'fullDescriptionApprovedInUse': o.fullDescriptionApprovedInUse });
    r.push({ 'identificationKeysApprovedInUse': o.identificationKeysApprovedInUse });
    /*
    */

    // Historia natural I
    r.push({ 'lifeFormApprovedInUse': o.lifeFormApprovedInUse });
    r.push({ 'lifeCycleApprovedInUse': o.lifeCycleApprovedInUse });
    r.push({ 'reproductionApprovedInUse': o.reproductionApprovedInUse });
    r.push({ 'feedingApprovedInUse': o.feedingApprovedInUse });
    r.push({ 'dispersalApprovedInUse': o.dispersalApprovedInUse });

    // Historia natural II
    r.push({ 'behaviorApprovedInUse': o.behaviorApprovedInUse });
    r.push({ 'interactionsApprovedInUse': o.interactionsApprovedInUse });
    r.push({ 'molecularDataApprovedInUse': o.molecularDataApprovedInUse });
    r.push({ 'migratoryApprovedInUse': o.migratoryApprovedInUse });
    r.push({ 'ecologicalSignificanceApprovedInUse': o.ecologicalSignificanceApprovedInUse });
    r.push({ 'environmentalEnvelopeApprovedInUse': o.environmentalEnvelopeApprovedInUse });

    // Invasividad
    r.push({ 'invasivenessApprovedInUse': o.invasivenessApprovedInUse });

    // Hábitat y distribución
    r.push({ 'habitatsApprovedInUse': o.habitatsApprovedInUse });
    r.push({ 'distributionApprovedInUse': o.distributionApprovedInUse });
    r.push({ 'endemicAtomizedApprovedInUse': o.endemicAtomizedApprovedInUse });

    // Dinámica poblacional y Amenazas
    r.push({ 'territoryApprovedInUse': o.territoryApprovedInUse });
    r.push({ 'populationBiologyApprovedInUse': o.populationBiologyApprovedInUse });
    r.push({ 'threatStatusApprovedInUse': o.threatStatusApprovedInUse });
    r.push({ 'directThreatsApprovedInUse': o.directThreatsApprovedInUse });
    r.push({ 'legislationApprovedInUse': o.legislationApprovedInUse });

    // Usos, Manejos y Conservación
    r.push({ 'usesManagementAndConservationApprovedInUse': o.usesManagementAndConservationApprovedInUse });

    // Partes asociadas
    r.push({ 'associatedPartyApprovedInUse': o.associatedPartyApprovedInUse });


    // Referencias
    r.push({ 'referencesApprovedInUse': o.referencesApprovedInUse });


    // Información adicional
    r.push({ 'ancillaryDataApprovedInUse': o.ancillaryDataApprovedInUse });
    /*
    */


    return r;
  }


  buildAncillaryData(value, key, id) {
    if (key === 'ancillaryData') {
      // value[0].type="article"
      // let r = <References references={value} fondo={true} />
      // console.log("------------------------------")
      // console.log(value, key, id)
      if (value.length > 0) {
        const x = Ancillary.count(value, key, id);
        if (x > 0) {
          return <Ancillary value={value} key={key} id={id} />;
        }
        return null;
      } else {
        return null;
      }
    }

    if (typeof value !== 'object') {
      if (this.cantidadHijosValida(value)) {
        if (typeof value === 'boolean') {
          return null;
        }
        return null;
      }

    } else if (value !== null && !isEmpty(value)) {
      const r = map(value, (value1, key1) => {
        if (findIndex(this.notShow, (o) => { return o === key1; }) < 0) {
          return this.buildAncillaryData(value1, key1);
        }
      });
      return compact(flatten(r));
    }
  }


  getAncillaryData(value, key, id) {
    if (true || key === 'hierarchyApprovedInUse') {
      // console.log("->          ######   getAncillaryData")
      // console.log(value, key, id)
      const r = this.buildAncillaryData(value, key, id);

      const rs = [];
      if (r) {
        for (let i = 0; i < r.length; i++) {
          // console.log(r[i])
          if (r[i] !== null && r[i] !== undefined) {
            rs.push(r[i]);
          }
        }
        if (rs.length > 0) {
          return rs;
        } else
          return null;
      }
    }
  }


  buildProviderAncillaryData(value, key, id) {
    if (key === 'ancillaryData') {
      // value[0].type="article"
      // let r = <References references={value} fondo={true} />
      // console.log("------------------------------")
      // console.log(value, key, id)
      if (value.length > 0) {
        if (value[0].rightsHolder !== '') {
          return value[0].rightsHolder;
        }
        return null;
      } else {
        return null;
      }
    }

    if (typeof value !== 'object') {
      if (this.cantidadHijosValida(value)) {
        if (typeof value === 'boolean') {
          return null;
        }
        return null;
      }

    } else if (value !== null && !isEmpty(value)) {
      const r = map(value, (value1, key1) => {
        if (findIndex(this.notShow, (o) => { return o === key1; }) < 0) {
          return this.buildProviderAncillaryData(value1, key1);
        }
      });
      return compact(flatten(r));
    }
  }


  getProviderAncillaryData(value, key, id) {
    if (true || key === 'hierarchyApprovedInUse') {
      // console.log("->          ######   getAncillaryData")
      // console.log(value, key, id)
      const r = this.buildProviderAncillaryData(value, key, id);

      const rs = [];
      if (r) {
        // console.log("Contando los finales ", r)
        for (let i = 0; i < r.length; i++) {
          // console.log(r[i])
          if (r[i] !== null && r[i] !== undefined) {
            rs.push(r[i]);
          }
        }
        if (rs.length > 0) {
          return rs;
        } else
          return null;
      }
    }
  }


  makeDataSheets(obj) {
    const obj_ord = this.ordenarDetalles(obj);
    // console.log(obj)
    return map(obj_ord, (valueX, keyX) => {
      const value = values(valueX)[0];
      const key = keys(valueX)[0];
      if (findIndex(this.notShow, (o) => { return o === key; }) < 0) {
        if (
          (
            key.substring(0, key.length - 13) !== 'ancillaryData'
              &&
            this.cantidadHijosValida(value)
          )
        ||
          (
            key.substring(0, key.length - 13) === 'ancillaryData'
              &&
            this.cantidadHijosValidaAncillaryData(value)
          )
        ) {
          if (
            key.substring(key.length - 13) === 'ApprovedInUse' // &&
            // key !== "ancillaryDataApprovedInUse"
            // uk-margin-remove
          ) {
            /* dynamicForm={{ [key.substring(0, key.length - 13)]: value[key.substring(0, key.length - 13)] }} id={obj._id} toReview={this.mapRows}*/
            
            return (
              <DataSheet
                key={key}
                title={T(key.substring(0, key.length - 13))}
                scroll={key}
                references={this.getAncillaryData(value[key.substring(0, key.length - 13)], key, 0)}
                provider={this.getProviderAncillaryData(value[key.substring(0, key.length - 13)], key, 0)}
              >
                <div className={'uk-padding-small type_' + key.substring(0, key.length - 13)}>
                  {this.mapRows(value[key.substring(0, key.length - 13)], key, 0)}
                </div>
                {/* this.ancillary(value[key.substring(0, key.length - 13)], key, 0)*/}
              </DataSheet>

            );
          }
        }
      }
      return;
    });
  }

  render() {
    const { data } = this.props;
    const menu = {
      "hierarchyApprovedInUse": "Nomenclatura y clasificación",
      "fullDescriptionApprovedInUse" : "Descripción taxonómica",
      "lifeCycleApprovedInUse" : "Historia natural I",
      "behaviorApprovedInUse" : "Historia natural II",
      "invasivenessApprovedInUse" : "Invasividad",
      "habitatsApprovedInUse" : "Hábitat y distribución",
      "territoryApprovedInUse" : "Dinámica poblacional y Amenazas",
      "usesManagementAndConservationApprovedInUse" : "Usos, Manejos y Conservación",
      "associatedPartyApprovedInUse" : "Partes asociadas",
      "referencesApprovedInUse" : "Referencias",
      "ancillaryDataApprovedInUse" : "Información adicional",
    }

    return (
      <div className="uk-container uk-width-5-6@l uk-width-2-3@xl uk-margin-top">
        <div className="uk-grid-small" data-uk-grid="">
          <div className="uk-width-1-3">
            {data &&
              <div className="uk-card uk-card-default" data-uk-sticky="offset: 90; bottom: true" style={{ zIndex: 979 }}>
                <div className="uk-card-header">
                  <h4>Índice</h4>
                </div>
                <div className="uk-padding-small">
                  <ul className="uk-list uk-list-large uk-margin-remove-bottom" uk-scrollspy-nav="closest: li; cls: uk-text-primary; offset: 89;">
                    {map(menu, (value, key)=>{
                      let hijos = 0
                      if (key !== 'ancillaryData'){
                        hijos = this.cantidadHijosValida(data[key])
                      }else{
                        hijos = this.cantidadHijosValidaAncillaryData(data[key])
                      }
                      if (hijos!==0){
                        return <li><a className="uk-link-reset" href={"#"+key} data-uk-scroll="offset: 90">{value}</a></li>
                      }else{
                        return <li style={{"color":"#cccccc"}}>{value}</li>
                      }
                    })}
                  </ul>
                </div>
              </div>
            }
          </div>
          <div className="uk-width-expand">
            {
              this.makeDataSheets(data)
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Detail;
