import React, { Component } from 'react';

class UICNSlotIcon extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    let { name, id, select, containerBase, content, tooltip} = this.props
    if (tooltip === undefined) tooltip=''
    let t=''
    let classNameCaja = ''
    if (tooltip) t = "Categoria  UICN "+tooltip
    
    if (select===id){
      //className += 'uk-width-1-5'
      classNameCaja = 'UICN_'+id
    }
    
    return (
      <span class={"caja-"+classNameCaja} uk-tooltip={t}><span className="title uicn-icon"/></span> 
    );
  }
}

export default UICNSlotIcon;
