/* eslint-disable react/prop-types */
import React, { Component } from 'react';

class UsersForm extends Component {

  componentWillMount(){
    this.setState({ u: this.props.usuario, rol: this.props.usuario.roles[0] });
  }
  componentWillReceiveProps(nextProps){
    this.setState({ u: nextProps.usuario, rol: nextProps.usuario.roles[0] });
  }
  handleInput(e, nombre) {
    const u = this.state.u;
    u[nombre] = e.target.value;
    this.setState({ u });
  }
  cambioRol(e){
    const u = this.state.u;
    u['roles'] = [e.target.value];
    this.setState({ u, rol: e.target.value });
  }

  render() {
    return (<div className="uk-padding">
      <fieldset className="uk-fieldset">
        <legend className="uk-legend">Editar usuario {this.state.u.username}</legend>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-horizontal-text">Usuario</label>
          <div className="uk-form-controls">
            <input value={this.state.u.username} onChange={(e) => { this.handleInput(e, 'username'); }} className="uk-input" id="form-horizontal-text" type="text" placeholder="Usuario ..." />
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-horizontal-text">Clave</label>
          <div className="uk-form-controls">
            <input value={this.state.u.password} onChange={(e) => { this.handleInput(e, 'password'); }} className="uk-input" id="form-horizontal-text" type="text" placeholder="Clave ..." />
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-stacked-select">Rol</label>
          <div className="uk-form-controls">
            <select value={this.state.rol} onChange={(e)=>this.cambioRol(e)} className="uk-select" id="form-stacked-select">
              <option value="user">Usuario</option>
              <option value="admin">Admin</option>
            </select>
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-horizontal-text">Correo</label>
          <div className="uk-form-controls">
            <input value={this.state.u.email} onChange={(e) => { this.handleInput(e, 'email'); }} className="uk-input" id="form-horizontal-text" type="text" placeholder="Correo ..." />
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-horizontal-text">Nombres</label>
          <div className="uk-form-controls">
            <input value={this.state.u.name} onChange={(e) => { this.handleInput(e, 'name'); }} className="uk-input" id="form-horizontal-text" type="text" placeholder="Nombres ..." />
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-horizontal-text">Apellidos</label>
          <div className="uk-form-controls">
            <input value={this.state.u.lastname} onChange={(e) => { this.handleInput(e, 'lastname'); }} className="uk-input" id="form-horizontal-text" type="text" placeholder="Apellidos ..." />
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-horizontal-text">Foto</label>
          <div className="uk-form-controls">
            <input value={this.state.u.photo} onChange={(e) => { this.handleInput(e, 'photo'); }} className="uk-input" id="form-horizontal-text" type="text" placeholder="Url foto ..." />
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-horizontal-text">Ubicación</label>
          <div className="uk-form-controls">
            <input value={this.state.u.ubicacion} onChange={(e) => { this.handleInput(e, 'ubicacion'); }} className="uk-input" id="form-horizontal-text" type="text" placeholder="Ubicación ..." />
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-horizontal-text">Perfil</label>
          <div className="uk-form-controls">
            <textarea value={this.state.u.perfil} onChange={(e) => { this.handleInput(e, 'perfil');}} className="uk-textarea" rows="5" placeholder="Perfil"></textarea>
          </div>
        </div>
        <button className="uk-button uk-button-default" onClick={()=>this.props.guardar(this.state.u)} >Guardar</button>
        <button className="uk-button uk-button-default" onClick={()=>this.props.cancelar()} >Cancelar</button>
      </fieldset>
    </div>);
  }
}

export default UsersForm;
