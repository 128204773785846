export default {
  login: {
    signIn: '/auth/local',
    recovery: '/api/user/password/recovery',
    reset: '/api/user/password/token',
  },
  user: {
    create: '/api/user',
    me: '/api/user/me',
    getUsers: '/api/user',
  },
  uicn: { /* VEDA EL PROYECTO - UICN LA ENTIDAD DONDE ESTÁN LOS DATOS */
    getData: '/api/iucn/cbc',
  },
  ficha: {
    getData: '',
  },
  album: {
    getData: '',
  },
  filter: {
    record_search: '/record_search/advanced_search',
  },
};
