import { fromJS } from 'immutable';
import * as RecoverActions from '../typesActions';

const INITIAL_STATE = fromJS({
  recoverForm: {
    email: '',
    system: 'CATALOGO',
  },
  loading: false,
  error: '',
  data: {},
});

const recoverReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case RecoverActions.FETCHING_RECOVER_REQUEST: {
      return state
        .set('loading', true);
    }

    case RecoverActions.FETCHING_RECOVER_SUCCESS: {
      return state
        .set('loading', false)
        .set('error', '')
        .set('data', payload.data);
    }

    case RecoverActions.FETCHING_RECOVER_FAILURE: {
      return state
        .set('loading', false)
        .set('data', payload.data);
    }

    case RecoverActions.SET_RECOVER_FORM_CHANGE: {
      const { name, value } = payload;
      const recoverForm = (typeof state.get('recoverForm').email === 'undefined') ? state.get('recoverForm').toJS() : state.get('recoverForm');
      console.log('SET_RECOVER_FORM_CHANGE', recoverForm);
      return state
        .set('recoverForm', { ...recoverForm, [name]: value });
    }

    case RecoverActions.SET_RECOVER_FIELD_ERROR: {
      return state
        .set('error', payload.error);
    }

    default: {
      return state;
    }
  }
};

export default recoverReducer;
