/* eslint-disable no-unused-vars */
import * as UserActions from '../actions';
import * as UserServices from '../newServices';

export const getUserMeRequest = login => {
  return async (dispatch, getState) => {
    // const state = getState.get('userReducer'); // getState() normally without immutable
    try {
      dispatch(UserActions.fetchingUserMeRequest());
      const data = await UserServices.apiUser.getUser(login);
      dispatch(UserActions.fetchingUserMeSuccess(data));
    } catch (error) {
      dispatch(UserActions.fetchingUserMeFailure(error.message));
    }
  };
};

export const createUserRequest = () => {
  return async (dispatch, getState) => {
    const state = getState(); // getState() normally without immutable
    const userForm = state.get('userReducer').get('userForm');
    try {
      dispatch(UserActions.fetchingCreateUserRequest());
      const data = await UserServices.apiUser.createUser(userForm);
      dispatch(UserActions.fetchingCreateUserSuccess(data));
    } catch (error) {
      dispatch(UserActions.fetchingCreateUserFailure(error.message));
    }
  };
};

export const userFormChange = (name, value) => {
  return async (dispatch, getState) => {
    // const state = getState.get('userReducer'); // getState() normally without immutable
    try {
      dispatch(UserActions.setUserFormChange(name, value));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const userFieldError = fieldError => {
  return async (dispatch, getState) => {
    // const state = getState.get('userReducer'); // getState() normally without immutable
    try {
      dispatch(UserActions.setUserFieldError(fieldError));
    } catch (error) {
      console.log(error.message);
    }
  };
};
