import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Dispatchers */
import { recoverFormChange, recoverFieldError, recoverRequest } from '../dispatchers';

/* Components */
import OauthPage from '../components/templates/OauthPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import {URL_STATICS_LOGOS_CANALES_SVG} from '../config/const';

/* Style Components */
import { Container, LinkStyled } from './styled';

const Recover = ({ recoverForm, error, loading, data, onRecoverFormChange, onRecoverFieldError, onSendRecoverRequest }) => {
  const [classResponse, setClassResponse] = useState('');
  const [response, setResponse] = useState('');

  const handleChange = event => {
    const { name, value } = event.target;
    console.log(name, value);
    onRecoverFormChange(name, value);
    onRecoverFieldError('');
  };

  const handlePasswordRecovery = event => {
    const { email } = recoverForm;
    console.log('recoverForm handlePasswordRecovery', email);
    if(typeof email !== 'undefined') {
      if(email === '') { onRecoverFieldError('El email no puede estar vació'); return false; }
      if(email !== '') onSendRecoverRequest();
    }
    event.stopPropagation();
  };

  useEffect(() => {
    console.log('data', data);
    if(typeof data.response !== 'undefined') {
      if(data.response === 'email sent') { setResponse('Se ha enviado un correo a su cuenta de email con el link de acceso para cambiar su contraseña.'); setClassResponse('uk-alert-success'); }
    } else if(typeof data.error !== 'undefined') { setResponse('Se genero un error posiblemente por que el email no se encuentra asignado!'); setClassResponse('uk-alert-danger'); }
  }, [data]);

  return (
    <OauthPage titlep="Catálogo de la biodiversidad" header={<Header />} footer={<Footer />}>
      <form className="uk-margin-auto uk-margin-auto-vertical uk-width-large uk-card uk-card-default" style={{ borderRadius: '3px' }}>
        <Container>
          <div className="uk-padding-small uk-flex uk-flex-column">
            <img className="uk-margin-auto" src={URL_STATICS_LOGOS_CANALES_SVG+'simbolo-catalogo.svg'} width="200" alt="" />
            <h4 className="uk-margin-remove-top uk-text-center" style={{ fontWeight: 200 }}>Catálogo de la Biodiversidad de Colombia</h4>
            {loading && (
              <div className={'uk-alert-warning uk-alert'} uk-alert="true">
                <a className="uk-alert-close uk-icon uk-close" uk-close="true"></a>
                <p className="response">
                  <span className="uk-margin-small-right" uk-spinner="ratio: 2"></span>
                  {'Cargando..'}
                </p>
              </div>
            )}
            {response !== '' && (
              <div className={`${classResponse} uk-alert`} uk-alert="true">
                <a className="uk-alert-close uk-icon uk-close" uk-close="true"></a>
                <p className="response">{response}</p>
              </div>
            )}
            <div className="uk-margin-small">
              <input className="uk-input" type="email" name="email" placeholder="Ingresa tu correo electrónico" onChange={handleChange} autoFocus />
            </div>
            {error !== '' && (
              <div className="uk-alert-danger uk-alert" uk-alert="true">
                <p className="error">{error}</p>
              </div>
            )}
            <LinkStyled active={error} className="uk-button uk-button-primary uk-width-1-1 uk-margin-small-top uk-text-bold" onClick={(e) => handlePasswordRecovery(e)}>Recuperar contraseña</LinkStyled>
          </div>
        </Container>
      </form>
    </OauthPage>
  );
};

Recover.propTypes = {
  recoverForm: PropTypes.shape({
    email: PropTypes.string,
    system: PropTypes.string,
  }),
  data: PropTypes.object.isRequired,
  loading: PropTypes.oneOf([true, false]).isRequired,
  error: PropTypes.string.isRequired,
  onRecoverFormChange: PropTypes.func.isRequired,
  onRecoverFieldError: PropTypes.func.isRequired,
  onSendRecoverRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const recoverForm = (typeof state.get('recoverReducer').get('recoverForm').email === 'undefined') ? state.get('recoverReducer').get('recoverForm').toJS() : state.get('recoverReducer').get('recoverForm');
  // const data = (typeof state.get('recoverReducer').get('data')._id === 'undefined') ? state.get('recoverReducer').get('data').toJS() : state.get('recoverReducer').get('data');
  console.log('mapStateToProps recover', recoverForm);
  return {
    data: state.get('recoverReducer').get('data'),
    recoverForm,
    loading: state.get('recoverReducer').get('loading'),
    error: state.get('recoverReducer').get('error'),
  };
};

const mapDispatchToProps = dispatch => ({
  onRecoverFormChange: (name, value) => dispatch(recoverFormChange(name, value)),
  onRecoverFieldError: (error) => dispatch(recoverFieldError(error)),
  onSendRecoverRequest: () => dispatch(recoverRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Recover));
