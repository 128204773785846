/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Dispatchers */
import { loginFormChange, loginFieldError, loginRequest } from '../dispatchers';

/* Components */
import OauthPage from '../components/templates/OauthPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';

import { signin, me } from '../auth';

/* Style Components */
import { Container, LinkStyled } from './styled';

const wait = (ms) => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, ms);
});

class SignIn extends Component {

  constructor() {
    super();
    this.state = {
      me: null,
      classResponse: '',
      usernameText: '',
      passwordText: '',
      redirect: '',
    };
  }

  handleChange = event => {
    const { onLoginFormChange, onLoginFieldError } = this.props;
    const { name, value } = event.target;
    onLoginFormChange(name, value);
    onLoginFieldError('');
  };

  handleLogin = async event => {
    const { onLoginRequest, onLoginFieldError, history, loginForm } = this.props;
    const { username, password } = loginForm;
    if(username === '') { onLoginFieldError('El nombre de usuario no puede estar vació'); return false; }
    if(password === '') { onLoginFieldError('La confirmación de password no puede estar vaciá'); return false; }

    if (username !== '' && password !== '' ) {
      onLoginRequest();
      await wait(3000);
      me();
      wait(3000);
    }

    event.stopPropagation();
  };

  handleInput = (e, nombre) => {
    const s = {};
    s[nombre] = e.target.value;
    this.setState(s);
  };

  ingresar(e){
    e.preventDefault();
    signin(this.state)
      .then(data => {
        if (data.error){
          alert(data.error);
          this.setState({ error: data.error });
        }
        else
          me()
            .then(user => {
              window.location.href = '/';
            });
      });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { data, history } = this.props;
    let redirect = '';
    if(prevProps.data !== data) {
      if(typeof data.redirect !== 'undefined') {
        redirect = data.redirect;
        if(redirect === '/home') {
          redirect = 'Re-direccionando a: ' + redirect;
          this.updateState(redirect, 'uk-alert-success');
          await wait(3000);
          history.push('/');
        }
        else {
          redirect = 'Se genero el siguiente error: ' + redirect;
          this.updateState(redirect, 'uk-alert-danger');
        }
      }
    }
  }

  updateState = (redirect, classResponse) => {
    this.setState({ redirect, classResponse });
  }

  render() {
    const { error, loading } = this.props;
    const { classResponse, redirect } = this.state;

    return (
      <OauthPage titlep="Catálogo de la biodiversidad" header={<Header />} footer={<Footer />}>
        <div className="uk-margin-auto uk-margin-auto-vertical uk-width-large uk-card uk-card-default" style={{ borderRadius: '3px' }}>
          <Container>
            <div className="uk-padding-small uk-flex uk-flex-column">
              <img className="uk-margin-auto" src="https://s3.amazonaws.com/sib-resources/images/logos-canales/svg/simbolo-catalogo.svg" width="200" alt="" />
              <h4 className="uk-margin-remove-top uk-text-center" style={{ fontWeight: 200 }}>Bienvenido al Catálogo de la Biodiversidad de Colombia</h4>
              {loading && (
                <div className={'uk-alert-warning uk-alert'} uk-alert="true">
                  <a className="uk-alert-close uk-icon uk-close" uk-close="true"></a>
                  <p className="response">
                    <span className="uk-margin-small-right" uk-spinner="ratio: 2"></span>
                    {'Cargando..'}
                  </p>
                </div>
              )}
              {redirect !== '' && (
                <div className={`${classResponse} uk-alert`} uk-alert="true">
                  <a className="uk-alert-close uk-icon uk-close" uk-close="true"></a>
                  <p className="response">{redirect}</p>
                </div>
              )}
              <div className="uk-margin-small">
                <div className="uk-inline uk-width-1">
                  <span className="uk-form-icon" uk-icon="icon: user"></span>{/* onChange={(e) => { this.handleInput(e, 'username'); }} */}
                  <input className="uk-input" type="text" placeholder="Nombre del usuario" name="username" autoFocus onChange={this.handleChange} />
                </div>
              </div>
              <div className="uk-margin-small">
                <div className="uk-inline uk-width-1">
                  <span className="uk-form-icon" uk-icon="icon: unlock"></span>{/* onChange={(e) => { this.handleInput(e, 'password'); }} */}
                  <input className="uk-input" type="password" placeholder="Contraseña" name="password" onChange={this.handleChange} />
                </div>
              </div>
              {error !== '' && (
                <div className="uk-alert-danger uk-alert" uk-alert="true">
                  <p className="error">{error}</p>
                </div>
              )}
              <LinkStyled active={error} className="uk-button uk-button-primary uk-width-1-1 uk-margin-small-top uk-text-bold" onClick={(e) => this.handleLogin(e)}>Ingresar</LinkStyled>
              <div className="uk-flex uk-flex-around uk-margin-small-top">{/* onClick={(e)=>this.ingresar(e)} */}
                <div><Link to="/oauth/sign-up" className="uk-button uk-button-text">Registrarse</Link></div>
                <div><Link to="/oauth/recover" className="uk-button uk-button-text">Ólvide mis datos</Link></div>
              </div>
            </div>
          </Container>
        </div>
      </OauthPage>
    );
  }
}

SignIn.propTypes = {
  loginForm: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }),
  history: PropTypes.object,
  data: PropTypes.object.isRequired,
  loading: PropTypes.oneOf([true, false]).isRequired,
  error: PropTypes.string.isRequired,
  onLoginFormChange: PropTypes.func.isRequired,
  onLoginFieldError: PropTypes.func.isRequired,
  onLoginRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  // console.log('mapStateToProps SignIn', state.get('loginReducer').get('loginForm'), state.get('loginReducer').get('error'));
  const loginForm = (typeof state.get('loginReducer').get('loginForm').username === 'undefined') ? state.get('loginReducer').get('loginForm').toJS() : state.get('loginReducer').get('loginForm');
  console.log('mapStateToProps SignIn', loginForm);
  return {
    data: state.get('loginReducer').get('data'),
    loginForm,
    loading: state.get('loginReducer').get('loading'),
    error: state.get('loginReducer').get('error'),
  };
};

const mapDispatchToProps = dispatch => ({
  onLoginFormChange: (name, value) => dispatch(loginFormChange(name, value)),
  onLoginFieldError: (error) => dispatch(loginFieldError(error)),
  onLoginRequest: () => dispatch(loginRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignIn));
