/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import DataSheet from '../../molecules/DataSheet';
import Icon from '../../atoms/Icon';
import OrganizationList from '../../molecules/lists/OrganizationList';

import * as OrganizacionService from '../../../services/OrganizacionService';

class Main extends Component {

  componentWillMount(){
    const d = this.props.data;
    OrganizacionService.getByUser(d._id).then(data => {
      // console.log("Consultando Organizacion desde el Usuario: ", d._id)
      // console.log(data)
      this.setState({ organizaciones: data });
      /*
        Buscar la fichas del album
      CatalogService.getRelatedRecords(data.hierarchy[0].family).then(related => {
        this.setState({ related })
      })
      */
    });
  }

  render() {
    const { data } = this.props;
    return (
      <div>
        <DataSheet title="Perfil">
          <div className="uk-padding-small">{data.perfil}</div>
        </DataSheet>
        <DataSheet title="Perfiles asociados">
          <div className="uk-padding-small uk-grid-small" data-uk-grid="">
            <Link to=""><Icon icon="facebook" backColor={['#9a9a9a', '#46528e']} color="#fff" padding="4px 2px 0px 2px" /></Link>
            <Link to=""><Icon icon="google-plus" backColor={['#9a9a9a', '#bf4e43']} ratio="1.3" color="#fff" padding="6px 6px 6px 6px" /></Link>
            <Link to=""><Icon icon="linkedin" backColor={['#9a9a9a', '#097cb6']} ratio="1.3" color="#fff" padding="6px 6px 6px 6px" /></Link>
            <Link to=""><Icon icon="flickr" backColor={['#9a9a9a', '#eaeaea']} ratio="1.3" color="#fff" padding="6px 6px 6px 6px" /></Link>
            <Link to=""><Icon icon="twitter" backColor={['#9a9a9a', '#7ea8da']} ratio="1.3" color="#fff" padding="6px 6px 6px 6px" /></Link>
          </div>
        </DataSheet>
        <DataSheet title="Afilicaciones">
          {this.state && this.state.organizaciones &&
            <OrganizationList organizations={this.state.organizaciones} />
          }
        </DataSheet>
      </div>
    );
  }
}

export default Main;
