import React, { Component } from 'react';
import UIkit from 'uikit';

import AdvancedSearch from './AdvancedSearch';

const styles = {
  fontSize: '1.09rem', height: 60,
};

class Search extends Component {

  handleSearch = () => {
    window.location.href = `/search/basic?${this.url !== null ? this.url : ''}`;
  };

  handleClickButton = e => {
    e.preventDefault();
    UIkit.modal('#navbar-menu').show();
    UIkit.util.on(document, 'hide', '#navbar-menu', function(){
      document.getElementById("btn-open-modal").removeAttribute("hidden");
    });
    e.stopPropagation();
  }

  handleTextFieldKeyDown = event => {
    switch (event.key) {
      case 'Enter':
        console.log('Enter');
        window.location.href = `/search/basic?q=${event.target.value}`;
        event.stopPropagation();
        event.preventDefault();
        break;
      case 'Escape':
        // etc...
        break;
      default: break;
    }
  };

  changeUrl(url) {
    this.url = url;
  }

  render() {
    return (
      <div className="uk-navbar-right uk-visible@m">
        <div className="uk-navbar-item">
          <form className="uk-search uk-search-navbar uk-hidden@l" style={{ backgroundColor:  '#f1f8f7' }}>
            <span uk-search-icon="ratio: 0.8"></span>
            <input className="uk-search-input" type="search" placeholder="Buscar" style={styles} onKeyDown={this.handleTextFieldKeyDown} />
            <button onClick={this.handleClickButton} refer={AdvancedSearch.id} className="uk-form-icon uk-form-icon-flip" uk-icon="icon: settings; ratio: 1" uk-toggle=""/>
          </form>
          <form className="uk-search uk-search-navbar uk-visible@l uk-width-xlarge@l" style={{ backgroundColor:  '#f1f8f7' }}>
            <span uk-search-icon="ratio: 0.8"></span>
            <input className="uk-search-input" type="search" placeholder="Buscar" style={styles} onKeyDown={this.handleTextFieldKeyDown} />
            <button id="btn-open-modal" onClick={this.handleClickButton} refer={AdvancedSearch.id} className="uk-form-icon uk-form-icon-flip" uk-icon="icon: settings; ratio: 1" uk-toggle=""/>
          </form>
        </div>
        <AdvancedSearch />
      </div>
    );
  }
}

class Responsive extends Component {
  render() {
    return (
      <div className="uk-navbar-dropdown uk-navbar-dropdown-width-2" data-uk-drop="mode: click; cls-drop: uk-navbar-dropdown; boundary: !nav">
        <div className="uk-grid-small uk-flex-middle" data-uk-grid>
          <div className="uk-width-expand">
            <form className="uk-search uk-search-navbar uk-width-1-1">
              <input className="uk-search-input" type="search" placeholder="Buscar" style={styles} autoFocus />
              <button href="#navbar-menu" className="uk-form-icon uk-form-icon-flip uk-navbar-dropdown-close" uk-icon="icon: settings; ratio: 1" uk-toggle="" />
            </form>
          </div>
          <div className="uk-width-auto">
            <button className="uk-navbar-dropdown-close" data-uk-close />
          </div>
        </div>
      </div>
    );
  }
}

Search.Responsive = Responsive;

export default Search;
