/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import GenericPage from '../components/templates/GenericPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import Slider from '../components/molecules/Slider';
import * as CatalogService from '../services/CatalogService';
import { URL_STATICS_CATALOGO } from '../config/const';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: NaN,
      data: null,
      last_records: null,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentWillMount() {
    console.log("UNSAFE_componentWillMount")
    this.updateDimensions();
    CatalogService.getLastRecords().then(data => {
      this.setState({
        data: {
          'ESPECIES': 54748,
        },
        last_records: data,
      });
    }).catch(err =>{
      console.log(err)
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    this.setState({
      height: (window.innerHeight / 2),
    });
  }

  render() {
    return (
      <GenericPage titlep="Catálogo de la biodiversidad" header={<Header />} footer={<Footer />}>
        <div className="uk-position-relative uk-light" data-uk-slideshow={`min-height: ${this.state.height}; max-height: ${this.state.height}`}>
          <ul className="uk-slideshow-items">
            <li>
              <img src="static/css/images/img2.jpg" alt="" style={{ 'cursor':'pointer' }} data-uk-cover="data-uk-cover" onClick={()=>{this.props.history.push('/file/595d0ee22f4fd7bc7f3a0cf1/summary');}} />
              <div className="uk-overlay uk-overlay-primary uk-position-top-right uk-position-small uk-padding-small uk-text-center uk-light uk-visible@s">
                <p className="uk-text-small">Hevea brasiliensis, CC by-nc</p>
              </div>
            </li>
            <li>
              <img src="static/css/images/img3.jpg" alt="" style={{ 'cursor':'pointer' }} data-uk-cover="data-uk-cover" onClick={()=>{this.props.history.push('/file/5f8775014568a5b84027a6d0/summary');}} />
              <div className="uk-overlay uk-overlay-primary uk-position-top-right uk-position-small uk-padding-small uk-text-center uk-light uk-visible@s">
                <p className="uk-text-small">Hyloscirtus denticulentus, Esteban Alzate CC by-nc-nd</p>
              </div>
            </li>
            <li>
              <img src="static/css/images/img4.jpg" alt="" style={{ 'cursor':'pointer' }} data-uk-cover="data-uk-cover" onClick={()=>{this.props.history.push('/file/5ce6f5b78fe61bf73db10a52/summary');}} />
              <div className="uk-overlay uk-overlay-primary uk-position-top-right uk-position-small uk-padding-small uk-text-center uk-light uk-visible@s">
                <p className="uk-text-small">Anopheles albimanus, James Gathany CC by-nc</p>
              </div>
            </li>
            <li>
              <img src="static/css/images/img5.jpg" alt="" style={{ 'cursor':'pointer' }} data-uk-cover="data-uk-cover" onClick={()=>{this.props.history.push('/file/56d6fd153c16479905cba919/summary');}} />
              <div className="uk-overlay uk-overlay-primary uk-position-top-right uk-position-small uk-padding-small uk-text-center uk-light uk-visible@s">
                <p className="uk-text-small">Tyto alba, Stevie B. CC by-nc</p>
              </div>
            </li>
          </ul>
          <button className="uk-position-center-left uk-position-small uk-visible@s" data-uk-slidenav-previous="ratio: 1.5" uk-slideshow-item="previous" />
          <button className="uk-position-center-right uk-position-small uk-visible@s" data-uk-slidenav-next="ratio: 1.5" uk-slideshow-item="next" />
          <div className="uk-position-center uk-position-medium">
            <div className="uk-flex uk-flex-column">
              <Link to="/search/basic" style={{
                textDecoration: 'none',
              }}
              >
                <div className="uk-overlay uk-padding-small uk-text-center" style={{
                  border: 'solid 1.5px #fff',
                  cursor: 'pointer',
                  backgroundColor: (
                    !this.state.hover
                      ? 'rgba(0,0,0,0.2)'
                      : 'rgba(255,255,255,0.2)'),
                  transition: 'background-color 0.3s ease',
                }} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}
                >
                  <h3 className="uk-text-bold">EXPLORA EL CATÁLOGO</h3>
                </div>
              </Link>
            </div>
          </div>
          <div className="uk-position-bottom-center uk-position-medium">
            <ul className="uk-dotnav uk-flex-nowrap uk-margin-bottom">
              <li uk-slideshow-item="0">
                <a>Item 1</a>
              </li>
              <li uk-slideshow-item="1">
                <a>Item 1</a>
              </li>
              <li uk-slideshow-item="2">
                <a>Item 1</a>
              </li>
              <li uk-slideshow-item="3">
                <a>Item 1</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="uk-flex uk-flex-center uk-background-tertiary" style={{ marginTop: -35 }} />
        <div className="uk-section uk-background-tertiary">
          <div className="uk-container uk-width-5-6@l uk-width-2-3@xl">
            <div className="uk-flex uk-flex-column">
              <h2 className="uk-margin-left uk-text-default">
                Explora a través de grupos biológicos
              </h2>
              <div className="uk-width-1-4 uk-width-small@s uk-background-default" style={{ height: 2 }} />
            </div>
            <div className="uk-flex uk-flex-center uk-margin-top uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-auto@m uk-text-center" data-uk-grid="data-uk-grid">
              <Link className="uk-link-reset" to="/search/full?phylum=Acanthocephala&phylum=Annelida&phylum=Arthropoda&phylum=Brachiopoda&phylum=Bryozoa&phylum=Chaetognatha&phylum=Cnidaria&phylum=Echinodermata&phylum=Gastrotricha&phylum=Mollusca&phylum=Myxozoa&phylum=Nematoda&phylum=Onychophora&phylum=Platyhelminthes&phylum=Porifera&phylum=Rotifera&phylum=Sipuncula">
                <img className="uk-card uk-card-default uk-card-body uk-border-circle uk-padding-remove" src={URL_STATICS_CATALOGO+'inicio/png/h-invertebrados.png'} alt="" />
                <p className="uk-text-center uk-text-default">INVERTEBRADOS</p>
              </Link>
              <Link className="uk-link-reset" to="/search/full?phylum=Chordata">
                <img className="uk-card uk-card-default uk-card-body uk-border-circle uk-padding-remove" src={URL_STATICS_CATALOGO+'inicio/png/h-vertebrados.png'} alt="" />
                <p className="uk-text-center uk-text-default">VERTEBRADOS</p>
              </Link>
              <Link className="uk-link-reset" to="/search/full?kingdom=Plantae">
                <img className="uk-card uk-card-default uk-card-body uk-border-circle uk-padding-remove" src={URL_STATICS_CATALOGO+'inicio/png/h-plantas.png'} alt="" />
                <p className="uk-text-center uk-text-default">PLANTAS</p>
              </Link>
              <Link className="uk-link-reset" to="/search/full?kingdom=Fungi">
                <img className="uk-card uk-card-default uk-card-body uk-border-circle uk-padding-remove" src={URL_STATICS_CATALOGO+'inicio/png/h-hongos.png'} alt="" />
                <p className="uk-text-center uk-text-default">HONGOS</p>
              </Link>
              <Link className="uk-link-reset" to="/search/full?kingdom=Chromista">
                <img className="uk-card uk-card-default uk-card-body uk-border-circle uk-padding-remove" src={URL_STATICS_CATALOGO+'inicio/png/h-chromista.png'} alt="" />
                <p className="uk-text-center uk-text-default">CHROMISTA</p>
              </Link>
              <Link className="uk-link-reset" to="/search/full?kingdom=Archaea&kingdom=Bacteria&kingdom=incertae%20sedis&kingdom=Protozoa&kingdom=Viruses">
                <img className="uk-card uk-card-default uk-card-body uk-border-circle uk-padding-remove" src={URL_STATICS_CATALOGO+'inicio/png/h-otros.png'} alt="" />
                <p className="uk-text-center uk-text-default">OTROS</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="uk-section uk-section-small">
          <div className="uk-container uk-width-5-6@l uk-width-2-3@xl">
            <div className="uk-flex uk-flex-column uk-margin">
              <h2 className="uk-margin-left">
                Fichas recientes
              </h2>
              <div className="uk-width-1-4 uk-width-small@s uk-background-primary" style={{ height: 2 }} />
            </div>
            {console.log("lastt----------------------->",this.state.last_records)}
            <Slider data={this.state.last_records} />
          </div>
        </div>

        <div className="uk-section uk-light" style={{ backgroundColor: '#00a8b4' }}>
          <div className="uk-container uk-width-5-6@l uk-width-2-3@xl">
            <div className="uk-flex uk-flex-center uk-flex-middle uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid="data-uk-grid">
              {/*<div className="uk-text-center">*/}
              {/*  <h1 className="uk-margin-remove">¿Qué esperas?</h1>*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <div className="uk-overlay uk-text-center uk-padding-small uk-link-reset" style={{ border: 'solid 1.5px #fff' }} >*/}
              {/*    <Link to="/static/sobre_el_portal">*/}
              {/*      <h2 className="uk-margin-remove">ÚNETE AL CATÁLOGO</h2>*/}
              {/*    </Link>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </GenericPage>);
  }
}

export default Home;
