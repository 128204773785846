import { map, findIndex, compact, flattenDeep } from 'lodash';

import g from '../static/glossary.json';

export function T(t) {
  if (g[t] !== undefined && g[t] !== '') {
    return g[t];
  }
  return t;
}

export function route(data, route) {
  let temp = data;
  if(temp === null || temp[route[0]] === null)
    return '';
  for (let i = 0; i < route.length; i++) {
    temp = temp[route[i]];
    if (temp === undefined) {
      return '';
    }
  }
  return temp;
}

export function extraerColumnas(ds, ignorar) {
  // console.log("Se esta buscando en TABLA IGNORAR ", ds)

  let columns = map(ds, (d, k) => {
    // console.log("comparando ", k)
    if ((findIndex(ignorar, (i) => { return (i === k); })) === -1) return k;
  });
  columns = compact(flattenDeep(columns));
  // console.log("Se organiza ", columns)
  return columns;
}

export function extraerColumnas2(ds, ignorar) {
  // console.log("Se esta buscando en TABLA IGNORAR ", ds)

  let columns = map(ds, (d, k) => {
    // console.log("comparando ", k)
    if ((findIndex(ignorar, (i) => { return (i === k); })) !== -1) return k;
  });
  columns = compact(flattenDeep(columns));
  // console.log("Se organiza ", columns)
  return columns;
}

export function toCamelCase(str) {
  str = str.replace(/([A-Z])/g, (x, y) => {
    return '_' + y.toLowerCase();
  }).replace(/^_/, '');

  return str;
}
