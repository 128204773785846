/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';

import Amenaza from '../atoms/Amenaza';

import * as CatalogService from '../../services/CatalogService';
import * as UtilService from '../../services/UtilService';

class SearchListItem extends Component {

  nombre(d){
    let lista = map(d.commonNames, (v, k)=>{
      if (v.language==='Español'){
        return v.name;
      }
    });
    lista = map(d.commonNames, (v, k)=>{
      return v.name;
    });
    return lista[0];
  }

  render() {
    const { data } = this.props;

    return (
      <div className="uk-box-shadow-small uk-box-shadow-hover-medium uk-card uk-card-default uk-grid-collapse uk-margin-small" data-uk-grid>
        <div className="uk-overlay uk-padding-remove uk-position-top-right">
          <Amenaza data={data} />
        </div>
        <div className="uk-card-media-left uk-cover-container">
          <Link to={`/file/${data._id}/summary`} className="uk-text-center">
            <div className="uk-inline uk-light uk-transition-toggle" style={{ cursor: 'pointer', width: '100%' }}>
              {typeof data.imageInfo !== 'undefined' && typeof data.imageInfo.mainImage !== 'undefined' && <img width="210" src={CatalogService.getThumb(data.imageInfo.mainImage)} alt="" />}
              <div className="uk-overlay-primary uk-position-cover uk-transition-fade">
                <div className="uk-position-center">
                  <span uk-overlay-icon=""></span>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="uk-width-expand">
          <div className="uk-padding-small">
            <Link to={`/file/${data._id}/summary`} className="uk-link-reset"><i><h4 className="uk-text-tertiary uk-margin-remove" style={{ fontWeight: 200 }}>{data.scientificNameSimple}</h4></i></Link>
            <Link to={`/file/${data._id}/summary`} className="uk-link-reset"><p>{UtilService.route(data, ['fullDescriptionApprovedInUse', 'fullDescription', 'fullDescriptionUnstructured']).substring(0, 300)} ...</p></Link>
            <Link to={`/file/${data._id}/summary`} className="uk-link-reset"><div className="uk-background-tertiary uk-text-default" style={{ padding: 5 }} >{this.nombre(data)}</div></Link>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchListItem;
