import React, { Component } from 'react';
import { map } from 'lodash';

import { T, route } from '../../../services/UtilService';

class Table extends Component {
  render() {
    return (
      <div>Hello from Table!</div>
    );
  }
}

function util(k, v, columns, campo) {

  if (v.length===0){
    return <div></div>;
  }

  let result = map(v, (value, key) => {
      return map(columns, (columna, key2) => {
        let v;

        if(Array.isArray(columna)){
          if (campo === undefined)
            v = route(value, columna);
          else
            v = route(value[campo], columna);

        }else{
          if (campo === undefined)
            v = value[columna];
          else
            v = value[campo][columna];

          if (typeof v === Array) {
            v = map(v, (val, ke) => {
              return <span key={ke}>{val}, </span>;
            });
          }

        }
        return v;
      }).join('')
    }).join('')
  if (result===''){
    return <></>
  }

  return (
    <table className="uk-table uk-table-striped">
      <thead>
        <tr>
          {map(columns, (c, key) => {
            if(Array.isArray(c)){
              return (<th key={key}>{T(c.join('.'))}</th>);
            }else{
              return (<th key={key}>{T(c)}</th>);
            }
          })}
        </tr>
      </thead>
      <tbody>
        {map(v, (value, key) => {
          return (
            <tr key={key}>
              {map(columns, (columna, key2) => {
                let v;

                if(Array.isArray(columna)){
                  if (campo === undefined)
                    v = route(value, columna);
                  else
                    v = route(value[campo], columna);

                }else{
                  if (campo === undefined)
                    v = value[columna];
                  else
                    v = value[campo][columna];

                  if (typeof v === Array) {
                    v = map(v, (val, ke) => {
                      return <span key={ke}>{val}, </span>;
                    });
                  }

                }
                return <td key={key2}>{v}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

Table.util = util;

export default Table;
