import React, { Component } from 'react';

class Loading extends Component {
  render() {
    return (<div className="uk-flex uk-text-center uk-flex-column">
      <div style={{ color: 'white' }} data-uk-spinner="ratio: 1.5"></div>
      <span>Cargando...</span>
    </div>);
  }
}

export default Loading;
