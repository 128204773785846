import * as AlbumActions from '../typesActions';

export const fetchingAlbumRequest = () => {
  return {
    type: AlbumActions.FETCHING_ALBUM_REQUEST,
  };
};

export const fetchingAlbumSuccess = data => {
  return {
    type: AlbumActions.FETCHING_ALBUM_SUCCESS,
    payload: { data },
  };
};

export const fetchingAlbumFailure = data => {
  return {
    type: AlbumActions.FETCHING_ALBUM_FAILURE,
    payload: { data },
  };
};

export const setAlbumFormChange = (name, value) => {
  console.log('actions', name, value);
  return {
    type: AlbumActions.SET_ALBUM_FORM_CHANGE,
    payload: { name, value },
  };
};
