import React, { Component } from 'react';
import { map } from 'lodash';

import TextWithoutStructure from './TextWithoutStructure';

class ListWithoutStructure extends Component {
  render() {
    return (
      <div>Hello from ListWithoutStructure!</div>
    );
  }
}

function util(k, x) {
  // console.log("----------------------- LISTA Texto sin estructurar")
  // console.log("----------------------- Texto sin estructurar")
  // console.log(k)
  // console.log(x)
  // let t = k.substring(0, k.length - 13)

  const r = map(x, (valor, key) => {
    // console.log("Mandando a texto_sinEstructura", k, valor)
    const rt = <div key={key}>{TextWithoutStructure.util(k, valor)}</div>;
    // console.log(rt)
    return rt;
  });
  return (<div>{r}</div>);
}
ListWithoutStructure.util = util;

export default ListWithoutStructure;
