/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import map from 'lodash/map';

import SearchListItem from '../molecules/SearchListItem';

class SearchList extends Component {
  render() {
    return (
      <div className="uk-flex uk-flex-column">
        {this.props.data &&
          map(this.props.data, (value, key) => (
            <SearchListItem key={key} data={value} />
          ))
        }
      </div>
    );
  }
}

export default SearchList;
