import React, { Component } from 'react';

import DataSheet from '../../molecules/DataSheet';

class Albums extends Component {
  render() {
    return (
      <div>
        <DataSheet title="Álbumes">
          <ul className="uk-list uk-list-divider uk-padding-small uk-margin-remove-top">
            <li>
              <div data-uk-grid="">
                <div className="uk-width-1-3 uk-text-center">
                  <img className="uk-margin-auto" src="/static/css/images/collections.png" alt="" />
                </div>
                <div className="uk-width-expand uk-flex uk-flex-column">
                  <span className="uk-h5 uk-text-tertiary uk-margin-remove">Mis favoritos</span>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nobis iste molestiae fugit esse quia adipisci labore veniam nulla,
                  </p>
                  <div><span className="uk-margin-small-right" data-uk-icon="icon: user"></span><span>Usuario registrado ...</span></div>
                </div>
              </div>
            </li>
            <li>
              <div data-uk-grid="">
                <div className="uk-width-1-3 ">
                  <img src="/static/css/images/collections.png" alt="" />
                </div>
                <div className="uk-width-expand uk-flex uk-flex-column">
                  <span className="uk-h5 uk-text-tertiary uk-margin-remove">Nombre de la colección</span>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nobis iste molestiae fugit esse quia adipisci labore veniam nulla,
                  </p>
                  <div><span className="uk-margin-small-right" data-uk-icon="icon: user"></span><span>Usuario registrado ...</span></div>
                </div>
              </div>
            </li>
          </ul>
        </DataSheet>
      </div>
    );
  }
}

export default Albums;
