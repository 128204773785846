/* eslint-disable-next-line */
import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';

class Subnav extends Component {
  render() {
    return (
      <ul className="uk-subnav uk-subnav-divider uk-text-bold uk-flex uk-flex-center uk-margin-small uk-hidden@s">
        <li>
          <a>Explorar</a>
          <div data-uk-dropdown="mode: click">
            <ul className="uk-nav uk-navbar-dropdown-nav">
              <li><Link to="/specimens">Especímenes</Link></li>
              <li><Link to="/collections">Colecciones</Link></li>
              <li><Link to="/providers">Publicadores</Link></li>
            </ul>
          </div>
        </li>
        <li><NavLink to="/sing-in" activeClassName="uk-active">Registrarse</NavLink></li>
        <li><NavLink to="/log-in" activeClassName="uk-active">Ingresar</NavLink></li>
      </ul>
    );
  }
}

export default Subnav;
