/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { map, head, keys, findIndex, concat } from 'lodash';

import Plinian from '../../molecules/Plinian';

class CardValue extends Component {
  render() {
    return (
      <div>Hello from CardValue!</div>
    );
  }
}

function util(k, x) {
  const t = k.substring(0, k.length - 13);
  const ignorar = ['_id', 'ancillaryData'];

  const lista = map(x[t + 'Atomized'], (d, k) => {
    const h = head(keys(d), (d1, k1) => {
      // console.log("La llave es: ", d1, k1)
      if ((findIndex(ignorar, (i) => { return (i === d1); })) === -1)
        return <Plinian type={'head_' + d1} data={d[d1]} />;
    });


    const l = map(keys(d), (d1, k1) => {
      // console.log("La llave es: ", d1, k1)
      if ((findIndex(ignorar, (i) => { return i === d1; })) === -1)
        return <Plinian type={d1} data={d[d1]} />;
    });
    return concat(h, l);
  });

  const u = <p>{x[t + 'Unstructured']}</p>;
  const e = <table className="uk-table uk-table-striped">{lista}</table>;


  return (<div><p>Este es el bloque de texto y luego llaves nombre valor TODO</p>
    {u}
    {e}
  </div>);
}

CardValue.util = util;

export default CardValue;
