import INITIAL_STATE from '../store/initialState';

const permissionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

export default permissionReducer;
