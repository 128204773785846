/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Dispatchers */
import { userFormChange, userFieldError, createUserRequest } from '../dispatchers';

/* Components */
import OauthPage from '../components/templates/OauthPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';

import { signup/* , me*/ } from '../auth';

/* Style Components */
import { Container, LinkStyled } from './styled';

const wait = (ms) => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, ms);
});

class SignUp extends Component {

  constructor() {
    super();
    this.state = {
      me: null,
      classResponse: '',
      username: '',
      password: '',
      email: '',
      name: '',
      lastname: '',
      created: false,
      response: '',
    };
  }

  handleChange = event => {
    const { onUserFormChange, onUserFieldError } = this.props;
    const { name, value } = event.target;
    onUserFormChange(name, value);
    onUserFieldError('');
  };

  handleRegister = async event => {
    const { onUserFieldError, onCreateUserRequest, userForm } = this.props;
    const { name, lastname, username, email, password, confirm } = userForm;
    if(name === '') { onUserFieldError('Los nombres no pueden estar vacíos'); return false; }
    if(lastname === '') { onUserFieldError('Los apellidos no pueden estar vacíos'); return false; }
    if(username === '') { onUserFieldError('El nombre de usuario no pueden estar vacío'); return false; }
    if(email === '') { onUserFieldError('El email no puede estar vació'); return false; }
    if(password === '') { onUserFieldError('El password no puede estar vació'); return false; }
    if(confirm === '') { onUserFieldError('La confirmación de password no puede estar vaciá'); return false; }
    if(password !== confirm) { onUserFieldError('La confirmación de contraseña no coincide'); return false; }
    if (name !== '' && lastname !== '' && username !== '' && email !== '' && password !== '' && confirm !== '' ) {
      onCreateUserRequest();
      await wait(3000);
    }

    event.stopPropagation();
  };

  handleInput(e, nombre) {
    const s = {};
    s[nombre] = e.target.value;
    this.setState(s);
  }

  crear(e) {
    console.log('Crear ', this.state);
    if(this.state.password !== '') {
      if (this.state.password!==this.state.password2){
        alert('Las constraseñas no coinciden');
        return;
      }
      if (this.state.password.length<6){
        alert('por favor ingrese una contraseña mayor a 6 caracteres');
        return;
      }

      signup(this.state)
        .then(data => {
          // console.log("El resultado es ", data)
          if (data.error){
            this.setState({ error: data.error });
          }else if(data.message){
            alert('El usuario no se encuentra disponible, probablemente ya se registró.');
          }else{
            this.setState({
              username: '',
              password: '',
              email: '',
              name: '',
              lastname: '',
            });
            alert('Usuario registrado correctamente, puede ingresar al sistema');
            window.location.href = '/oauth/sign-in';
          }
        })
        .catch(err => console.error(err));
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { data, history } = this.props;
    console.log('componentDidUpdate', prevProps, prevState);
    console.log('componentDidUpdate props', data);
    if(prevProps.data !== data) {
      let msg = '';
      if(typeof data.created !== 'undefined') {
        if(data.created) {
          msg = 'El usuario ha sido creado con el ID: ' + data._id;
          this.updateState(data.created, msg, 'uk-alert-success');
          await wait(3000);
          history.push('/oauth/sign-in');
        }
      } else if(typeof data.error !== 'undefined') {
        msg = 'El usuario no fue creado debido al siguiente error: ' + data.error;
        this.updateState(true, msg, 'uk-alert-danger');
      }
    }
  }

  updateState = (created, response, classResponse) => {
    this.setState({ created, response, classResponse });
  }

  render() {
    const { error, loading } = this.props;
    const { classResponse, created, response } = this.state;

    return (
      <OauthPage titlep="Catálogo de la biodiversidad" header={<Header />} footer={<Footer />}>
        <div className="uk-margin-auto uk-margin-auto-vertical uk-width-large uk-card uk-card-default">
          <Container>
            <div className="uk-padding-small uk-flex uk-flex-column">
              <img className="uk-margin-auto" src="https://s3.amazonaws.com/sib-resources/images/logos-canales/svg/simbolo-catalogo.svg" width="200" alt="" />
              <h4 className="uk-margin-remove-top uk-text-center" style={{ fontWeight: 200 }}>Formulario de registro</h4>
              {loading && (
                <div className={'uk-alert-warning uk-alert'} uk-alert="true">
                  <a className="uk-alert-close uk-icon uk-close" uk-close="true"></a>
                  <p className="response">
                    <span className="uk-margin-small-right" uk-spinner="ratio: 2"></span>
                    {'Cargando..'}
                  </p>
                </div>
              )}
              {created && (
                <div className={`${classResponse} uk-alert`} uk-alert="true">
                  <a className="uk-alert-close uk-icon uk-close" uk-close="true"></a>
                  <p className="response">{response}</p>
                </div>
              )}
              <div className="uk-grid-small" data-uk-grid="">
                <div className="uk-width-1-2">
                  <input className="uk-input" type="text" name="name" placeholder="Nombres" autoFocus onChange={this.handleChange} />
                </div>
                <div className="uk-width-1-2">
                  <input className="uk-input" type="text" name="lastname" placeholder="Apellidos" onChange={this.handleChange} />
                </div>
                <div className="uk-width-1">
                  <input className="uk-input" type="text" name="username" placeholder="Usuario" onChange={this.handleChange} />
                </div>
                <div className="uk-width-1">
                  <input className="uk-input" type="email" name="email" placeholder="Correo electrónico" onChange={this.handleChange} />
                </div>
                <div className="uk-width-1">
                  <input className="uk-input" type="password" name="password" placeholder="Contraseña" onChange={this.handleChange} />
                </div>
                <div className="uk-width-1">
                  <input className="uk-input" type="password" name="confirm" placeholder="Verificar contraseña" onChange={this.handleChange} />
                </div>
              </div>
              {error !== '' && (
                <div className="uk-alert-danger uk-alert" uk-alert="true">
                  <p className="error">{error}</p>
                </div>
              )}
              <LinkStyled active={error} className="uk-button uk-button-primary uk-width-1-1 uk-margin-small-top uk-text-bold" onClick={(e) => this.handleRegister(e)}>CREAR CUENTA</LinkStyled>
            </div>
          </Container>
        </div>
      </OauthPage>
    );
  }
}

SignUp.propTypes = {
  userForm: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    confirm: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    lastname: PropTypes.string,
    photo: PropTypes.string,
    provider: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.number,
    date: PropTypes.string,
    last_login: PropTypes.string,
    social: PropTypes.object,
  }),
  history: PropTypes.object,
  data: PropTypes.object.isRequired,
  loading: PropTypes.oneOf([true, false]).isRequired,
  error: PropTypes.string.isRequired,
  onUserFormChange: PropTypes.func.isRequired,
  onUserFieldError: PropTypes.func.isRequired,
  onCreateUserRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const userForm = (typeof state.get('userReducer').get('userForm').username === 'undefined') ? state.get('userReducer').get('userForm').toJS() : state.get('userReducer').get('userForm');
  const data = (typeof state.get('userReducer').get('data')._id === 'undefined') ? state.get('userReducer').get('data').toJS() : state.get('userReducer').get('data');
  console.log('mapStateToProps SignUp', userForm, data);
  return {
    data,
    userForm,
    loading: state.get('userReducer').get('loading'),
    error: state.get('userReducer').get('error'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserFormChange: (name, value) => dispatch(userFormChange(name, value)),
    onUserFieldError: (error) => dispatch(userFieldError(error)),
    onCreateUserRequest: () => dispatch(createUserRequest()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignUp));
