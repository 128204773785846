import * as LoginActions from '../typesActions';

export const fetchingLoginRequest = () => {
  return {
    type: LoginActions.FETCHING_LOGIN_REQUEST,
  };
};

export const fetchingLoginSuccess = data => {
  return {
    type: LoginActions.FETCHING_LOGIN_SUCCESS,
    payload: { data },
  };
};

export const fetchingLoginFailure = data => {
  return {
    type: LoginActions.FETCHING_LOGIN_FAILURE,
    payload: { data },
  };
};

export const fetchingLogoutRequest = () => {
  return {
    type: LoginActions.FETCHING_LOGOUT_REQUEST,
  };
};

export const fetchingLogoutSuccess = data => {
  return {
    type: LoginActions.FETCHING_LOGOUT_SUCCESS,
    payload: { data },
  };
};

export const fetchingLogoutFailure = data => {
  return {
    type: LoginActions.FETCHING_LOGOUT_FAILURE,
    payload: { data },
  };
};

export const setLoginFormChange = (name, value) => {
  return {
    type: LoginActions.SET_LOGIN_FORM_CHANGE,
    payload: { name, value },
  };
};

export const setLoginFieldError = error => {
  return {
    type: LoginActions.SET_LOGIN_FIELD_ERROR,
    payload: { error },
  };
};
