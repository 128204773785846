import * as FiltersActions from '../typesActions';

export const searchInit = () => {
  return {
    type: FiltersActions.FILTERS_SEARCH_INIT,
  };
};

export const searchSuccess = data => {
  return {
    type: FiltersActions.FILTERS_SEARCH_SUCCESS,
    payload: { data },
  };
};

export const searchError = error => {
  return {
    type: FiltersActions.FILTERS_SEARCH_ERROR,
    payload: error,
  };
};

export const filterChange = (value) => {
  return {
    type: FiltersActions.FILTERS_CHANGE,
    payload: { value },
  };
};
