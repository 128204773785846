import { fromJS } from 'immutable';
import * as ResetActions from '../typesActions';

const INITIAL_STATE = fromJS({
  resetForm: {
    password: '',
    verify: '',
    token: '',
  },
  loading: false,
  error: '',
  data: {},
});

const resetReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ResetActions.FETCHING_RESET_REQUEST: {
      return state
        .set('loading', true);
    }

    case ResetActions.FETCHING_RESET_SUCCESS: {
      return state
        .set('loading', false)
        .set('error', '')
        .set('data', payload.data);
    }

    case ResetActions.FETCHING_RESET_FAILURE: {
      return state
        .set('loading', false)
        .set('data', payload.data);
    }

    case ResetActions.SET_RESET_FORM_CHANGE: {
      const { name, value } = payload;
      const resetForm = (typeof state.get('resetForm').password === 'undefined') ? state.get('resetForm').toJS() : state.get('resetForm');
      console.log('SET_RESET_FORM_CHANGE', resetForm);
      return state
        .set('resetForm', { ...resetForm, [name]: value });
    }

    case ResetActions.SET_RESET_FIELD_ERROR: {
      return state
        .set('error', payload.error);
    }

    default: {
      return state;
    }
  }
};

export default resetReducer;
