import * as IucnActions from '../typesActions';

export const fetchingIucnRequest = () => {
  return {
    type: IucnActions.FETCHING_IUCN_REQUEST,
  };
};

export const fetchingIucnSuccess = data => {
  //console.log('actions fetchingIucnSuccess', data);
  return {
    type: IucnActions.FETCHING_IUCN_SUCCESS,
    payload: { data },
  };
};

export const fetchingIucnFailure = data => {
  return {
    type: IucnActions.FETCHING_IUCN_FAILURE,
    payload: { data },
  };
};

export const setIucnFormChange = (name, value) => {
  //console.log('actions', name, value);
  return {
    type: IucnActions.SET_IUCN_FORM_CHANGE,
    payload: { name, value },
  };
};
