import React, { Component } from 'react';

class UICNSlot extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    let { name, id, select, containerBase, content} = this.props
    if (content===undefined) content=true
    let container = ''
    if (containerBase === undefined){
      container = 'uk-text-center uk-text-middle content-caja'
    }else{
      container = containerBase
    }
    
    //let className = 'uk-text-center uk-text-middle UICNSlotContainer '
    let className = container
    let classNameCaja = ''
    if (select===id){
      //className += 'uk-width-1-5'
      classNameCaja = 'UICN_'+id
    }else{
      //className += 'uk-width-1-10'
    }
    
    return (
      <span className={className}>
        <div className={"uk-panel caja caja-"+classNameCaja}>
          <div className={"title "}>{id}</div>
          {content && <div className={"content "}>{name}</div>}
        </div>
      </span>
    );
  }
}

export default UICNSlot;
