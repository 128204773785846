/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { map, indexOf } from 'lodash';
import UIkit from 'uikit';

import { T, extraerColumnas, toCamelCase } from '../../../services/UtilService';
import * as CatalogService from '../../../services/CatalogService';


class IgnoreCard extends Component {

  constructor() {
    super();
    this.state = {
      _id: '',
      id_record: '',
      created: '',
      id_user: 'mmorales@idesoluciones.com',
      version: 0,
      state: '',
      element: '',
      hierarchy: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { id } = this.props;
    this.setState({
      _id: id,
      hierarchy: this.props.inputs[this.props.label],
    });
  }

  handleInput(e, k1, k2) {
    const { hierarchy } = this.state;
    hierarchy[k1][k2] = e.target.value;

    this.setState({ hierarchy });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { id, label } = this.props;
    CatalogService.setField(id, this.state, toCamelCase(label)).then(data => {
      UIkit.notification({
        message: '<span uk-icon=\'icon: check\'></span> Agregado correctamente',
        status: 'success',
        timeout: 2000,
      });
      UIkit.modal(`#${label}`) && UIkit.modal(`#${label}`).hide();
    });
  }

  render() {
    const { inputs, label } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        {
          map(inputs[label], (v1, k1) => (
            <div key={k1} className="uk-grid-small uk-margin" data-uk-grid="">
              {
                map(v1, (v2, k2) => (
                  indexOf(['ancillaryData', '_id'], k2) < 0 && <div className="uk-width-1-2@s" key={k2}>
                    <label className="uk-form-label" htmlFor={k2}>{T(k2)}</label>
                    <div className="uk-form-controls">
                      <input className="uk-input" id={k2} type="text" defaultValue={v2} onChange={(e) => { this.handleInput(e, k1, k2); }} />
                    </div>
                  </div>
                ))
              }
            </div>
          ))
        }
        <div className="uk-text-right">
          <button className="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Cancelar</button>
          <button className="uk-button uk-button-primary uk-text-bold" type="submit">Guardar</button>
        </div>
      </form>
    );
  }
}

function _t(x){
  if (x.startsWith('i_')){
    return <i>{x.substring(2)}</i>
  }
  return x
}

function util(k, data, ignorar, cero) {
  let columns;
  if (cero)
    columns = extraerColumnas(data[0], ignorar);
  else
    columns = extraerColumnas(data, ignorar);

  return (
    <div className={'uk-child-width-1-1'}>
      {map(columns, (value, key) => {
        let d;
        if (cero)
          d = data[0][value];
        else
          d = data[value];
        if (d) {
          let imprimir = true;
          if((Array.isArray(d) && d.length===0) || d==='i_'){
            imprimir=false;
          }
          if (imprimir)
            return (
              <li key={key}><div className="uk-child-width-1-2" data-uk-grid="">
                <span className="uk-text-bold">{T(value)}</span><span>{_t(d)}</span>
              </div></li>);
        }
      })}
    </div>
  );
}

IgnoreCard.util = util;

export default IgnoreCard;
