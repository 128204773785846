/* eslint-disable react/prop-types */
import React, { Component } from 'react';

class FNumber extends Component {

  constructor(props) {
    super(props);
    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(event) {
    this.props.onDataChange({ path: this.props.path, value: event.target.value });
  }

  render() {
    return (
      <div>
        <h5>{this.props.value.title}</h5>
        <input type="number" className="uk-input" min="0" onKeyUp={this.updateValue} />
      </div>
    );
  }
}

export default FNumber;
