/* eslint-disable react/prop-types */
import React, { Component } from 'react';

class Icon extends Component {
  constructor() {
    super();
    this.state = {
      bColor: 0,
    };
  }

  render() {
    const { bColor } = this.state;
    return (
      <span
        className="uk-border-rounded"
        data-uk-icon={`icon: ${this.props.icon}; ratio: ${this.props.ratio ? this.props.ratio : 1.7}`}
        style={{ backgroundColor: this.props.backColor[bColor], color: this.props.color, padding: this.props.padding, transition: 'background-color .5s ease' }}
        onMouseEnter={() => this.setState({ bColor: 1 })}
        onMouseLeave={() => this.setState({ bColor: 0 })}
      />
    );
  }
}

export default Icon;
