/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import * as ComplexTypeService from '../../../services/ComplexTypeService';
import AutoForm from '../../molecules/AutoForm';

import invasiveness from '../../../config/forms/invasiveness.json';
import dispersal from '../../../config/forms/dispersal.json';
import legislation from '../../../config/forms/legislation.json';
import lifeForm from '../../../config/forms/lifeForm.json';
import behavior from '../../../config/forms/behavior.json';

class Form extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };

    this.updateValue = this.updateValue.bind(this);
    this.addItem = this.addItem.bind(this);
  }

  componentDidMount(props) {
    this.onMount(function callback(newName) {
      this.setState({ element: this.props.element, attrs: this.get(this.props.element) });
    });
  }

  get(type) {
    switch (type) {
      case 'invasiveness':
        return invasiveness;
      case 'dispersal':
        return dispersal;
      case 'legislation':
        return legislation;
      case 'lifeForm':
        return lifeForm;
      case 'behavior':
        return behavior;
    }
  }

  addItem() {
    const data = {};

    this.state.formData.ancillaryData = [];
    data[this.props.element] = this.state.formData;
    data[this.props.element][this.props.element + 'Atomized'] = [data[this.props.element][this.props.element + 'Atomized']];

    ComplexTypeService.saveNewItem(this.props.id, data, this.props.element).then((result) => {
      if (result.version) {
        alert(`Versión ${result.version} de ${result.element} Guardada`);
      }
    });
  }

  updateValue(data) {
    let parent = this.state.formData;

    data.path.forEach((element, idx) => {
      if (element !== 'item') {
        if (!parent[element]) {
          parent[element] = {};
        }

        if (idx === data.path.length - 1) {
          parent[element] = data.value;
        } else {
          parent = parent[element];
        }
      }
    });
  }

  render() {
    const type = this.get(this.props.element);

    return (
      <div className="uk-container uk-width-5-6@l uk-width-2-3@xl uk-margin-top">
        <div className="uk-grid-small" data-uk-grid="">
          <div className="uk-width-1-3">
            <div className="uk-card uk-card-default" data-uk-sticky="offset: 90; bottom: true" style={{ zIndex: 979 }}>
              <div className="uk-card-header">
                <h4>Form</h4>
              </div>
              <div className="uk-padding-small">
                <ul className="uk-list uk-list-large uk-margin-remove-bottom" uk-scrollspy-nav="closest: li; cls: uk-text-primary; offset: 90;">
                  <li><a className="uk-link-reset" href="#images" data-uk-scroll="offset: 90">Imágenes</a></li>
                  <li><a className="uk-link-reset" href="#sounds" data-uk-scrol1l="offset: 90">Sonidos</a></li>
                  <li><a className="uk-link-reset" href="#others" data-uk-scroll="offset: 90">Otros</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="uk-width-expand">
            <AutoForm data={this.props.data} type={type} onDataChange={this.updateValue} />
            <br />
            <button className="uk-button uk-button-primary uk-align-right" onClick={this.addItem}>Agregar nueva versión de { this.state.element }</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
