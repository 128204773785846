/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { map, compact, flatten } from 'lodash';
import Plinian from '../molecules/Plinian';


class Ancillary extends Component {

  render() {
    const { value } = this.props;
    if (value.length >0) {
      let lista = map(value, (v, k)=>{
        let x;
        if ((v.reference !== undefined && v.reference.length>0) || (v.rightsHolder!==undefined && v.rightsHolder !== '')){// || (v.license!==undefined && v.license!=="")){
          if(v.reference){
            x = <Plinian type={'references'} data={v.reference} />;
            return <ul key={k} className="uk-margin-remove-top uk-margin-remove-bottom ancillaryItem">
              {v.reference &&
                x
              }
            </ul>;
          }
        }else if (v.source!==undefined && v.source !== '') {
          x = <Plinian type={'source'} data={v.source} />;
          return <ul key={k} className="uk-margin-remove-top uk-margin-remove-bottom ancillaryItem">
            {v.source &&
              x
            }
          </ul>;
        }else{
          return null;
        }
      });
      lista = compact(flatten(lista));
      if (lista.length>0)
        return lista;
      else
        return null;
    }else{
      return null;
    }
  }
}

Ancillary.count = (value, key, id) => {
  if(value.length>0){
    let lista = map(value, (v, k)=> {
      if ((v.reference !== undefined && v.reference.length>0) || (v.rightsHolder!==undefined && v.rightsHolder !== '')) {
        let x;
        if(v.reference){
          x = <Plinian type={'references'} data={v.reference} />;
        }
        return <ul key={k} className="uk-margin-remove-top ancillaryItem">
          {v.reference &&
            x
          }
        </ul>;
      }else if (v.source!==undefined && v.source !== '') {
        let x;
        if(v.source){
          x = <Plinian type={'source'} data={v.source} />;
        }
        return <ul key={k} className="uk-margin-remove-top ancillaryItem">
          {v.source &&
            x
          }
        </ul>;
      } else {
        return null;
      }
    });
    lista = compact(flatten(lista));
    return lista.length;
  } else {
    return 0;
  }
};

export default Ancillary;
