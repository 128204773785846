import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as UtilService from '../../services/UtilService';
/* Components */
import UICNSlot from '../atoms/UICNSlot';

class ListaRoja extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  getListaAmenazasUICN(data){
    let lista = UtilService.route(data, ['threatStatusApprovedInUse', 'threatStatus']);
    console.log("la lista es ", lista)
    for(let i=0;i<lista.length;i++){
      let v = lista[i]
      console.log("la lista[",i,"] es ", v)
      if (v['threatStatusAtomized'] !== undefined && v['threatStatusAtomized']['appliesTo'] !== undefined){
        console.log("paso 1", v['threatStatusAtomized'])
        if (v['threatStatusAtomized']['appliesTo']['country']==='GLOBAL'){
          console.log("paso 2 ", v['threatStatusAtomized']['threatCategory']['measurementValue'])
          return v['threatStatusAtomized']['threatCategory']['measurementValue']
        }
      }
    }
    return ''
  }
  render(){
    let imageId = this.getListaAmenazasUICN(this.props.data)

    return (
        <div className="uk-margin">
          <div id={'evaluacion'} className={'uk-card uk-card-default uk-panel uk-padding uk-text-break'}>
            <div className="uk-flex uk-flex-column">
              <span className={'uk-h3 uk-text-tertiary uk-text-bold'}>{'Categoría Lista Roja UICN'}</span>
            </div>
          
          <div className="UICNContainerParent">
              <div className="UICNContainer2">
                  <UICNSlot name="No Evaluado" id="NE" select={imageId} />
                  <UICNSlot name="Datos Insuficientes" id="DD" select={imageId} />
                  <UICNSlot name="Preocupación Menor" id="LC" select={imageId} />
                  <UICNSlot name="Casi Amenazada" id="NT" select={imageId} />
                  <UICNSlot name="Vulnerable" id="VU" select={imageId} />
                  <UICNSlot name="En Peligro" id="EN" select={imageId} />
                  <UICNSlot name="En Peligro Crítico" id="CR" select={imageId} />
                  <UICNSlot name="Extinto en Estado Silvestre" id="EW" select={imageId} />
                  <UICNSlot name="Extinto" id="EX" select={imageId} />
              </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ListaRoja;
