import fetch from 'isomorphic-fetch';

import { URL_API_PARTICIPATION } from '../config/const';

export function getId(id) {
  return fetch(`${URL_API_PARTICIPATION}/api/album/${id}`, {
    method: 'GET',
  }).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}

export function getOrgs(id) {
  return fetch(`${URL_API_PARTICIPATION}/api/album/readOrgs/${id}`, {
    method: 'GET',
  }).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}
