/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { filter } from 'lodash';
import { URL_STATICS } from '../../config/const';
import { URL_STATICS_LICENCIAS_SVG } from '../../config/const';

class License extends Component {

  constructor(props) {
    super(props);
    this.licenses = [
      { id: 'http://creativecommons.org/publicdomain/zero/1.0/legalcode', img: '/static/css/images/licenses/zero.png', to: 'http://creativecommons.org/publicdomain/zero/1.0/legalcode' },
      { id: 'http://creativecommons.org/licenses/by/4.0/legalcode', img: '/static/css/images/licenses/by.png', to: 'https://creativecommons.org/licenses/by/4.0' },
      { id: 'http://creativecommons.org/licenses/by-sa/4.0/legalcode', img: '/static/css/images/licenses/by-sa.png', to: 'https://creativecommons.org/licenses/by-sa/4.0' },
      { id: 'http://creativecommons.org/licenses/by-nd/4.0/legalcode', img: '/static/css/images/licenses/by-nd.png', to: 'https://creativecommons.org/licenses/by-nd/4.0' },
      { id: 'http://creativecommons.org/licenses/by-nc/4.0/legalcode', img: URL_STATICS_LICENCIAS_SVG+'licencia-by-nc.svg', to: 'https://creativecommons.org/licenses/by-nc/4.0' },
      { id: 'http://creativecommons.org/licenses/by-nc-sa/4.0/legalcode', img: URL_STATICS_LICENCIAS_SVG+'licencia-by-nc-sa.svg', to: 'https://creativecommons.org/licenses/by-nc-sa/4.0' },
      { id: 'http://creativecommons.org/licenses/by-nc-nd/4.0/legalcode', img: '/static/css/images/licenses/by-nc-nd.png', to: 'https://creativecommons.org/licenses/by-nc-nd/4.0' },
    ];
  }

  render() {
    const license = filter(this.licenses, { id: this.props.id });
    return (
      <a href={`${license[0].to}`} target="_blank" rel="noopener noreferrer">
        <img style={this.props.style} src={license[0].img} alt={license[0].id} />
      </a>
    );
  }
}

export default License;
