/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';

import GenericPage from '../components/templates/GenericPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import * as CatalogService from '../services/CatalogService';
import Loading from '../components/atoms/Loading';

class FileLegacy extends Component {
  constructor() {
    super();
    this.state = {
      select: 0,
      related: null,
      data: null,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.cargarPagina(match.params.id);
  }

  cargarPagina(id) {
    const { match } = this.props;
    CatalogService.getLegacyById(match.params.id).then(data => {
      if (typeof(data) !== 'object') {
        alert('Ficha no encontrada.');
        window.location.replace('/');
      } else{
        window.location.replace('/file/'+data._id);
      }
    });
  }

  render() {
    return (
      <GenericPage
        titlep="Catálogo de la biodiversidad"
        header={<Header />}
        footer={<Footer />}
      >
        <Loading />
      </GenericPage>
    );
  }
}

export default FileLegacy;
