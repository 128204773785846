/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import UIkit from 'uikit';

// Components
import GenericPage from '../components/templates/GenericPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import Main from '../components/organisms/desktop/Main';
import Albums from '../components/organisms/desktop/Albums';
import Activity from '../components/organisms/desktop/Activity';
import UserCard from '../components/molecules/UserCard';
import { URL_STATICS } from '../config/const';

import { getUser } from '../auth';

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      select: 0,
    };
  }

  componentWillMount() {
    // console.log('El usuario es ');
    // console.log(getUser());
    this.setState({ 'data': getUser() });

    this.activeTab(this.props.match.params.tab);
  }

  activeTab(tab, changeUrl = true) {
    let tabIndex, title, url;
    switch (tab) {
      case 'desktop':
        tabIndex = 0;
        title = 'Perfil';
        url = '/desktop/profile';
        break;
      case 'albums':
        tabIndex = 1;
        title = 'Colecciones';
        url = '/desktop/albums';
        break;
      case 'activity':
        tabIndex = 2;
        title = 'Actividad';
        url = '/desktop/activity';
        break;
      case 'comments':
        tabIndex = 3;
        title = 'Comentarios';
        url = '/desktop/comments';
        break;

      default:
        tabIndex = 0;
        title = 'Perfil';
        url = '/desktop/profile';
        break;
    }

    changeUrl && window.history.replaceState({}, title + ' - Catálogo de la biodiversidad', url + this.props.location.search);
    this.setState({ select: tabIndex });
  }

  componentDidMount() {
    const bar = document.getElementById('js-progressbar');

    UIkit.upload('.js-upload', {

      url: '',
      multiple: true,

      beforeSend () {
        console.log('beforeSend', arguments);
      },
      beforeAll () {
        console.log('beforeAll', arguments);
      },
      load () {
        console.log('load', arguments);
      },
      error () {
        console.log('error', arguments);
      },
      complete () {
        console.log('complete', arguments);
      },

      loadStart (e) {
        console.log('loadStart', arguments);

        bar.removeAttribute('hidden');
        bar.max = e.total;
        bar.value = e.loaded;
      },

      progress (e) {
        console.log('progress', arguments);

        bar.max = e.total;
        bar.value = e.loaded;
      },

      loadEnd (e) {
        console.log('loadEnd', arguments);

        bar.max = e.total;
        bar.value = e.loaded;
      },

      completeAll () {
        console.log('completeAll', arguments);

        setTimeout(function () {
          bar.setAttribute('hidden', 'hidden');
        }, 1000);

        alert('Upload Completed');
      },

    });

  }

  render() {
    const { data } = this.state;

    console.log('Los datos en el render son: ', data);

    return (
      <GenericPage
        titlep="Catálogo de la biodiversidad"
        header={<Header />}
        footer={<Footer />}
        background={URL_STATICS+'destacadas/png/f-mamifero.png'}
      >
        { data &&
          <div>
            <div className="uk-background-linear uk-section">
              <div className="uk-container uk-width-5-6@m uk-width-2-3@l" style={{ marginTop: '40vh' }}>
                <div className="uk-card uk-card-default">
                  <div className="uk-card-body">
                    <i>
                      <span className="uk-text-tertiary uk-margin-remove uk-h1">{data.username}</span>
                    </i>
                    <span className="uk-h5 uk-margin-left" style={{ fontWeight: 200 }}>{data.name} {data.lastname}</span>
                  </div>
                  <div className="uk-overlay uk-padding-remove uk-position-top-right">
                    <a className="uk-background-tertiary uk-link-reset" style={{ padding: 5 }}><span className="uk-text-default" data-uk-icon="icon: pencil"></span><span className="uk-text-default uk-text-bold uk-text-small" style={{ padding: 3 }}>Editor</span></a>
                  </div>
                  <div className="uk-card-footer">
                    <ul data-uk-switcher={`connect: .uk-switcher; active: ${this.state.select}`} data-uk-grid="">
                      <button onClick={() => this.activeTab('desktop')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 0 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Perfil</button>
                      <button onClick={() => this.activeTab('albums')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 1 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Álbumes</button>
                      <button onClick={() => this.activeTab('activity')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 2 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Actividad</button>
                      <button onClick={() => this.activeTab('comments')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 3 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Comentarios</button>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="lol" className="uk-container uk-width-5-6@m uk-width-2-3@l uk-margin-top">
                <div className="uk-grid-small" data-uk-grid="">
                  <div className="uk-width-1-3">
                    <UserCard data={data} />
                  </div>
                  <div className="uk-width-expand">
                    <ul className="uk-switcher">
                      <Main data={data} />
                      <Albums />
                      <Activity />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-position-fixed uk-position-medium uk-position-bottom-right">
              <div className="uk-inline">
                <a className="uk-button-primary uk-border-circle uk-box-shadow-medium" style={{ padding: 10 }} uk-icon="icon: pencil; ratio: 1.3"><span /></a>
                <div uk-drop="mode: click">
                  <div className="uk-card uk-card-default">
                    <table className="uk-table uk-table-divider uk-table-hover">
                      <tbody>
                        <tr>
                          <td>
                            <a href="#outstanding-image" data-uk-toggle="" className="uk-link-reset"><span className="uk-margin-small-right" data-uk-icon="icon: camera"></span>Imagen destacada</a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a href="#profile-modal" data-uk-toggle="" className="uk-link-reset"><span className="uk-margin-small-right" data-uk-icon="icon: user"></span>Perfil</a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a href="" className="uk-link-reset"><span className="uk-margin-small-right" data-uk-icon="icon: cog"></span>Ajuste de cuenta</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div id="profile-modal" data-uk-modal="">
              <div className="uk-modal-dialog uk-width-xxlarge">
                <button className="uk-modal-close-default" type="button" data-uk-close=""></button>
                <div className="uk-modal-header">
                  <h2 className="uk-modal-title">Perfil <span data-uk-icon="icon: pencil; ratio: 1.3"></span></h2>
                </div>
                <div className="uk-modal-body">
                  <form className="uk-grid-small uk-child-width-1-2" data-uk-grid="">
                    <div className="uk-text-center">
                      <img className="uk-margin-auto" src="/static/css/images/slide.png" alt="" />
                    </div>
                    <div className="uk-flex uk-flex-bottom uk-grid-collapse" data-uk-grid="">
                      <div className="js-upload uk-placeholder uk-padding-small uk-text-center uk-margin-remove">
                        <span className="uk-width-1" uk-icon="icon: cloud-upload; ratio: 2"></span>
                        <span className="uk-text-middle">Suelte una imagen para cargarla instantanemanete o </span>
                        <div uk-form-custom="">
                          <input type="file" />
                          <span className="uk-link">seleccione una</span>
                        </div>
                      </div>
                      <progress id="js-progressbar" className="uk-progress uk-margin-remove" value="0" max="100" hidden></progress>
                    </div>
                    <div>
                      <label className="uk-form-label" htmlFor="firstNames">Nombres</label>
                      <div className="uk-form-controls">
                        <input className="uk-input" id="firstNames" type="text" />
                      </div>
                    </div>
                    <div>
                      <label className="uk-form-label" htmlFor="lastNames">Apellidos</label>
                      <div className="uk-form-controls">
                        <input className="uk-input" id="lastNames" type="text" />
                      </div>
                    </div>
                    <div>
                      <label className="uk-form-label" htmlFor="username">Nombre de usuario</label>
                      <div className="uk-form-controls">
                        <input className="uk-input" id="username" type="text" />
                      </div>
                    </div>
                    <div>
                      <label className="uk-form-label" htmlFor="emailAddress">Correo electrónico</label>
                      <div className="uk-form-controls">
                        <input className="uk-input" id="emailAddress" type="text" />
                      </div>
                    </div>
                    <div>
                      <label className="uk-form-label" htmlFor="birthDate">Fecha de nacimiento</label>
                      <div className="uk-form-controls">
                        <select className="uk-select">
                          <option></option>
                          <option></option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="uk-form-label" htmlFor="gender">Generó</label>
                      <div className="uk-form-controls">
                        <select className="uk-select">
                          <option></option>
                          <option></option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="uk-form-label" htmlFor="telephoneContact">Teléfono de contacto</label>
                      <div className="uk-form-controls">
                        <input className="uk-input" id="telephoneContact" type="text" />
                      </div>
                    </div>
                    <div>
                      <label className="uk-form-label" htmlFor="cellPhone">Teléfono movil</label>
                      <div className="uk-form-controls">
                        <input className="uk-input" id="cellPhone" type="text" />
                      </div>
                    </div>
                    <div>
                      <label className="uk-form-label" htmlFor="city">Ciudad</label>
                      <div className="uk-form-controls">
                        <input className="uk-input" id="city" type="text" />
                      </div>
                    </div>
                    <div>
                      <label className="uk-form-label" htmlFor="country">País</label>
                      <div className="uk-form-controls">
                        <input className="uk-input" id="country" type="text" />
                      </div>
                    </div>
                    <div className="uk-width-1">
                      <label className="uk-form-label" htmlFor="profile">Perfil</label>
                      <div className="uk-form-controls">
                        <textarea className="uk-textarea" rows="5"></textarea>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="uk-modal-footer uk-text-right">
                  <button className="uk-button uk-button-primary uk-text-bold uk-margin-small-right" type="button">Guardar</button>
                  <button className="uk-button uk-button-default uk-modal-close" type="button">Cancelar</button>
                </div>
              </div>
            </div>

            <div id="outstanding-image" data-uk-modal="">
              <div className="uk-modal-dialog uk-width-xxlarge">
                <button className="uk-modal-close-default" type="button" data-uk-close=""></button>
                <div className="uk-modal-header">
                  <h2 className="uk-modal-title">Imagen destacada <span data-uk-icon="icon: pencil; ratio: 1.3"></span></h2>
                </div>
                <div className="uk-modal-body">
                  <form className="uk-search uk-search-default uk-width-1 uk-margin">
                    <span uk-search-icon=""></span>
                    <input className="uk-search-input" type="search" placeholder="Buscar" />
                  </form>
                  <div className="uk-panel uk-panel-scrollable uk-height-large">
                    <img src="/static/css/images/logo_entidad.png" alt="" />
                    <img src="/static/css/images/logo_entidad.png" alt="" />
                    <img src="/static/css/images/logo_entidad.png" alt="" />
                  </div>
                </div>
                <div className="uk-modal-footer uk-text-right">
                  <button className="uk-button uk-button-primary uk-text-bold uk-margin-small-right" type="button">Guardar</button>
                  <button className="uk-button uk-button-default uk-modal-close" type="button">Cancelar</button>
                </div>
              </div>
            </div>
          </div>
        }
      </GenericPage>
    );
  }
}

export default Profile;
