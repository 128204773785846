import { fromJS } from 'immutable';
import * as AlbumActions from '../typesActions';

const INITIAL_STATE = fromJS({
  albumForm: {
    id: '',
  },
  loading: false,
  error: '',
  data: {},
});

const albumReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case AlbumActions.FETCHING_ALBUM_REQUEST: {
      return state
        .set('loading', true);
    }

    case AlbumActions.FETCHING_ALBUM_SUCCESS: {
      let { data } = payload;
      data = ((data === null) || (typeof data === 'undefined')) ? {} : data;
      return state
        .set('loading', false)
        .set('data', data);
    }

    case AlbumActions.FETCHING_ALBUM_FAILURE: {
      return state
        .set('loading', false)
        .set('data', payload.data);
    }

    case AlbumActions.SET_ALBUM_FORM_CHANGE: {
      const { name, value } = payload;
      return state
        .set('albumForm', { ...state.get('albumForm').toJS(), [name]: value });
    }

    default: {
      return state;
    }
  }
};

export default albumReducer;
