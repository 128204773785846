/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import cx from 'classnames';
import { split, map, indexOf, join } from 'lodash';
// Components
import LogicPage from '../components/templates/LogicPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import Sidebar from '../components/organisms/Sidebar';
import Filters from '../components/molecules/Filters';
import Title from '../components/atoms/Title';
import SearchGrid from '../components/organisms/SearchGrid';
import SearchList from '../components/organisms/SearchList';
// Services
import * as CatalogService from '../services/CatalogService';

class Search extends Component {

  constructor() {
    super();
    this.state = {
      displayDatasets: 0,
      data: null,
      count: null,
      search: '',
      limite: 20,
    };
    this.count = [];
    this.search = [];
  }
  activeFilters() {
    return this.props.location.search !== '' && split((this.props.location.search).slice(1), '&');
  }

  setCount(e, filter) {
    console.log("count")
    this.count[filter] = e.length;
    this.search[filter] = e;
    const stringQ = this.createQuery(this.search);
    window.history.replaceState('', 'Búsqueda - Catálogo de la Biodiversidad', `${this.props.location.pathname}${stringQ && '?' + stringQ}`);
    map(this.child, (value, key) => {
      value.offsetResults(0, 0, stringQ);
    });

    this.setState({ count: this.count, search: stringQ });

    console.log("-------------------->StringQ---------------------->",stringQ)
    this.buscar(stringQ);
  }

  componentWillMount() {
    this.buscar();
  }

  buscar(stringQ){
    this.setState({ 'cargando': 'Cargando ...' });
    //console.log('Buscando limited: ', this.state.limite);
    let q=stringQ;
    let path = 'advanced_search';
    if(stringQ===undefined) {
      //console.log("Consulta actual")
      //console.log(this.props.location.search);
      // console.log("this.state.search", this.state.search)

      if(this.props.location.search===''){

        if (this.state.search===''){
          q = '';
          // q = "kingdom=animalia&kingdom=plantae&kingdom=fungi&kingdom=bacteria&kingdom=archaea"
        }else{
          q = this.state.search;
        }

      }else{
        //console.log('buscando', this.props.location.search);
        if (this.props.location.search.substring(0, 3)==='?q='){
          q = this.props.location.search.replace('?', '');
          path='search';
        }else{
          q = this.props.location.search.replace('?', '');
        }
      }
    }

    //console.log("q--------------------->",q)
    CatalogService.getList(path, q, this.state.limite).then(data => {
      this.setState({ data, 'cargando': '' });
    }).catch(err => console.log(err));
    CatalogService.getCount(path, q).then(data => {
      this.setState({ count: data.total });
    }).catch(err => console.log(err));
  }

  handleDisplay(state) {
    this.setState({ displayDatasets: state });
  }
  createQuery(data) {
    //console.log("data.......->",data)
    const search = this.props.location.search !== '' ? split((this.props.location.search).slice(1), '&') : [];
    search['query'] = [];
    map(data, (value, key) => {
      map(value, (value1) => {
        const i = indexOf(search['query'], value1.id);
        if (i < 0) {
          search['query'].push(value1.id);
        }
      });
    });

    if (!this.state.modified && search['query'].length < 0)
      search['query'].unshift(join(search, '&'));

    return join(search['query'], '&');
  }

  cargarMas() {
    //console.log('Buscando pagina: ', this.state.limite);
    this.setState({ limite: this.state.limite+20 }, ()=>{
      this.buscar();
    });
  }

  render() {
    /*
                  <li>
                    <a className="uk-text-right" title="Ordenar" data-uk-tooltip>A/Z</a>
                    <div className="uk-padding-small" data-uk-dropdown="mode: click">
                      <ul className="uk-nav uk-dropdown-nav">
                        <li><a>Alfabético</a></li>
                        <li><a>Visitas</a></li>
                        <li><a>Peligro</a></li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a className="uk-text-right" title="Ordenar" data-uk-tooltip>Mejor coincidencia</a>
                    <div className="uk-padding-small" data-uk-dropdown="mode: click">
                      <ul className="uk-nav uk-dropdown-nav">
                        <li><a>Mejor coincidencia</a></li>
                        <li><a>Más visitados</a></li>
                        <li><a>Mejor valorados</a></li>
                      </ul>
                    </div>
                  </li>

*/

    let t = this.state.count;
    if (t===undefined){
      t=0;
    }
    if (t===null){
      t=0;
    }
    if (Array.isArray(t)){
      t=t[0];
    }
    console.log('La cantidad de elementos es ->:  ', t);

    return (
      <LogicPage
        titlep="Catálogo de la biodiversidad"
        headercustom={<Header withSidebar={Sidebar.id} search={this.props.location.search} location={this.props.location.pathname} />}
        sidebar={
          <Filters count={this.state.count} search={this.state.search}>
            <Filters.Taxonomy activeFilters={this.activeFilters()} count={(e) => this.setCount(e, 0)} />
            <Filters.Location activeFilters={this.activeFilters()} count={(e) => this.setCount(e, 1)} />
            {/* <Filters.Thematic activeFilters={this.activeFilters()} count={(e) => this.setCount(e, 2)} /> */}
            <Filters.Multimedia activeFilters={this.activeFilters()} count={(e) => this.setCount(e, 3)} />
            <Filters.GruposTematicos activeFilters={this.activeFilters()} count={(e) => this.setCount(e, 4)} />
          </Filters>
        }
        footer={<Footer />}
      >
        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-expand">
            <Title
              label={<span className="uk-text-bold">BÚSQUEDA DE ESPECIES  </span>}
              icon={<span className="uk-text-primary" uk-icon="icon: triangle-right; ratio: 1.3" />}
              number={<NumberFormat value={t} displayType="text" thousandSeparator />}
              labelNumber="RESULTADOS"
              tag="h4"
            />
            <div className="uk-grid-collapse uk-child-width-expand uk-margin uk-flex uk-flex-bottom" data-uk-grid>
              <ul className="uk-flex-right" data-uk-tab="swiping: false;" data-uk-switcher="connect: #paginasT">
                <li className={cx({ 'uk-active': !this.state.displayDatasets })} onClick={() => this.handleDisplay(false)}><a title="Modo grilla" data-uk-tooltip><span uk-icon="icon: grid"></span></a></li>
                <li className={cx({ 'uk-active': this.state.displayDatasets })} onClick={() => this.handleDisplay(true)}><a title="Modo lista" data-uk-tooltip><span uk-icon="icon: list"></span></a></li>
              </ul>
            </div>
            <div className="uk-switcher switcher-dataset uk-container uk-container-expand" id="paginasT">
              <div className="uk-section uk-section-xsmall uk-clearfix uk-overflow-hidden">
                {t !== 0 ? (<SearchGrid data={this.state.data} />) : (<div style={{ height: '150px' }}></div>)}
                <div className="uk-clearfix" />
              </div>
              <div className="uk-section uk-section-xsmall uk-width-4-5@xl uk-align-center">
                {t !== 0 && (<SearchList data={this.state.data} />)}
              </div>
            </div>
            {t>this.state.limite &&
              <div className="uk-align-center uk-button uk-button-primary" onClick={()=>{this.cargarMas();}}>Cargar más ({this.state.limite} de {t}) {this.state.cargando}</div>
            }
          </div>
        </div>
      </LogicPage>
    );
  }
}

export default Search;
