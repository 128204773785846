import fetch from 'isomorphic-fetch';

import { URL_API_PARTICIPATION } from '../config/const';
import { getToken } from '../auth';
// import { Exception } from 'handlebars';


function http(method, data) {
  const headers = {
    'Content-Type': 'application/json',
  };

  const token = getToken();
  if (token) headers['Authorization'] = token;

  const config = {
    method,
    headers,
    body: JSON.stringify(data),
  };

  return config;
}

export function getUsername(username) {
  return fetch(`${URL_API_PARTICIPATION}/api/user/${username}`, http('GET')).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}

export function get() {
  return fetch(`${URL_API_PARTICIPATION}/api/admin/user`, http('GET')).then(function(response) {
    console.log(response);
    return response.json();
  }).then((data) => {
    return data;
  });
}

export function put(id, u) {
  return fetch(`${URL_API_PARTICIPATION}/api/admin/user/${id}`, http('PUT', u)).then((response) => {
    console.log(response);
    return response.json();
  }).then((data) => {
    return data;
  });
}

export function del(id) {
  return fetch(`${URL_API_PARTICIPATION}/api/admin/user/${id}`, http('DELETE')).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}
