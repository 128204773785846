/* eslint-disable no-unused-vars */
import fetch from 'isomorphic-fetch';
import { indexOf, map, keys, join } from 'lodash';

import { USER, URL_API_PARTICIPATION, TOKEN } from '../config/const';

// eslint-disable-next-line
let rootMe = null

export function http(type, data) {
  /*
    console.log(data)

    var body = new FormData();
    if (data)
      for (var key in data)
        body.append(key, data[key]);

    data = data
      ? body
      : null;*/

  let d = data;
  if (data){
    const ks = keys(data);
    console.log(data, ks);
    const lista = map(ks, (v, k)=>{
      console.log(v, k, data[v]);
      return v+'='+data[v];
    });
    console.log(lista);
    d = join(lista, '&');
  }

  const config = {
    method: type,
    // mode: 'cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': getToken(),
    },
    body: d,
  };

  return config;

}

export function signin(user) {
  return fetch(`${URL_API_PARTICIPATION}/auth/local`, http('POST', user)).then((response) => {
    return response.json();
  }).then((data) => {
    if (data.token) {
      return setToken(data).then(token => {
        return token;
      });
    } else {
      return { error: data.message };
    }
  }).catch(err => {
    return err;
  });
}

export function logout() {
  window.location.reload();
  clean();
}

export function signup(user) {
  return fetch(`${URL_API_PARTICIPATION}/api/user`, http('POST', user)).then((response) => {
    return response.json();
  }).then((data) => {
    if (data && data.message && data.message.errors) {
      data = data.message.errors;
      return { error: data.username.message };
    } else {
      return data;
    }
  }).catch(error => {
    console.log(error);
    return error;
  });
}


export function me() {
  return fetch(`${URL_API_PARTICIPATION}/api/user/me`, http('GET')).then((response) => {
    return response.json();
  }).then((data) => {

    if (data._id) {
      return setUser(data).then(user => {
        return user;
      });
    } else {
      throw new Error(`error: ${data.message}`);
    }

  }).catch(err => {
    clean();
  });

}

export function isAuthenticated() {
  return getUser();
}

export function isAdmin(){
  return indexOf(getUser().roles, 'admin')>=0;
}

export function setUser(data) {
  try {
    localStorage.setItem(USER, JSON.stringify(data));
  } catch (err) {
    return Promise.reject(err.message);
  }
  return Promise.resolve(rootMe = data || null);
}

export function getUser() {
  return JSON.parse(localStorage.getItem(USER));
}

export function getToken() {
  return localStorage.getItem(TOKEN);
}

export function setToken(data) {
  try {
    localStorage.setItem(TOKEN, data.token);
  } catch (err) {
    return Promise.reject(err.message);
  }
  return Promise.resolve(data.token || null);
}

export function clean() {
  localStorage.clear();
}

