import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Style Components */
import { Paragraph } from '../../pages/styled';

const Poblacion = ({ data }) => {
  const { population } = data;
  const { justification, severeFragmentation, subpopulation } = population;

  return (
    <div className="uk-margin">
      <div id={'poblacion'} className={'uk-card uk-card-default uk-panel uk-padding uk-text-break'}>
        <div className="uk-flex uk-flex-column">
          <span className={'uk-h3 uk-text-tertiary uk-text-bold'}>{'Población'}</span>
        </div>

        <div className="uk-padding-small type_hierarchy">
          <div className="uk-child-width-1-1">
            <li>
              <div className="uk-child-width-1-2 uk-grid" data-uk-grid="">
                <span className="uk-text-bold uk-first-column">Justificación:</span>
              </div>
            </li>
            <Paragraph>{justification}</Paragraph>
            <li>
              <div className="uk-child-width-1-2 uk-grid" data-uk-grid="">
                <span className="uk-text-bold uk-first-column">Fragmentación severa:</span>
              </div>
            </li>
            <Paragraph>{severeFragmentation}</Paragraph>
            <li>
              <div className="uk-child-width-1-2 uk-grid" data-uk-grid="">
                <span className="uk-text-bold uk-first-column">Subpoblaciones:</span>
                <span>{subpopulation}</span>
              </div>
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

Poblacion.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.get('iucnReducer').get('data'),
});


export default connect(
  mapStateToProps,
  null
)(withRouter(Poblacion));
