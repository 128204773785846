/* eslint-disable no-trailing-spaces */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { map/* , split /*, filter*/ } from 'lodash';
// import {TileLayer, LayersControl} from 'react-leaflet';
import { URL_STATICS_CATALOGO} from '../../../config/const';

// import License from '../../atoms/License';
import DataSheet from '../../molecules/DataSheet';

// import * as UtilService from '../../../services/UtilService';
// import * as MapService from '../../../services/MapService';
import * as AlbumService from '../../../services/AlbumService';
import UserList from '../../molecules/lists/UserList';
import OrganizationList from '../../molecules/lists/OrganizationList';



class Summary extends Component {


  constructor(props) {
    super(props);
    this.state = {
      id: null,
    };
  }


  componentWillMount() {
    console.log('voy a consultar: ');
    console.log(this.props);
    AlbumService.getOrgs(this.props.data._id).then(organizaciones => {
      console.log('Lista de organizaciones');
      console.log(organizaciones);
      this.setState({ organizaciones });
      /*
        Buscar la fichas del album
      CatalogService.getRelatedRecords(data.hierarchy[0].family).then(related => {
        this.setState({ related })
      })
      */
    });

  }
  // https://api.gbif.org/v2/map/occurrence/adhoc/1/0/1@1x.png?style=orange.marker&bin=square&squareSize=256&locale=en&q=Pristimantis%20palmeri&advanced=false&srs=EPSG%3A4326


  render() {
    const { data } = this.props;// locale=en&taxonKey=2425033&advanced=false&srs=EPSG%3A4326
    console.log('Los datos que llegan son: ');
    console.log(data);

    console.log('Las organizaciones son: ');
    console.log(this.state.organizaciones);

    return (
      <div>
        <div className="uk-container uk-width-5-6@l uk-width-2-3@xl uk-margin-top">
          <div className="uk-card uk-card-default">
            <div className="uk-card-body uk-flex uk-padding-remove-top">
              <div className="uk-width-1-6">
                <img className="uk-padding-remove" src="https://statics.sibcolombia.net/sib-resources/images/catalogo/inicio/png/h-invertebrados.png" alt="" />
                <p className="uk-margin-remove uk-text-center uk-text-large uk-text-bold">4.600</p>
                <p className="uk-margin-remove uk-text-center uk-text-xsmall">INVERTEBRADOS</p>
              </div>
              <div className="uk-width-1-6">
                <img className="uk-padding-remove" src="https://statics.sibcolombia.net/sib-resources/images/catalogo/inicio/png/h-vertebrados.png" alt="" />
                <p className="uk-margin-remove uk-text-center uk-text-large uk-text-bold">2.500</p>
                <p className="uk-margin-remove uk-text-center uk-text-xsmall">VERTEBRADOS</p>
              </div>
              <div className="uk-width-1-6">
                <img className="uk-padding-remove" src="https://statics.sibcolombia.net/sib-resources/images/catalogo/inicio/png/h-plantas.png" alt="" />
                <p className="uk-margin-remove uk-text-center uk-text-large uk-text-bold">2.400</p>
                <p className="uk-margin-remove uk-text-center uk-text-xsmall">PLANTAS</p>
              </div>
              <div className="uk-width-1-6">
                <img className="uk-padding-remove" src="https://statics.sibcolombia.net/sib-resources/images/catalogo/inicio/png/h-hongos.png" alt="" />
                <p className="uk-margin-remove uk-text-center uk-text-large uk-text-bold">2.500</p>
                <p className="uk-margin-remove uk-text-center uk-text-xsmall">HONGOS</p>
              </div>
              <div className="uk-width-1-6">
                <img className="uk-padding-remove" src="https://statics.sibcolombia.net/sib-resources/images/catalogo/inicio/png/h-chromista.png" alt="" />
                <p className="uk-margin-remove uk-text-center uk-text-large uk-text-bold">2.500</p>
                <p className="uk-margin-remove uk-text-center uk-text-xsmall">CHROMISTA</p>
              </div>
              <div className="uk-width-1-6">
                <img className="uk-padding-remove" src="https://statics.sibcolombia.net/sib-resources/images/catalogo/inicio/png/h-otros.png" alt="" />
                <p className="uk-margin-remove uk-text-center uk-text-large uk-text-bold">1.000</p>
                <p className="uk-margin-remove uk-text-center uk-text-xsmall">OTROS</p>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-container uk-width-5-6@l uk-width-2-3@xl uk-margin-top">
          <div className="uk-grid-small" data-uk-grid="">
            <div className="uk-width-2-3">
              <DataSheet scroll="descripcion" title="Descripción">
                { map(data.descripcion.split('\n'), (d)=>
                  <p>{d}</p>
                )}
              </DataSheet>
              <DataSheet scroll="organizaciones" title="Organizaciones">
                <OrganizationList organizations={this.state.organizaciones} />
              </DataSheet>
            </div>
            <div className="uk-width-1-3">
              <DataSheet scroll="revisores" title="Colaborador" size="4">
                <UserList users={data.usuarios} rol='colaborador' />
              </DataSheet>
              <DataSheet scroll="participante" title="Participante" size="4">
                <UserList users={data.usuarios} rol='participante' />
              </DataSheet>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Summary;
