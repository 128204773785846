import React, { Component } from 'react';
import { map, findIndex, compact, flattenDeep } from 'lodash';

import { T /* , extraerColumnas, extraerColumnas2*/ } from '../../../services/UtilService';
import DataSheet from '../DataSheet';


class TableObjects extends Component {
  render() {
    return (
      <div>Hello from TableObjects!</div>
    );
  }
}

function util(k, x, objs, modo) {
  if (modo === undefined) {
    modo = true;
  }

  const t = k.substring(0, k.length - 13);
  let u, o2;
  if (x[t + 'Unstructured'] !== undefined) {
    u = (<p>{x[t + 'Unstructured']}</p>);
  }

  if (x['distributionScope'] !== undefined) {
    o2 = (<h3>{T('distributionScope')}: {x['distributionScope']['type']}</h3>);
  }

  // console.log("Buscando en:")
  // console.log(x[t + "Atomized"])

  let listas = [];
  for (let i = 0; i < x[t + 'Atomized'].length; i++) {
    const objeto = x[t + 'Atomized'][i];
    const lista = map(objeto, (d, k) => {
      if ((findIndex(objs, (i) => {
        if (modo) {
          return i !== k;
        } else {
          return i === k;
        }
      })) === -1) {
        const o = {};
        o['nombre'] = k;
        o['valor'] = d;
        return o;
      }
    });
    listas.push(lista);
  }
  listas = compact(flattenDeep(listas));
  // console.log("La lista de objetos es: ")
  // console.log(listas)

  const r = map(listas, (l, k) => {
    // console.log("-----------------")
    // console.log("l, k", l)
    if (l['valor'] !== '') {

      // console.log("Intentando mostrar ", l, typeof l["valor"])

      // console.log("Verificando si puede entrar ", l)

      // try{
      // console.log(
      //          l["nombre"]==="actions",
      //          l["valor"].length>0,
      //          l["valor"][0]["measurementOrFact"]!==undefined,
      //          l["valor"][0]["measurementOrFact"]["measurementType"]!=="",
      //          l["valor"][0]["measurementOrFact"]["measurementValue"]!=="")
      // }catch(e){
      // }
      if (
        l['nombre'] !== 'ancillaryData' &&
        l['valor'] !== null &&
        (
          l['nombre'] !== 'actions' ||
          (
            l['nombre'] === 'actions' &&
            l['valor'].length > 0 &&
            l['valor'][0]['measurementOrFact'] !== undefined &&
            // l["valor"][0]["measurementOrFact"].length>0 &&
            (
              l['valor'][0]['measurementOrFact']['measurementType'] !== '' ||
              l['valor'][0]['measurementOrFact']['measurementValue'] !== ''
            )
          )
        )
      ) {
        // console.log("PUEDE ENTRAR")

        if (typeof l['valor'] === 'object') {
          // let ks2 = extraerColumnas(l["valor"], ["_id", "ancillaryData"])

          // console.log("Las columnas extras son")
          // console.log(l)

          // <h4>{T(key2)}</h4>
          return (<div key={k}><h4>{T(l['nombre'])}</h4>
            {
              map(l['valor'], (val3, key3) => {
                // console.log("MAP -> ", l["nombre"])
                // console.log(ks2)
                // console.log(val3)

                // console.log(typeof l["valor"])
                // console.log(l["valor"][val3])


                if (l['valor'][val3] !== '' && l['valor'][val3] !== null) {
                  if (typeof val3 === 'object') {
                    // console.log("Retornando un objeto a validar ", val3)
                    return map(val3, (val4, key4) => {
                      if (key4 !== '_id' && key4 !== 'ancillaryData' && key4 !== 'measurementOrFact') {
                        if (key4 !== 'strategy')
                          return <div key={key4}>{T(key4)}: {T(val4)}</div>;
                        else
                          return <div key={key4}>{T(val4)}</div>;
                      } else if (key4 === 'measurementOrFact') {
                        return DataSheet.TableWithoutStructure.util('XApprovedInUse', { 'XAtomized': { 'a': val3 } }, 'measurementOrFact', ['measurementType', 'measurementValue']);
                      }
                    });

                  } else if (typeof l['valor'][val3] === 'object') {
                    return map(l['valor'][val3], (val4, key4) => {
                      return <div key={key4}>c{T(key4)} d{T(val4)}: {l['valor'][val3][key4]} </div>;
                    });
                  } else {
                    if (val3 !== '' && val3 !== null) {
                      // return (<div key={key3}>a{T(key3)} b{T(val3)} c{l["valor"][val3]} </div>)
                      if (key3 === 'measurementValue' || typeof key3 === 'number') {
                        return (<div key={key3}>{T(val3)}</div>);
                      } else {
                        return (<div key={key3}>{T(key3)}: {T(val3)}</div>);
                      }
                    }
                  }

                }
              })
            }</div>);
        } else {
          return (<div key={k}><h3>{T(l['nombre'])}</h3><p>{l['valor']}</p></div>);
        }
      } else {
        // console.log("NO PUEDE ENTRAR")
        return <div key={k}></div>;
      }
    } else {
      return <div key={k}></div>;
    }
    // return (<div key={k}><h3>{T(l)}</h3></div>)
  });

  // console.log("Voy a retornar ", u, r)
  return (<div key={k}>{o2}{u}{r}</div>);
}

TableObjects.util = util;

export default TableObjects;
