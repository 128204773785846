/* eslint-disable react/prop-types */
import React, { Component } from 'react';

class Sidebar extends Component {
  render() {
    return (
      <div id="sidebar-search" className="uk-width-1-3@s uk-width-1-4@m uk-width-1-5@l uk-background-default uk-visible@s uk-box-shadow-medium" style={{ borderTop: 'solid 1.5px #ff7847' }} >
        {this.props.children}
      </div>
    );
  }
}



Sidebar.id = 'sidebar-search';

export default Sidebar;
