/* eslint-disable no-unused-vars */
import * as RecoverActions from '../actions';
import * as RecoverServices from '../newServices';

export const recoverRequest = () => {
  return async (dispatch, getState) => {
    const state = getState(); // getState() normally without immutable
    const recoverForm = state.get('recoverReducer').get('recoverForm');
    console.log('recoverRequest', recoverForm);
    try {
      dispatch(RecoverActions.fetchingRecoverRequest());
      const data = await RecoverServices.apiLogin.recovery(recoverForm);
      dispatch(RecoverActions.fetchingRecoverSuccess(data));
    } catch (error) {
      dispatch(RecoverActions.fetchingRecoverFailure(error.message));
    }
  };
};

export const recoverFormChange = (name, value) => {
  return async (dispatch, getState) => {
    // const state = getState().get('loginReducer'); // getState() normally without immutable
    try {
      dispatch(RecoverActions.setRecoverFormChange(name, value));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const recoverFieldError = fieldError => {
  return async (dispatch, getState) => {
    // const state = getState().get('loginReducer'); // getState() normally without immutable
    try {
      dispatch(RecoverActions.setRecoverFieldError(fieldError));
    } catch (error) {
      console.log(error.message);
    }
  };
};
