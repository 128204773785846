/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import UIkit from 'uikit';

export class Offcanvas extends Component {

  // componentDidMount() {
  //   UIkit.offcanvas('#my-id').show();
  // }

  // componentWillUnmount() {
  //   UIkit.offcanvas('#my-id').$destroy(true);
  // }

  render() {
    return (
      <div id="my-id" className="uk-box-shadow-small" onClick={(e) => e.preventDefault()} uk-offcanvas="" style={{ marginTop: 70, overflowY: 'scroll', 'background': '#FFF' }}>
        <div className="uk-offcanvas-bar2 uk-padding-remove uk-background-default" style={{ borderTop: 'solid 2px #ff7847' }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Offcanvas;
