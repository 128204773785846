/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import _ from 'lodash';

class Pagination extends Component {
  static defaultProps = {
    initialPage: 1,
  }

  constructor(props) {
    super(props);
    this.state = {
      pager: {},
    };
  }

  componentWillMount() {

    const { items } = this.props;
    if (items && items > 0) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidMount() {
    const { items } = this.props;
    if (items) {
      this.setPage(this.props.initialPage);
    }
  }

  setPage(page) {
    const { items } = this.props;
    let { pager } = this.state;

    if (page < 1 || page > pager.totalPages) {
      return;
    }
    pager = this.getPager(items, page);


    this.setState({ pager });

    this.props.onChangePage(pager.currentPage);
  }

  getPager(totalItems, currentPage) {
    currentPage = currentPage || 1;

    const pageSize = this.props.pageSize || 20;

    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage,
      endPage;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    const pages = _.range(startPage, endPage + 1);

    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  }

  render() {
    const { pager } = this.state;

    if (!pager.pages || pager.pages.length <= 1) {
      return null;
    }

    return (<div className="uk-pagination uk-flex-center" data-uk-margin="data-uk-margin">
      {/* eslint-disable-next-line */}
      <a className={`uk-button uk-button-default uk-flex-middle ${(pager.currentPage === 1 ? 'uk-disabled': '')}`} title="Anterior" data-uk-tooltip="" onClick={() => this.setPage(pager.currentPage - 1)} data-uk-scroll="">
        <span uk-pagination-previous=""></span>
      </a>
      {/* eslint-disable-next-line */
        pager.pages.map((page, index) => <a key={index} className={`uk-button uk-button-default ${(pager.currentPage === page? 'uk-active': '' )}`} onClick={() => this.setPage(page)} data-uk-scroll=''>{page}</a>)
      }
      {/* eslint-disable-next-line */}
      <a className={`uk-button uk-button-default uk-flex-middle ${(pager.currentPage === pager.totalPages? 'uk-disabled': '' )}`} title="Siguiente" data-uk-tooltip="" onClick={() => this.setPage(pager.currentPage + 1)} data-uk-scroll="">
        <span uk-pagination-next=""> </span>
      </a>

    </div>);
  }
}

export default Pagination;
