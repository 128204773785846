/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { map } from 'lodash';

class UsersTable extends Component {

  render() {
    return (<table className="uk-table uk-table-hover uk-table-striped">
      <thead>
        <tr>
          <th>Usuario</th>
          <th>Nombres</th>
          <th>Apellidos</th>
          <th>Roles</th>
          <th>Correo</th>
          <th>Operaciones</th>
        </tr>
      </thead>
      <tbody>
        {this.props.lista &&
        map(this.props.lista, (l, k)=>
          <tr key={k}>
            <td>{l.username}</td>
            <td>{l.name}</td>
            <td>{l.lastname}</td>
            <td><ul>{map(l.roles, (r, k2)=><li key={k2}>{r}</li>)}</ul></td>
            <td>{l.email}</td>
            <td>
              <a className="uk-button uk-button-default" onClick={() => this.props.op('editar', l)}>Editar</a>
              <a className="uk-button uk-button-danger" onClick={() => this.props.op('borrar', l)}>Borrar</a>
            </td>
          </tr>
        )}
      </tbody>
    </table>);
  }
}

export default UsersTable;
