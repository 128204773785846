/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import cx from 'classnames';


import SearchGrid from '../../organisms/SearchGrid';
import SearchList from '../../organisms/SearchList';

import * as CatalogService from '../../../services/CatalogService';

class Species extends Component {


  constructor(props) {
    super(props);
    this.state = {
      id: null,
    };
  }

  // https://api.gbif.org/v2/map/occurrence/adhoc/1/0/1@1x.png?style=orange.marker&bin=square&squareSize=256&locale=en&q=Pristimantis%20palmeri&advanced=false&srs=EPSG%3A4326


  componentWillMount(){
    CatalogService.getList('basic', 'q= ').then(data => {
      this.setState({ data });
    }).catch(err => console.log(err));

  }

  render() {
    const { data } = this.props;// locale=en&taxonKey=2425033&advanced=false&srs=EPSG%3A4326
    console.log('Los datos que llegan son: ');
    console.log(data);

    return (
      <div className="uk-container uk-width-5-6@l uk-width-2-3@xl uk-margin-top">
        <div className="uk-grid-collapse uk-child-width-expand uk-margin uk-flex uk-flex-bottom" data-uk-grid>
          <div className="uk-width-expand">
            <ul className="uk-flex-right" data-uk-tab="swiping: false;" data-uk-switcher>
              <li className={cx({ 'uk-active': !this.state.displayDatasets })} onClick={() => this.handleDisplay(false)}><a title="Moto grilla" data-uk-tooltip><span uk-icon="icon: grid"></span></a></li>
              <li className={cx({ 'uk-active': this.state.displayDatasets })} onClick={() => this.handleDisplay(true)}><a title="Modo lista" data-uk-tooltip><span uk-icon="icon: list"></span></a></li>
              <li>
                <a className="uk-text-right" title="Ordenar" data-uk-tooltip>A/Z</a>
                <div className="uk-padding-small" data-uk-dropdown="mode: click">
                  <ul className="uk-nav uk-dropdown-nav">
                    <li><a>Alfabético</a></li>
                    <li><a>Visitas</a></li>
                    <li><a>Peligro</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <a className="uk-text-right" title="Ordenar" data-uk-tooltip>Mejor coincidencia</a>
                <div className="uk-padding-small" data-uk-dropdown="mode: click">
                  <ul className="uk-nav uk-dropdown-nav">
                    <li><a>Mejor coincidencia</a></li>
                    <li><a>Más visitados</a></li>
                    <li><a>Mejor valorados</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={cx('uk-container uk-width-1-1', { 'uk-width-1-1': this.state.displayDatasets })}>
          {this.state.displayDatasets ? <SearchList data={this.state.data} /> : <SearchGrid data={this.state.data} />}
        </div>
      </div>
    );
  }
}

export default Species;
