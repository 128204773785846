import React, { Component } from 'react';
import { map, findIndex, compact, flattenDeep } from 'lodash';

import { T } from '../../../services/UtilService';

class ListText extends Component {
  render() {
    return (
      <div>Hello from ListText!</div>
    );
  }
}

function util(k, x) {
  // console.log("----------------------- Texto listaTextos")
  // console.log(x)

  const ignorar = ['_id', 'ancillaryData'];
  let lista = map(x, (d, k) => {
    if ((findIndex(ignorar, (i) => { return (i === k); })) === -1) return k;
  });
  lista = compact(flattenDeep(lista));
  // console.log(lista)

  const r = map(lista, (l, k) => {
    return (<div key={k}><h3>{T(l)}</h3><p>{x[l]}</p></div>);
  });

  return (<div>{r}</div>);
}

ListText.util = util;

export default ListText;
