export const FETCHING_USERS_REQUEST = '[USERS] USERS_REQUEST';
export const FETCHING_USERS_SUCCESS = '[USERS] USERS_SUCCESS';
export const FETCHING_USERS_FAILURE = '[USERS] USERS_FAILURE';
export const FETCHING_USER_ME_REQUEST = '[USERS] USER_ME_REQUEST';
export const FETCHING_USER_ME_SUCCESS = '[USERS] USER_ME_SUCCESS';
export const FETCHING_USER_ME_FAILURE = '[USERS] USER_ME_FAILURE';
export const FETCHING_CREATE_USER_REQUEST = '[USERS] CREATE_USER_REQUEST';
export const FETCHING_CREATE_USER_SUCCESS = '[USERS] CREATE_USER_SUCCESS';
export const FETCHING_CREATE_USER_FAILURE = '[USERS] CREATE_USER_FAILURE';
export const FETCHING_UPDATE_USER_REQUEST = '[USERS] UPDATE_USER_REQUEST';
export const FETCHING_UPDATE_USER_SUCCESS = '[USERS] UPDATE_USER_SUCCESS';
export const FETCHING_UPDATE_USER_FAILURE = '[USERS] UPDATE_USER_FAILURE';
export const FETCHING_DELETE_USER_REQUEST = '[USERS] DELETE_USER_REQUEST';
export const FETCHING_DELETE_USER_SUCCESS = '[USERS] DELETE_USER_SUCCESS';
export const FETCHING_DELETE_USER_FAILURE = '[USERS] DELETE_USER_FAILURE';
export const SET_USER_FORM_CHANGE = '[USERS] USER_FORM_CHANGE';
export const SET_USER_FIELD_ERROR = '[USERS] USER_FIELD_ERROR';
