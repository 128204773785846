import * as UserActions from '../typesActions';

export const fetchingUsersRequest = () => {
  return {
    type: UserActions.FETCHING_USERS_REQUEST,
  };
};

export const fetchingUsersSuccess = dataUsers => {
  return {
    type: UserActions.FETCHING_USERS_SUCCESS,
    payload: { dataUsers },
  };
};

export const fetchingUsersFailure = dataUsers => {
  return {
    type: UserActions.FETCHING_USERS_FAILURE,
    payload: { dataUsers },
  };
};

export const fetchingUserMeRequest = () => {
  return {
    type: UserActions.FETCHING_USER_ME_REQUEST,
  };
};

export const fetchingUserMeSuccess = dataUser => {
  return {
    type: UserActions.FETCHING_USER_ME_SUCCESS,
    payload: { dataUser },
  };
};

export const fetchingUserMeFailure = dataUser => {
  return {
    type: UserActions.FETCHING_USER_ME_FAILURE,
    payload: { dataUser },
  };
};

export const fetchingCreateUserRequest = () => {
  return {
    type: UserActions.FETCHING_CREATE_USER_REQUEST,
  };
};

export const fetchingCreateUserSuccess = data => {
  return {
    type: UserActions.FETCHING_CREATE_USER_SUCCESS,
    payload: { data },
  };
};

export const fetchingCreateUserFailure = data => {
  return {
    type: UserActions.FETCHING_CREATE_USER_FAILURE,
    payload: { data },
  };
};

export const setUserFormChange = (name, value) => {
  return {
    type: UserActions.SET_USER_FORM_CHANGE,
    payload: { name, value },
  };
};

export const setUserFieldError = error => {
  return {
    type: UserActions.SET_USER_FIELD_ERROR,
    payload: { error },
  };
};
