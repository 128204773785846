/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { map, findIndex, compact, flattenDeep } from 'lodash';

class Contact extends Component {
  render() {
    const { data } = this.props;
    const keys = [
      {
        key: 'elefante',
      }, {
        key: 'role',
      }, {
        key: 'personnelIdentifier',
      }, {
        key: 'personnelDirectory',
      }, {
        key: 'homepage',
      }, {
        key: 'email',
      }, {
        key: 'phone',
      }, {
        key: 'postalCode',
      }, {
        key: 'country',
      }, {
        key: 'state',
      }, {
        key: 'city',
      }, {
        key: 'address',
      }, {
        key: 'organisation',
      }, {
        key: 'position',
      },
    ];

    let rows = map(data, (values, key) => {
      if ((findIndex(keys, (k) => {
        return k.key === key;
      })) > 0) {
        return values;
      }
    });

    rows = compact(flattenDeep(rows));
    // console.log(rows)
    // console.log(rows.length)

    return (<div>
      <div className="uk-padding-small uk-margin-small-top">
        <h5 className="uk-text-bold uk-margin-small">
          {
            (typeof data.firstName !== 'undefined' ? data.firstName : '') + ' ' + (
              typeof data.lastName !== 'undefined' ? data.lastName : '')
          }
        </h5>
        { rows.length > 0 &&
          <ul className="uk-list uk-padding-small" style={{
            borderLeft: 'solid 2px #eee',
          }}
          >
            {map(rows, (value, key) => (<li key={key} className="uk-text-break">{value}</li>))}</ul>
        }

      </div>
    </div>);
  }
}

export default Contact;
