/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import {map} from 'lodash';

import Pagination from '../../atoms/Pagination';

import UsersTable from '../../molecules/admin/UsersTable';
import UsersForm from '../../molecules/admin/UsersForm';

import * as UserService from '../../../services/UserService';

class Users extends Component {

  componentWillMount(){
    this.buscarUsuarios();
  }

  buscarUsuarios(){

    UserService.get().then(data => {
      // console.log("Consultando UserService")

      const pageSize = 5;
      const sublista = data.slice(0, pageSize);
      this.setState({ lista: data, pagina: 0, sublista, pageSize });

      /*
        Buscar la fichas del album
      CatalogService.getRelatedRecords(data.hierarchy[0].family).then(related => {
        this.setState({ related })
      })
      */
    });
  }

  onChangePage(pagina){
    const sublista = this.state.lista.slice(pagina*this.state.pageSize, (pagina+1)*this.state.pageSize);
    this.setState({ pagina, sublista });
  }

  operacion(op, id){
    console.log(op, id);
    if(op==='editar'){
      this.setState({ usuario: id });
    }else if(op==='borrar'){
      this.eliminar(id);
    }
  }
  eliminar(u){
    if(window.confirm('Desea eliminar el usuario '+u.username)){
      UserService.del(u._id).then(data => {
        alert('Usuario eliminado');
        this.buscarUsuarios();
      });
    }
  }


  guardar(u){
    // console.log("Guardando ", u)
    const id = ''+u._id;
    const v = JSON.parse(JSON.stringify(u));
    delete v._id;
    // delete v.roles
    delete v.status;
    delete v.social;
    delete v.provider;


    UserService.put(id, v).then(data => {
      alert('Usuario actualizado');
      this.setState({ usuario: undefined });
    });
  }
  cancelar(){
    this.setState({ usuario: undefined });
  }

  render() {
    console.log(this.state);
    return (<div className="uk-padding">
      <h2>Administración de usuarios</h2>
      {this.state &&
      <div>
        <UsersTable lista={this.state.sublista} op={(o, id)=>{this.operacion(o, id);}} />
        <Pagination
          pageSize = {this.state.pageSize}
          items={this.state.lista.length}
          onChangePage={(number) => {
            this.onChangePage(number - 1);
          }}
        />
        {this.state.usuario &&
        <UsersForm
          usuario={this.state.usuario}
          guardar={(u)=>this.guardar(u)}
          cancelar={()=>this.cancelar()}
        />
        }
      </div>
      }

    </div>);
  }
}

export default Users;
