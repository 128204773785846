import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Components */
import Evaluacion from '../../uicn/Evaluacion';
import Distribuccion from '../../uicn/Distribuccion';
import Poblacion from '../../uicn/Poblacion';
import Amenazas from '../../uicn/Amenazas';
import Habitat from '../../uicn/Habitat';
import Conservacion from '../../uicn/Conservacion';
import Uso from '../../uicn/Uso';
import Enlace from '../../uicn/Enlace';
import Aportes from '../../uicn/Aportes';
import ListaRoja from '../../uicn/ListaRoja';

const campoValido = (c) => {
  return c!==undefined && c!==null && c!==''
}

const validar = (data, id) => {
  console.log("DATA data: ", data)
  console.log("DATA id  : ", id)
  if(id==="evaluacion"){
    return  campoValido(data.assessment.category) ||
            campoValido(data.assessment.criteria) ||
            campoValido(data.assessment.date) ||
            campoValido(data.assessment.language) ||
            campoValido(data.assessment.assesor) ||
            campoValido(data.assessment.reviewer) ||
            campoValido(data.assessment.contributor) ||
            campoValido(data.assessment.facilitator) ||
            campoValido(data.assessment.scope) ||
            campoValido(data.assessment.justification)
  }
  if(id==="distribuccion"){
    return  campoValido(data.distribution.country) ||
            campoValido(data.distribution.range) ||
            campoValido(data.distribution.elevationLower) ||
            campoValido(data.distribution.elevationUpper) ||
            campoValido(data.distribution.eoo) ||
            campoValido(data.distribution.aoo) ||
            campoValido(data.distribution.locationsNumber)
  }
  if(id==="poblacion"){
    return  campoValido(data.population.justification) ||
            campoValido(data.population.severeFragmentation) ||
            campoValido(data.population.subpopulation)
  }
  if(id==="amenazas"){
    return  campoValido(data.threats.documentation)
  }
  if(id==="habitat"){
    return  campoValido(data.habitat.documentation) ||
            campoValido(data.habitat.system)
  }
  if(id==="conservacion"){
    return  campoValido(data.conservation.actions)
  }
  if(id==="uso"){
    return  campoValido(data.use.documentation)
  }
  if(id==="partes_asociadas"){
    return  campoValido(data.assessment.category) ||
            campoValido(data.assessment.criteria) ||
            campoValido(data.assessment.date) ||
            campoValido(data.assessment.language) ||
            campoValido(data.assessment.assesor) ||
            campoValido(data.assessment.reviewer) ||
            campoValido(data.assessment.contributor) ||
            campoValido(data.assessment.facilitator) ||
            campoValido(data.assessment.scope) ||
            campoValido(data.assessment.justification)
  }
  
  return true;
}
const RiesgoExtincion = (props) => {

  console.log("props: ", props)
  console.log("data: ", props.data)
  console.log("data2: ", props.data2)

  return (
    <div className="uk-container uk-width-5-6@l uk-width-2-3@xl uk-margin-top">
      <div className="uk-grid-small" data-uk-grid="">
        <div className="uk-width-1-3">
          <div className="uk-card uk-card-default" data-uk-sticky="offset: 90; bottom: true" style={{ zIndex: 979 }}>
            <div className="uk-card-header">
              <h4>Riesgo de extinción</h4>
            </div>
            <div className="uk-padding-small">
              <ul className="uk-list uk-list-large uk-margin-remove-bottom" uk-scrollspy-nav="closest: li; cls: uk-text-primary; offset: 89;">
                {validar(props.data2, "evaluacion") && <li><a className="uk-link-reset" href="#evaluacion" data-uk-scroll="offset: 90">Evaluación</a></li>}
                {!validar(props.data2, "evaluacion") && <li style={{"color":"#cccccc"}}>Evaluación</li>}
                {validar(props.data2, "distribuccion") && <li><a className="uk-link-reset" href="#distribuccion" data-uk-scroll="offset: 90">Distribución</a></li>}
                {!validar(props.data2, "distribuccion") && <li style={{"color":"#cccccc"}}>Distribución</li>}
                {validar(props.data2, "poblacion") && <li><a className="uk-link-reset" href="#poblacion" data-uk-scroll="offset: 90">Población</a></li>}
                {!validar(props.data2, "poblacion") && <li style={{"color":"#cccccc"}}>Población</li>}
                {validar(props.data2, "amenazas") && <li><a className="uk-link-reset" href="#amenazas" data-uk-scroll="offset: 90">Amenazas</a></li>}
                {!validar(props.data2, "amenazas") && <li style={{"color":"#cccccc"}}>Amenazas</li>}
                {validar(props.data2, "habitat") && <li><a className="uk-link-reset" href="#habitat" data-uk-scroll="offset: 90">Hábitat y ecología</a></li>}
                {!validar(props.data2, "habitat") && <li style={{"color":"#cccccc"}}>Hábitat y ecología</li>}
                {validar(props.data2, "conservacion") && <li><a className="uk-link-reset" href="#conservacion" data-uk-scroll="offset: 90">Conservación</a></li>}
                {!validar(props.data2, "conservacion") && <li style={{"color":"#cccccc"}}>Conservación</li>}
                {validar(props.data2, "uso") && <li><a className="uk-link-reset" href="#uso" data-uk-scroll="offset: 90">Uso</a></li>}
                {!validar(props.data2, "uso") && <li style={{"color":"#cccccc"}}>Uso</li>}
                {validar(props.data2, "partes_asociadas") && <li><a className="uk-link-reset" href="#partes_asociadas" data-uk-scroll="offset: 90">Partes asociadas</a></li>}
                {!validar(props.data2, "partes_asociadas") && <li style={{"color":"#cccccc"}}>Partes asociadas</li>}
              </ul>
            </div>
          </div>
        </div>
        <div className="uk-width-expand">
          <ListaRoja data={props.data}/>
          {validar(props.data2, "evaluacion") && <Evaluacion />}
          {validar(props.data2, "distribuccion") && <Distribuccion />}
          {validar(props.data2, "poblacion") && <Poblacion />}
          {validar(props.data2, "amenazas") && <Amenazas />}
          {validar(props.data2, "habitat") && <Habitat />}
          {validar(props.data2, "conservacion") && <Conservacion />}
          {validar(props.data2, "uso") && <Uso />}
          {validar(props.data2, "partes_asociadas") && <Aportes />}
          <Enlace />
        </div>
      </div>
    </div>
  )
}

RiesgoExtincion.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data2: state.get('iucnReducer').get('data'),
});


export default connect(
  mapStateToProps,
  null
)(withRouter(RiesgoExtincion));
//export default RiesgoExtincion;
