import { fromJS } from 'immutable';
import * as LoginActions from '../typesActions';

const INITIAL_STATE = fromJS({
  loginForm: {
    username: '',
    password: '',
  },
  isLogged: false,
  loading: false,
  error: '',
  data: {},
});

const loginReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LoginActions.FETCHING_LOGIN_REQUEST: {
      return state
        .set('isLogged', false)
        .set('loading', true);
    }

    case LoginActions.FETCHING_LOGIN_SUCCESS: {
      return state
        .set('isLogged', true)
        .set('loading', false)
        .set('error', '')
        .set('data', payload.data);
    }

    case LoginActions.FETCHING_LOGIN_FAILURE: {
      return state
        .set('isLogged', false)
        .set('loading', false)
        .set('data', payload.data);
    }

    case LoginActions.SET_LOGIN_FORM_CHANGE: {
      const { name, value } = payload;
      const loginForm = (typeof state.get('loginForm').password === 'undefined') ? state.get('loginForm').toJS() : state.get('loginForm');
      console.log('SET_LOGIN_FORM_CHANGE', loginForm);
      return state
        .set('loginForm', { ...loginForm, [name]: value });
    }

    case LoginActions.SET_LOGIN_FIELD_ERROR: {
      return state
        .set('error', payload.error);
    }

    default: {
      return state;
    }
  }
};

export default loginReducer;
