import React, { Component } from 'react';

// Components
import LogicPage from '../components/templates/LogicPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import Organizations from '../components/organisms/admin/Organizations';
import Albums from '../components/organisms/admin/Albums';
import Stats from '../components/organisms/admin/Stats';
import Users from '../components/organisms/admin/Users';

// import { getUser } from '../auth';

class Admin extends Component {
  constructor() {
    super();
    this.state = {
      select: 0,
    };
  }

  componentWillMount() { }

  componentDidMount() { }

  render() {
    return (
      <LogicPage
        titlep="Catálogo de la biodiversidad"
        headercustom={<Header />}
        footer={<Footer />}
      >
        <div className="uk-flex uk-height-1-1" >
          <ul className="uk-width-1-6 uk-tab uk-tab-left" uk-tab="" data-uk-switcher={'connect: #admin;'}>
            <li><a href="">Estadísticas</a></li>
            <li className="uk-active"><a href="">Usuarios</a></li>
            <li><a href="">Organizaciones</a></li>
            <li><a href="">Álbums</a></li>
          </ul>

          <ul className="uk-width-5-6 uk-switcher" id="admin">
            <li><Stats /></li>
            <li><Users /></li>
            <li><Organizations /></li>
            <li><Albums /></li>
          </ul>
        </div>
      </LogicPage>
    );
  }
}

export default Admin;
