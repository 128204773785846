import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Style Components */
import { Paragraph } from '../../pages/styled';

const Amenazas = ({ data }) => {
  const { threats } = data;
  const { documentation } = threats;

  return (
    <div className="uk-margin">
      <div id={'amenazas'} className={'uk-card uk-card-default uk-panel uk-padding uk-text-break'}>
        <div className="uk-flex uk-flex-column">
          <span className={'uk-h3 uk-text-tertiary uk-text-bold'}>{'Amenazas'}</span>
        </div>

        <div className="uk-padding-small type_hierarchy">
          <div className="uk-child-width-1-1">
            <Paragraph>{documentation}</Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

Amenazas.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.get('iucnReducer').get('data'),
});


export default connect(
  mapStateToProps,
  null
)(withRouter(Amenazas));
