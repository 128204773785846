import { join } from 'lodash';

import { URL_API_DATOS } from '../config/const';
import { request } from '../util';

const xhr = new XMLHttpRequest();

export function getOccurrenceList(query, search) {
  if(query !== undefined && query.length > 0) {
    query = '?' + join(query, '&');
  } else {
    query = search;
  }

  return request(xhr, 'GET', `${URL_API_DATOS}/api/geography/search${query}`);
}
