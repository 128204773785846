import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Dispatchers */
import { resetFormChange, resetFieldError, resetRequest } from '../dispatchers';

/* Components */
import OauthPage from '../components/templates/OauthPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';

/* Style Components */
import { Container, LinkStyled } from './styled';

const wait = (ms) => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, ms);
});

const Reset = ({ resetForm, error, data, loading, onResetFormChange, onResetFieldError, onSendResetRequest, match, history }) => {
  const { params } = match;
  const { id } = params;
  const [tokenText] = useState(id);
  const [errorText, setErrorText] = useState(error);
  const [classResponse, setClassResponse] = useState('');
  const [response, setResponse] = useState('');

  const handleChange = event => {
    const { name, value } = event.target;
    console.log(name, value);
    onResetFormChange(name, value);
    onResetFieldError('');
    setErrorText('');
  };

  const handleChangePassword = async event => {
    const { password, verify, token } = resetForm;
    console.log('resetForm handleChangePassword', resetForm, error);
    if(password === '') { onResetFieldError('El password no puede estar vació'); setErrorText('El password no puede estar vació'); return false; }
    if(verify === '') { onResetFieldError('La confirmación de password no puede estar vaciá'); setErrorText('La confirmación de password no puede estar vaciá'); return false; }
    if(password !== verify) { onResetFieldError('La confirmación de contraseña no coincide'); setErrorText('La confirmación de contraseña no coincide'); return false; }
    if(password !== '' && verify !== '' && token !== '') {
      onSendResetRequest();
      await wait(3000);
    }

    event.stopPropagation();
  };

  const loadResponse = async () => {
    if(typeof data !== 'undefined') {
      if(typeof data.response !== 'undefined') {
        if(data.response === 'password updated') {
          setResponse('Se ha actualizado correctamente su contraseña.'); setClassResponse('uk-alert-success');
          await wait(3000);
          history.push('/');
        }
      } else if(typeof data.error !== 'undefined') {
        setResponse('Se genero el siguiente error: ' + data.error); setClassResponse('uk-alert-danger');
      }
    } else {
      onResetFieldError('El token expiró');
      setErrorText('El token expiró');
    }
  };

  useEffect(() => {
    console.log('useEffect 1', tokenText);
    if(tokenText !== '') onResetFormChange('token', tokenText);
  }, [tokenText, onResetFormChange]);

  useEffect(() => {
    console.log('useEffect 2', data);
    loadResponse();
  }, [data]);

  return (
    <OauthPage titlep="Catálogo de la biodiversidad" header={<Header />} footer={<Footer />}>
      <form className="uk-margin-auto uk-margin-auto-vertical uk-width-large uk-card uk-card-default" style={{ borderRadius: '3px' }}>
        <Container>
          <div className="uk-padding-small uk-flex uk-flex-column">
            <img className="uk-margin-auto" src="https://s3.amazonaws.com/sib-resources/images/logos-canales/svg/simbolo-catalogo.svg" width="200" alt="" />
            <h4 className="uk-margin-remove-top uk-text-center" style={{ fontWeight: 200 }}>Catálogo de la Biodiversidad de Colombia</h4>
            {loading && (
              <div className={'uk-alert-warning uk-alert'} uk-alert="true">
                <a className="uk-alert-close uk-icon uk-close" uk-close="true"></a>
                <p className="response">
                  <span className="uk-margin-small-right" uk-spinner="ratio: 2"></span>
                  {'Cargando..'}
                </p>
              </div>
            )}
            {response !== '' && (
              <div className={`${classResponse} uk-alert`} uk-alert="true">
                <a className="uk-alert-close uk-icon uk-close" uk-close="true"></a>
                <p className="response">{response}</p>
              </div>
            )}
            <div className="uk-margin-small">
              <input className="uk-input" type="password" name="password" placeholder="Ingresa nueva contraseña" onChange={handleChange} autoFocus />
            </div>
            <div className="uk-margin-small">
              <input className="uk-input" type="password" name="verify" placeholder="Repite su contraseña" onChange={handleChange} />
            </div>
            {errorText !== '' && (
              <div className="uk-alert-danger uk-alert" uk-alert="true">
                <p className="error">{errorText}</p>
              </div>
            )}
            <LinkStyled active={errorText} className="uk-button uk-button-primary uk-width-1-1 uk-margin-small-top uk-text-bold" onClick={(e) => handleChangePassword(e)}>Cambiar contraseña</LinkStyled>
          </div>
        </Container>
      </form>
    </OauthPage>
  );
};

Reset.propTypes = {
  resetForm: PropTypes.shape({
    password: PropTypes.string,
    verify: PropTypes.string,
    token: PropTypes.string,
  }),
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.oneOf([true, false]).isRequired,
  error: PropTypes.string.isRequired,
  onResetFormChange: PropTypes.func.isRequired,
  onResetFieldError: PropTypes.func.isRequired,
  onSendResetRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const resetForm = (typeof state.get('resetReducer').get('resetForm').password === 'undefined') ? state.get('resetReducer').get('resetForm').toJS() : state.get('resetReducer').get('resetForm');
  console.log('mapStateToProps reset', resetForm);
  return {
    data: state.get('resetReducer').get('data'),
    resetForm,
    loading: state.get('resetReducer').get('loading'),
    error: state.get('resetReducer').get('error'),
  };
};

const mapDispatchToProps = dispatch => ({
  onResetFormChange: (name, value) => dispatch(resetFormChange(name, value)),
  onResetFieldError: (error) => dispatch(resetFieldError(error)),
  onSendResetRequest: () => dispatch(resetRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Reset));
