import { combineReducers } from 'redux-immutable';

import albumReducer from './album';
import filtersReducer from './filters';
import iucnReducer from './iucn';
import loginReducer from './login';
import permissionReducer from './permission';
import rolReducer from './rol';
import userReducer from './user';
import resetReducer from './reset';
import recoverReducer from './recover';

const allReducers = combineReducers({
  albumReducer,
  filtersReducer,
  iucnReducer,
  loginReducer,
  permissionReducer,
  rolReducer,
  userReducer,
  resetReducer,
  recoverReducer,
});

export default allReducers;
