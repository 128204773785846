/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import cx from 'classnames';
import UIKit from 'uikit';

import ArrayListText from './datasheet/ArrayListText';
import IgnoreCard from './datasheet/IgnoreCard';
import TextWithoutStructure from './datasheet/TextWithoutStructure';
import ListWithoutStructure from './datasheet/ListWithoutStructure';
import ListText from './datasheet/ListText';
import TablesWithoutStructure from './datasheet/TablesWithoutStructure';
import TableWithoutStructure from './datasheet/TableWithoutStructure';
import Table from './datasheet/Table';
import TableObjects from './datasheet/TableObjects';
import TextsWithoutStructure from './datasheet/TextsWithoutStructure';
import CardValue from './datasheet/CardValue';
import ToReview from './datasheet/ToReview';
import { toCamelCase } from '../../services/UtilService';

class DataSheet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: null,
      expand: false,
    };
  }

  buildForm() {
    const { dynamicForm, id } = this.props;
    const label = Object.keys(dynamicForm)[0];
    let modal = null;

    UIKit.modal(`#${label}`) && UIKit.modal(`#${label}`).show();

    switch (label) {
      // case "ancillaryData":
      // TODO: blockquote
      // break;

      case 'ancillaryData':
        // TODO: Plinian
        break;

      case 'taxonRecordName':
        // TODO: Plinian
        break;

      case 'hierarchy':
        // TODO: IgnoreCard
        modal = (
          <Modal title={this.props.title} id={label}>
            <IgnoreCard inputs={dynamicForm} label={label} />
          </Modal>
        );

        break;

      case 'associatedParty':
        // TODO: CardValue
        modal = (
          <Modal title={this.props.title} id={label}>

          </Modal>
        );
        break;

      case 'abstract':
        // TODO: TextWithoutStructure
        modal = (
          <Modal title={this.props.title} id={label}>
            <TextWithoutStructure text={dynamicForm.abstract} label={label} id={id} action={`/record/${id}/abstract`} />
          </Modal>
        );
        break;

      case 'lifeCycle':
      case 'fullDescription':
      case 'moreInformation':
      case 'behavior':
      case 'habitats':
        // TODO: TextWithoutStructure
        modal = (
          <Modal title={this.props.title} id={label}>
            <TextWithoutStructure text={dynamicForm[label][label + 'Unstructured']} label={label} />
          </Modal>
        );
        break;

      case 'distribution':
        // TODO: ListWithoutStructure
        break;

      case 'identificationKeys':
        // TODO: ListText
        modal = (
          <Modal title={this.props.title} id={label}>
            <TextWithoutStructure text={dynamicForm[label]['keys']} label={label} />
          </Modal>
        );
        break;

      case 'threatStatus':
        // TODO: TablesWithoutStructure
        break;

      case 'ecologicalSignificance':
      case 'lifeForm':
      case 'directThreats':
        // TODO: TableWithoutStructure
        modal = (
          <Modal title={this.props.title} id={label}>
            <TableWithoutStructure text={dynamicForm} label={label} />
          </Modal>
        );
        break;

      case 'environmentalEnvelope':
        // TODO: TableWithoutStructure
        break;

      case 'reproduction':
        // TODO: TableWithoutStructure
        break;


      case 'dispersal':
      case 'migratory':
      case 'molecularData':
      case 'feeding':
      case 'legislation':
      case 'territory':
      case 'populationBiology':
      case 'invasiveness':
        // TODO: TextsWithoutStructure
        break;

      case 'references':
        // TODO: Plinian
        break;

      case 'commonNamesAtomized':
        // TODO: Table
        break;

      case 'endemicAtomized':
        // TODO: ArrayListText
        break;

      default:
      // TODO:
    }

    this.setState({ modal });
  }

  toReviews() {
    const { id, dynamicForm, title, toReview } = this.props;
    const label = Object.keys(dynamicForm)[0];
    UIKit.modal(`#${label}v`) && UIKit.modal(`#${label}v`).show();
    const modal = (
      <Modal title={title + ' - Versiones'} id={label + 'v'}>
        <ToReview id={id} label={toCamelCase(label)} versions={toReview(dynamicForm[label], label + 'ApprovedInUse', 0)} />
      </Modal>
    );
    this.setState({ modal });
  }

  extras(){
    this.setState({ expand: !this.state.expand });
  }

  render() {
    let tam = this.props.size;
    if (tam===undefined){
      tam = '3';
    }
    return (
      <div className="uk-margin">
        <div id={this.props.scroll} className={cx('uk-card uk-card-default uk-panel uk-padding uk-text-break', this.props.className)}>
          {this.props.title &&
            <div className="uk-flex uk-flex-column">
              <span className={'uk-h'+tam+' uk-text-tertiary uk-text-bold'}>{this.props.title}</span>
            </div>
          }
          {
            this.props.dynamicForm &&
            <div className="uk-position-top-right uk-overlay uk-overlay-default">
              <div className="uk-inline">
                <span data-uk-icon="icon: more-vertical" style={{ cursor: 'pointer' }} />
                <div data-uk-dropdown="mode: click" className="uk-padding-small">
                  <ul className="uk-nav uk-dropdown-nav">
                    <li><a onClick={() => this.buildForm()} className="uk-text-bold"><span data-uk-icon="icon: pencil" className="uk-margin-small-right" />Editar</a></li>
                    <li><a onClick={() => this.toReviews()} className="uk-text-bold"><span data-uk-icon="icon: album" className="uk-margin-small-right" />Versiones</a></li>
                  </ul>
                </div>
              </div>
            </div>
          }
          {this.props.children}
          {this.state.modal && this.state.modal}
        </div>
        {this.props.references && this.props.references!==null &&
          <div className="uk-card uk-card-default uk-panel">
            <div className="reference-title uk-flex uk-flex-middle uk-flex-between" onClick={()=>{this.extras();}}>
              <span>
                Referencias
                {!this.state.expand && <span data-uk-icon="triangle-down"></span>}
                {this.state.expand && <span data-uk-icon="triangle-up"></span>}
              </span>
              <span className="provider">{this.props.provider}</span>
            </div>
            {this.state.expand &&
              <div className="reference-content">
                <span className="lineaR" />
                {this.props.references}
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

class Modal extends Component {

  componentDidMount() {
    UIKit.modal(`#${this.props.id}`).show();
  }

  render() {
    return (
      <div id={this.props.id} data-uk-modal>
        <div className="uk-modal-dialog uk-width-2-3">
          <div className="uk-modal-header">
            <button className="uk-modal-close-default" type="button" data-uk-close></button>
            <h3 className="uk-modal-title">{this.props.title}</h3>
          </div>
          <div className="uk-modal-body">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}


DataSheet.TableObjects = TableObjects;
DataSheet.ArrayListText = ArrayListText;
DataSheet.IgnoreCard = IgnoreCard;
DataSheet.TextWithoutStructure = TextWithoutStructure;
DataSheet.ListWithoutStructure = ListWithoutStructure;
DataSheet.ListText = ListText;
DataSheet.TablesWithoutStructure = TablesWithoutStructure;
DataSheet.TableWithoutStructure = TableWithoutStructure;
DataSheet.Table = Table;
DataSheet.TextsWithoutStructure = TextsWithoutStructure;
DataSheet.CardValue = CardValue;
DataSheet.Modal = Modal;
DataSheet.ToReview = ToReview;

export default DataSheet;
