import * as RecoverActions from '../typesActions';

export const fetchingRecoverRequest = () => {
  return {
    type: RecoverActions.FETCHING_RECOVER_REQUEST,
  };
};

export const fetchingRecoverSuccess = data => {
  return {
    type: RecoverActions.FETCHING_RECOVER_SUCCESS,
    payload: { data },
  };
};

export const fetchingRecoverFailure = data => {
  return {
    type: RecoverActions.FETCHING_RECOVER_FAILURE,
    payload: { data },
  };
};

export const setRecoverFormChange = (name, value) => {
  return {
    type: RecoverActions.SET_RECOVER_FORM_CHANGE,
    payload: { name, value },
  };
};

export const setRecoverFieldError = error => {
  return {
    type: RecoverActions.SET_RECOVER_FIELD_ERROR,
    payload: { error },
  };
};
