/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { map, findIndex, compact, flattenDeep } from 'lodash';
import { T } from '../../services/UtilService';

import References from '../atoms/References';


class Plinian extends Component {

  listaValidos(data){
    const ignorar = ['_id', 'ancillaryData'];
    const lista = map(data, (d, k) => {
      // console.log("verificando", d, data[k]);
      if ((findIndex(ignorar, (i) => {return i === k;})) === -1 &&
        (
          (typeof d === Array && d.length>0) ||
          (typeof d === String && d!=='')
        )
      ){
        return k;
      }
    });
    return compact(flattenDeep(lista));
  }

  head_measurementOrFact(data){
    // let llaves = this.listaValidos(data);
    // console.log("Llaves validas", llaves)

    return <tr>
      <td>{T('measurementAccuracy')}</td>
      <td>{T('measurementDeterminedBy')}</td>
      <td>{T('measurementDeterminedDate')}</td>
      <td>{T('measurementID')}</td>
      <td>{T('measurementMethod')}</td>
      <td>{T('measurementRemarks')}</td>
      <td>{T('measurementType')}</td>
      <td>{T('measurementUnit')}</td>
      <td>{T('measurementValue')}</td>
      <td>{T('relatedTo')}</td>
    </tr>;
  }

  measurementOrFact(data){
    // let llaves = this.listaValidos(data);
    // console.log("Llaves validas", llaves)
    return <tr>
      <td>{data.measurementAccuracy && data.measurementAccuracy}</td>
      <td>{data.measurementDeterminedBy.length>0 && map(data.measurementDeterminedBy, (v, k)=>(
        <span>{v}, </span>
      ))}</td>
      <td>{data.measurementDeterminedDate && data.measurementDeterminedDate}</td>
      <td>{data.measurementID && data.measurementID}</td>
      <td>{data.measurementMethod && data.measurementAccuracy}</td>
      <td>{data.measurementRemarks && data.measurementRemarks}</td>
      <td>{data.measurementType && data.measurementType}</td>
      <td>{data.measurementUnit && data.measurementUnit}</td>
      <td>{data.measurementValue && data.measurementValue}</td>
      <td>{data.relatedTo && data.relatedTo}</td>
    </tr>;
  }


  taxonRecordName(data){
    /*
    console.log("taxonRecordName, ", data)
        ancillaryData Array []
        attributes Object  nomenclaturalCode "", isAnamorphic false, id null }
        basedOn Object  microReference "", note "", ruleConsidered "", … }
        basionym Object  microReference "", note "", ruleConsidered "", … }
        canonicalName Object  uninomial "", simple "Alouatta seniculus", epithet …}, … }
        conservedAgainst Array []
        laterHomonymOf Object  microReference "", note "", ruleConsidered "", … }
        microReference ""
        providerLink ""
        providerSpecificData Object  anyTwo "", anyOne [] }
        publicationStatus Object  microReference "", note "", ruleConsidered "", … }
        publishedln Object  simple "Syst. Nat. , 12th ed. vol. 1 p. 37", source "", datatype "", … }
        replacementNameFor Object  microReference "", note "", ruleConsidered "", … }
        sanctioned Object  microReference "", note "", ruleConsidered "", … }
        specialAuthorship Object  combinationAuthorship [], basionymAuthorship …} }
        spellingCorrentionOf Array []
        typeNameEntity Object  lectotypeMicroReference …}, lectotypePublication …}, nameReference …} }
        typificacion Object  simple "", typeVoucherEntity …} }
        year ""
    */
    return (<div>
      { data.scientificName.simple && <h3 className="scientificName">{data.scientificName.simple}</h3> }
      { data.scientificName.publishedln && data.scientificName.publishedln.simple && <div className="reference-content">{data.scientificName.publishedln.simple}</div> }
      { data.scientificName.rank && <span>Categoría: {data.scientificName.rank}</span> }
    </div>);
  }


  ancillaryData(d){
    let a = map(d, (v, k)=>{
        //console.log("Retornando información de ancillaryData ", v)
        if (v.additionalInformation!=='' && v.source!==''){
          return <li key={k}>
            {v.additionalInformation!=='' && v.additionalInformation}
            {v.source!=='' && v.source}
          </li>
        }
      })
    let result = a.join('')
    //console.log("El a es ", a, " result es ", result)
    
    if (result !== ''){
      return (<ul>{
        a
      }</ul>);
    }
    return <></>
  }



  source(v){
    return <ul>
        <li>
          {v!=='' && v}
        </li>
    </ul>
  }



  render() {
    const { data, type } = this.props;
     let lista = this.listaValidos(data)
/*
     console.log("PLINIAM: ")
     console.log(data)
     console.log(type)
     console.log(lista)
*/
    if (type==='references'){
      let r = <References references={data} />;
      //console.log("En plinian las referencias son: ", r)
      return r
    }

    if (this[type] !== undefined)
      return this[type](data);

    return (<div>
        Soy un elemento plinian sin definir {type}
    </div>);
  }
}

export default Plinian;
