/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { map } from 'lodash';

class FList extends Component {

  constructor(props) {
    super(props);
    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(event) {
    this.props.onDataChange({ path: this.props.path, value: event.target.value });
  }

  render() {
    return (
      <div>
        <h5>{this.props.value.title}</h5>
        <select name={this.props.value.name} className="uk-select" onChange={ this.updateValue }>
          {map(this.props.value.values, (v) => (
            <option value={v.value}>{v.text}</option>
          ))}
        </select>
      </div>
    );
  }
}

export default FList;
