import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

/* Style Components */
import { Paragraph, TableStyled } from '../../pages/styled';

const Evaluacion = ({ data }) => {
  const { assessment } = data;
  const { category, criteria, date, language, assesor, reviewer, contributor, facilitator, scope, justification } = assessment;
  console.log("assessment")
  const dateFormatted = dayjs(date).format('YYYY-MM-DD');
  const arrContributor = contributor.split(',');
  return (
    <div>
      <div className="uk-margin">
        <div id={'evaluacion'} className={'uk-card uk-card-default uk-panel uk-padding uk-text-break'}>
          <div className="uk-flex uk-flex-column">
            <span className={'uk-h3 uk-text-tertiary uk-text-bold'}>{'Evaluación'}</span>
          </div>

          <div className="uk-padding-small type_hierarchy">
            <div className="uk-child-width-1-1">
              <li>
                <div className="uk-child-width-1-2 uk-grid" data-uk-grid="">
                  <span className="uk-text-bold uk-first-column">Categoría y criterios Lista Roja UICN:</span>
                  <span>{category}{' '}{criteria}</span>
                </div>
              </li>
              <li>
                <div className="uk-child-width-1-2 uk-grid" data-uk-grid="">
                  <span className="uk-text-bold uk-first-column">Idioma de la evaluación:</span>
                  <span>{language}</span>
                </div>
              </li>
              <li>
                <div className="uk-child-width-1-2 uk-grid" data-uk-grid="">
                  <span className="uk-text-bold uk-first-column">Fecha de la evaluación:</span>
                  <span>{dateFormatted}</span>
                </div>
              </li>
              <li>
                <div className="uk-child-width-1-2 uk-grid" data-uk-grid="">
                  <span className="uk-text-bold uk-first-column">Regiones:</span>
                  <span>{scope}</span>
                </div>
              </li>
            </div>
          </div>

          <div className="uk-flex uk-flex-column">
            <div className="uk-child-width-1-1">
              <li>
                <div className="uk-child-width-1-2 uk-grid" data-uk-grid="">
                  <span className="uk-text-bold uk-first-column">Justificación:</span>
                </div>
              </li>
              <Paragraph>{justification}</Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Evaluacion.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.get('iucnReducer').get('data'),
});


export default connect(
  mapStateToProps,
  null
)(withRouter(Evaluacion));
