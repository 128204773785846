/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Dispatchers */
import { userFormChange, userFieldError, createUserRequest } from '../dispatchers';

/* Components */
import GenericPage from '../components/templates/GenericPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import Main from '../components/organisms/desktop/Main';
import Albums from '../components/organisms/desktop/Albums';
import Activity from '../components/organisms/desktop/Activity';
import UserCard from '../components/molecules/UserCard';
// Services
import * as UserService from '../services/UserService';

class User extends Component {
  constructor() {
    super();
    this.state = {
      select: 0,
    };
  }

  componentWillMount() {
    UserService.getUsername(this.props.match.params.username).then(data => {
      // console.log("Consultando UserService")
      // console.log(data)
      this.setState({ data });
      /*
        Buscar la fichas del album
      CatalogService.getRelatedRecords(data.hierarchy[0].family).then(related => {
        this.setState({ related })
      })
      */
    });




    this.activeTab(this.props.match.params.tab);
  }

  activeTab(tab, changeUrl = true) {
    let tabIndex, title, url;
    switch (tab) {
      case 'desktop':
        tabIndex = 0;
        title = 'Perfil';
        url = '/user/' + this.props.match.params.username + '/profile';
        break;
      case 'albums':
        tabIndex = 1;
        title = 'Albums';
        url = '/user/' + this.props.match.params.username + '/albums';
        break;
      case 'activity':
        tabIndex = 2;
        title = 'Actividad';
        url = '/user/' + this.props.match.params.username + '/activity';
        break;
      case 'comments':
        tabIndex = 3;
        title = 'Comentarios';
        url = '/user/' + this.props.match.params.username + '/comments';
        break;

      default:
        tabIndex = 0;
        title = 'Perfil';
        url = '/user/' + this.props.match.params.username + '/profile';
        break;
    }

    changeUrl && window.history.replaceState({}, title + ' - Catálogo de la biodiversidad', url + this.props.location.search);
    this.setState({ select: tabIndex });
  }

  render() {
    const { data } = this.state;
    return (
      <GenericPage
        titlep="Catálogo de la biodiversidad"
        header={<Header />}
        footer={<Footer />}
        background="https://s3.amazonaws.com/sib-resources/images/catalogo/destacadas/png/f-mamifero.png"
      >
        {data &&
          <div className="uk-background-linear uk-section">
            <div className="uk-container uk-width-5-6@m uk-width-2-3@l" style={{ marginTop: '40vh' }}>
              <div className="uk-card uk-card-default">
                <div className="uk-card-body">
                  <i>
                    <span className="uk-text-tertiary uk-margin-remove uk-h1">{data.username}</span>
                  </i>
                  <span className="uk-h5 uk-margin-left" style={{ fontWeight: 200 }}>{data.name} {data.lastname}</span>
                </div>
                <div className="uk-card-footer">
                  <ul data-uk-switcher={`connect: .uk-switcher; active: ${this.state.select}`} data-uk-grid="">
                    <button onClick={() => this.activeTab('desktop')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 0 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Perfil</button>
                    <button onClick={() => this.activeTab('albums')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 1 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Álbumes</button>
                    <button onClick={() => this.activeTab('activity')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 2 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Actividad</button>
                    <button onClick={() => this.activeTab('comments')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 3 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Comentarios</button>
                  </ul>
                </div>
              </div>
            </div>
            <div id="lol" className="uk-container uk-width-5-6@m uk-width-2-3@l uk-margin-top">
              <div className="uk-grid-small" data-uk-grid="">
                <div className="uk-width-1-3">
                  <UserCard data={data} />
                </div>
                <div className="uk-width-expand">
                  <ul className="uk-switcher">
                    <Main data={data} />
                    <Albums data={data} />
                    <Activity data={data} />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
      </GenericPage>
    );
  }
}

const mapStateToProps = state => {
  return {
    userForm: state.get('userReducer').get('userForm').toJS(),
    userError: state.get('userReducer').get('userError'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createUserSend: (userForm) => dispatch(createUserRequest(userForm)),
    userFieldChange: (name, value) => dispatch(userFormChange(name, value)),
    userFormFieldError: (fieldError) => dispatch(userFieldError(fieldError)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(User));
