/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { map, differenceWith, isEqual, findKey, isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';
import Filters from '../Filters';
/* Dispatchers */
import { filtersFormChange } from '../../../dispatchers';

class GruposTematicos extends Component {
  constructor(props) {
    super(props);

    this.query = [];
    this.values = {
      'Amenaza global': [
        { label: 'No evaluada (NE)', id: 'threatUICN=NE' },
        { label: 'Datos insuficientes (DD)', id: 'threatUICN=DD' },
        { label: 'Preocupación menor (LC)', id: 'threatUICN=LC' },
        { label: 'Casi amenazada (NT)', id: 'threatUICN=NT' },
        { label: 'Vulnerable (VU)', id: 'threatUICN=VU' },
        { label: 'En peligro (EN)', id: 'threatUICN=EN' },
        { label: 'En peligro crítico (CR)', id: 'threatUICN=CR' },
        { label: 'Extinto en vida silvestre (EW)', id: 'threatUICN=EW' },
        { label: 'Extinto (EX)', id: 'threatUICN=EX' },
      ],
      'Amenaza nacional': [
        { label: 'Vulnerable (VU)', id: 'threatMADS=VU' },
        { label: 'En peligro (EN)', id: 'threatMADS=EN' },
        { label: 'En peligro crítico (CR)', id: 'threatMADS=CR' },
      ],
      'Usos': [
        { label: 'Productos forestales no maderables', id: 'usos=PFNM' },
        { label: 'Otros', id: 'usos=Otros' },
      ],
      'Evaluación de riesgo y extinción': [
        { label: 'Evaluación del riesgo de extinción', id: 'assessment=Evaluación del riesgo de extinción' },
      ],
      'CITES': [
        { label: 'Apéndice I', id: 'threatCITES=Apéndice I' },
        { label: 'Apéndice II', id: 'threatCITES=Apéndice II' },
        { label: 'Apéndice III', id: 'threatCITES=Apéndice III' },
      ],
      'Exótica': [
        { label: 'Exótica', id: 'exotic=true' },
        { label: 'Exótica con alto riesgo de Invasión', id: 'invasive=true' },
      ],
      // 'Endémicas': [
      //   { label: 'Aves', id: 'class=Aves&endemic=Colombia' },
      //   { label: 'Peces de agua dulce', id: 'class=Actinopterygii&class=Actinopteri&class=Sarcopterygii&class=Elasmobranchii&class=Chondrichthyes&endemic=Colombia' },
      //   { label: 'Mamíferos', id: 'class=Mammalia&endemic=Colombia' },
      // ],
      'Ecosistema': [
        { label: 'Páramos', id: 'habitat=Páramos' },
        { label: 'Humedales', id: 'habitat=Humedales_Ciénagas' },
      ],
    };

    this.state = {
      data: null,
      search: props.search,
      modified: false,
      values: this.values,
    };
  }

  // activeFilters(data) {
  //   map(data, (item) => {
  //     const i = findKey(this.state.values, (o) => { return o.id === decodeURIComponent(item); });
  //     if (i >= 0) {
  //       this.handleFilter(this.state.values[i].label);
  //     }
  //   });
  // }
  componentWillMount() {
    // this.props.onRef(this)
  }

  componentDidMount() {
  }

  handleFilter(value) {
    console.log("handleFilter"+JSON.stringify(value))
    const { onFiltersFormChange } = this.props;
    this.query.push(value);
    this.props.count(this.query);
    this.setState({ data: this.query });

  }

  deleteFilter(value) {
    console.log("handleFilter")
    const { onFiltersFormChange } = this.props;
    const position = findKey(this.query, value);
    this.query.splice(position, 1);
    this.props.count(this.query);
    this.setState({ data: this.query });
    onFiltersFormChange(value);
  }

  cleanFilters() {
    this.query = [];
    this.child.handleDrop(false);
    this.props.count(this.query);
    this.setState({ data: this.query });
  }

  render() {
    return (
      <Filters.Base onRef={ref => { this.child = ref; }} title="Grupo temático" handlerFilter={this.state.data} func={(value) => this.deleteFilter(value)} empty={isEmpty(this.state.values)}>
        <div className="uk-child-width-1-1 uk-grid-collapse" data-uk-grid="">
          {map(this.state.values, (v, k) => {
            return (
              <div key={k} className="uk-grid-collapse uk-flex-between uk-flex-middle uk-text-small">
                {k!=='' &&
                <h4 className="uk-margin-small-top uk-margin-small-bottom">{k}</h4>
                }
                {map((differenceWith(v, this.query, isEqual)), (value, key) => {
                  return (
                    <label
                      key={key}
                      style={{
                        background: 'url(/images/fondo.png) no-repeat',
                        backgroundSize: value.fraction + '% 100%',
                        cursor: 'pointer',
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        paddingLeft: 2.5,
                        display: 'block',
                      }}
                      onClick={(e) => { e.preventDefault(); this.handleFilter(value); }}
                      title={value.label}
                    >
                      <input className="uk-checkbox uk-margin-small-right" type="checkbox" />{value.label}
                      <span className="uk-float-right">
                        <NumberFormat value={value.value} displayType="text" thousandSeparator />
                      </span>
                    </label>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Filters.Base>
    );
  }
};

const mapDispatchToProps = dispatch => ({
  onFiltersFormChange: (value) => dispatch(filtersFormChange(value)),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(GruposTematicos));
