/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import ReactSlider from 'react-slick';
import { Link } from 'react-router-dom';
import { map, get } from 'lodash';
import { URL_STATICS } from '../../config/const';

// import Card from '../molecules/Card';
import Amenaza from '../atoms/Amenaza';

import * as CatalogService from '../../services/CatalogService';

class Slider extends Component {


  imagen(record) {
    if (get(record, ['imageInfo', 'thumbnailImage']))
      return get(record, ['imageInfo', 'thumbnailImage']);
    else if (get(record, ['imageInfo', 'mainImage']))
      return get(record, ['imageInfo', 'mainImage']);
    return URL_STATICS+'sib-resources/images/catalogo/miniatura/png/t-anfibios.png';
  }
  nombre(d){
    let lista = map(d.commonNames, (v, k)=>{
      if (v.language==='Español'){
        return v.name;
      }
    });
    lista = map(d.commonNames, (v, k)=>{
      return v.name;
    });
    return lista[0];
  }

  parte1(s){
    if (s===undefined || s===null)
      return '';
    const x = s.split(' ');
    let r = x[0];
    if (x[1]!==undefined){
      r = x[0]+' '+x[1];
    }
    return r;
  }
  parte2(s){
    if (s===undefined || s===null)
      return '';
    const x = s.split(' ');
    if (x[2]!==undefined){
      return x.slice(2, x.length-1).join(' ');
    }
    return '';
  }

  openLinks(event){
    event.preventDefault();
    if (event.currentTarget.target === '_blank') {
        window.open(event.currentTarget.href, event.currentTarget.target);
    } else {
        window.location = event.currentTarget.href;
    }
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      swipe : true,
      draggable : false,
    };

    const { data } = this.props;
    let link='/search/basic';
    if (this.props.link){
      link = this.props.link;
    }

    return (
      <div>
        <ReactSlider ref={c => this.slider = c} {...settings}>
          {
            map(data, (value, key) => (
              <div key={key}>
                <div className="uk-position-small uk-card uk-card-default uk-flex uk-flex-column uk-flex-center">
                  {typeof value.imageInfo !== 'undefined' && <Link target={`/file/${value._id}/summary`} onClick={(e) => this.openLinks(e)} to={`/file/${value._id}/summary`} className="uk-text-center"><img className="uk-margin-auto" src={CatalogService.getThumb(this.imagen(value))} alt="" /></Link>}
                  <div className="uk-padding-small uk-inline">
                    <Link className="uk-link-reset" target={`/file/${value._id}/summary`} onClick={(e) => this.openLinks(e)} to={`/file/${value._id}/summary`}><i><h4 className="uk-text-tertiary uk-margin-remove uk-text-truncate" style={{ fontWeight: 200 }}>{this.parte1(value.scientificNameSimple)}</h4></i></Link>
                    <Link className="uk-link-reset" target={`/file/${value._id}/summary`} onClick={(e) => this.openLinks(e)} to={`/file/${value._id}/summary`}><span className="uk-text-small uk-text-small">{this.parte2(value.scientificNameSimple)}</span></Link>
                    <Amenaza data={value} small={true} />
                  </div>
                  <Link className="uk-link-reset" target={`/file/${value._id}/summary`} onClick={(e) => this.openLinks(e)} to={`/file/${value._id}/summary`}><div className="uk-padding-small uk-padding-remove-top uk-padding-remove-bottom uk-background-tertiary uk-text-default uk-text-small" >{this.nombre(value)}</div></Link>

                </div>
              </div>

            ))
          }

        </ReactSlider>
        <div className="uk-flex uk-flex-center uk-flex-middle">
          <button className="uk-margin-right uk-link" href="#" uk-slidenav-previous="" onClick={() => this.slider.slickPrev()} />
          <Link to={link} className="uk-button uk-button-primary">VER MÁS</Link>
          <button className="uk-margin-left uk-link" href="#" uk-slidenav-next="" onClick={() => this.slider.slickNext()} />
        </div>
      </div>
    );
  }
}

export default Slider;
