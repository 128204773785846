/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import StackGrid from 'react-stack-grid';

import { map } from 'lodash';
import DataSheet from './DataSheet';

class Galeria extends Component {

  getType(url){
    if(url){
      const f = url.toLowerCase().split('.');
      //console.log("tipo estoy en imágenes: ", url);
      //console.log(f);
      if (
        f[f.length-1]==='jpg' ||
        f[f.length-1]==='jpeg' ||
        f[f.length-1]==='png' ||
        f[f.length-1]==='bmp' ||
        f[f.length-1]==='gif'
      ){
        // console.log("Es una imágen")
        return 'image';
      }
      if (
        f[f.length-1]==='mp3' ||
        f[f.length-1]==='m4a' ||
        f[f.length-1]==='aac' ||
        f[f.length-1]==='ogg' ||
        f[f.length-1]==='wav'
      ){
        // console.log("Es un sonido")
        return 'sound';
      }
      if (
        f[f.length-1]==='ogg' ||
        f[f.length-1]==='mp4'
      ){
        // console.log("Es un video")
        return 'video';
      }
    }
    return null;
  }


  render() {
    // console.log("Desde Galeria")
    // console.log(this.props.data)
    const d = this.props.data;

    const x =
      (<div>
        { d.ancillaryDataApprovedInUse && d.ancillaryDataApprovedInUse.ancillaryData && (
          <DataSheet title="Imágenes" scroll="images">
            <div className="uk-padding-small" uk-lightbox="animation: slide">
              <StackGrid columnWidth={100} monitorImagesLoaded gutterWidth={10} gutterHeight={10} >
                { d.ancillaryDataApprovedInUse.ancillaryData && (map(d.ancillaryDataApprovedInUse.ancillaryData, (v, k)=> {
                  //console.log("Analizando")
                  //console.log(v)
                  const r = [];
                  if (v.mediaURL[0] && !v.mediaURL[0].startsWith("https://commons.wikimedia.org/wiki/File:") && this.getType(v.mediaURL[0])==='image'){
                    r.push(
                      <a className="uk-inline" href={v.mediaURL[0]} caption={v.rightsHolder+' - '+v.license+' - '+v.source}>
                        <img src={v.mediaURL[0]} alt="" />
                      </a>);
                  }else if (v.source && this.getType(v.source)==='image'){
                    r.push(
                      <a className="uk-inline" href={v.source} caption={v.rightsHolder+' - '+v.license+' - '+v.source}>
                        <img src={v.source} alt="" />
                      </a>);
                  }
                  if (r.length>0)
                    return r;
                }))}
              </StackGrid>
            </div>
          </DataSheet>
        )
        }</div>);
    return x;
  }
}

export default Galeria;
