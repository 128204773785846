import fetch from 'isomorphic-fetch';

import { URL_API_PARTICIPATION } from '../config/const';

export function getPage(id) {
  return fetch(`${URL_API_PARTICIPATION}/api/static/get/catalogo_${id}`, { method: 'GET' }).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}
