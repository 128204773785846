/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { map } from 'lodash';

import * as CatalogService from '../../../services/CatalogService';

class ToReview extends Component {

  constructor() {
    super();
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    const { id, label } = this.props;
    CatalogService.getField(`to_review/${id}`, label, '').then(data => {
      this.setState({ data });
    });
  }

  updateVersion(e, v) {
    e.preventDefault();
    const { id, label } = this.props;
    CatalogService.updateVersion(id, label, v, { id_user: 'mmorales@idesoluciones.com' }).then(data => {
      window.location.reload();
    });
  }

  render() {
    const { data } = this.state;
    return (
      <form>
        {
          map(data, (v, k) => (
            <div key={k}>
              <span>v<span className="uk-text-lead">{v.version}</span> - {v.id_user} - {v.created}</span>
              <div className="uk-section uk-section-small uk-section-muted uk-margin-bottom uk-inline uk-visible-toggle">
                <div className="uk-container">
                  {this.props.versions}
                </div>
                <div className="uk-position-bottom-right">
                  <button className="uk-button uk-button-primary uk-hidden-hover uk-animation-fade uk-animation-fast" onClick={(e) => { this.updateVersion(e, v.version); }}>Publicar</button>
                </div>
              </div>
            </div>
          ))
        }
      </form>
    );
  }
}

export default ToReview;
