import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

/* Style Components */
import { Paragraph, TableStyled } from '../../pages/styled';

const Aportes = ({ data }) => {
  const { assessment } = data;
  const { category, criteria, date, language, assesor, reviewer, contributor, facilitator, scope, justification } = assessment;
  const dateFormatted = dayjs(date).format('dddd[,] D [de] MMMM [de] YYYY');
  //console.log("contributor: ", contributor)
  const arrContributor = contributor.split(',');
  //console.log("assesment", assessment)
  return (
    <div>
      <div className="uk-margin">
        <div id={'partes_asociadas'} className={'uk-card uk-card-default uk-panel uk-padding uk-text-break'}>
          <div className="uk-flex uk-flex-column">
            <span className={'uk-h3 uk-text-tertiary uk-text-bold'}>{'Partes asociadas'}</span>
          </div>

          <div className="uk-padding-small type_hierarchy">
            <div className="uk-child-width-1-1">
              {assesor && <li>
                <div className="uk-grid" data-uk-grid="">
                  <span className="uk-width-1-4 uk-text-bold uk-first-column">Asesores:</span>
                  <span className="uk-width-3-4">{assesor}</span>
                </div>
              </li>}
              {reviewer && <li>
                <div className="uk-grid" data-uk-grid="">
                  <span className="uk-width-1-4 uk-text-bold uk-first-column">Revisores:</span>
                  <span className="uk-width-3-4">{reviewer}</span>
                </div>
              </li>}
              {facilitator && <li>
                <div className="uk-grid" data-uk-grid="">
                  <span className="uk-width-1-4 uk-text-bold uk-first-column">Facilitadores:</span>
                  <span className="uk-width-3-4">{facilitator}</span>
                </div>
              </li>}
              {contributor && <li>
                <div className="uk-grid" data-uk-grid="">
                  <span className="uk-width-1-4 uk-text-bold uk-first-column">Compiladores:</span>
                  <span className="uk-width-3-4">{contributor}</span>
                </div>
              </li>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Aportes.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.get('iucnReducer').get('data'),
});


export default connect(
  mapStateToProps,
  null
)(withRouter(Aportes));
