/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
// Components
import GenericPage from '../components/templates/GenericPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import Loading from '../components/atoms/Loading';
import DataSheet from '../components/molecules/DataSheet';
// Services
import * as StaticService from '../services/StaticService';

class Static extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      notFound: false,
    };
  }

  componentDidMount() {
    // console.log("Llamando a componentDidMount")
    this.cargarPagina(this.props.match.params.id);
  }
  componentWillReceiveProps(nextProps){
    // console.log("componentWillReceiveProps ", nextProps)
    this.cargarPagina(nextProps.match.params.id);
  }

  cargarPagina(id){
    // console.log("Llamando a componentDidMount")
    if (id !== undefined) {
      StaticService.getPage(id).then(data => {
        // console.log("StaticService.getPage -> pagina cargada ", data)
        window.scrollTo(0, 0);
        if (data===null){
          this.setState({ notFound: true });
        }else{
          this.setState({ data });
        }
      }).catch(err => {
        this.setState({ notFound: true });
        console.log(err);
      });
    }
  }

  render() {
    const titulo = this.state.data ? this.state.data.titulo : '';
    if (this.state.notFound) return (<Redirect to="/" />);
    return (<GenericPage titlep={'titulo Catálogo de la biodiversidad'} header={<Header />} footer={<Footer />}>
      {
        (this.state.data &&
          <div className="uk-section uk-section-xsmall">
            <div className="uk-flex uk-flex-center">
              <div className="uk-container uk-width-5-6@m uk-width-2-3@l">
                <div className="uk-flex uk-flex-column">
                  <h2 className="uk-margin-left uk-text-bold">{this.state.data.titulo}</h2>
                  <div className="uk-width-1-4 uk-width-small@s uk-background-primary" style={{ height: 2 }}></div>
                </div>
                <div className="content" dangerouslySetInnerHTML={{ __html: this.state.data.html }}></div>
                <div className="uk-grid-small uk-margin-top" data-uk-grid="data-uk-grid">
                  <div className="uk-width-1-4">
                    <div className="uk-card uk-card-default uk-padding-small" data-uk-sticky="offset: 90; bottom: true" style={{ zIndex: 979 }}>
                      <ul className="uk-list uk-list-large uk-list-bullet uk-margin-remove-bottom" uk-scrollspy-nav="closest: li; scroll: true; cls: uk-text-primary">
                        {_.map(this.state.data.datos, (punto, key) => (
                          <li key={key}>
                            <a className="uk-link-reset" href={'#'+punto.ancla} data-uk-scroll="offset: 90">{punto.titulo}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="uk-width-3-4">
                    {_.map(this.state.data.datos, (punto, key) => (
                      <DataSheet key={key} scroll={punto.ancla} title={punto.titulo} className="uk-margin-bottom">
                        <div className="uk-padding-small">
                          <div className="content" dangerouslySetInnerHTML={{ __html: punto.contenido }}></div>
                        </div>
                      </DataSheet>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) || <div className="uk-flex uk-flex-center uk-flex-middle uk-padding" data-uk-height-viewport="offset-top: true"><Loading /></div>
      }
    </GenericPage>);
  }
}

export default Static;
