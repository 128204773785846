/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import map from 'lodash/map';

class Title extends Component {

  renderTag(childs, tg) {
    switch (tg) {
      case 'h2':
        return <h2 className="uk-margin-left">{childs}</h2>;
      case 'h3':
        return <h3 className="uk-margin-left uk-text-bold">{childs}</h3>;

      case 'h4':
        childs = map(childs, (v, k) => React.cloneElement(v, { key: k }));
        return <h4 className="uk-margin-left">{childs}</h4>;

      default: break;
    }
  }

  render() {
    const { label, tag, icon, number, labelNumber } = this.props;
    return (
      <div className="uk-flex uk-flex-column">
        {this.renderTag([
          label,
          icon,
          number && <span className="uk-text-bold">{number} </span>,
          labelNumber && <span className="uk-text-small">{labelNumber}</span>,
        ], tag)}
        <div className="uk-width-1-4 uk-width-small@s uk-background-primary" style={{ height: 2 }}></div>
      </div>
    );
  }
}

export default Title;
