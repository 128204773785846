import * as ResetActions from '../typesActions';

export const fetchingResetRequest = () => {
  return {
    type: ResetActions.FETCHING_RESET_REQUEST,
  };
};

export const fetchingResetSuccess = data => {
  return {
    type: ResetActions.FETCHING_RESET_SUCCESS,
    payload: { data },
  };
};

export const fetchingResetFailure = data => {
  return {
    type: ResetActions.FETCHING_RESET_FAILURE,
    payload: { data },
  };
};

export const setResetFormChange = (name, value) => {
  return {
    type: ResetActions.SET_RESET_FORM_CHANGE,
    payload: { name, value },
  };
};

export const setResetFieldError = error => {
  return {
    type: ResetActions.SET_RESET_FIELD_ERROR,
    payload: { error },
  };
};
