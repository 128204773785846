/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';

class OauthPage extends Component {
  render() {
    return (
      <div className={cx('uk-scope', this.props.className)}>
        <Helmet>
          <title>{this.props.titlep}</title>
        </Helmet>
        {this.props.header}
        <div className="uk-background-image uk-background-cover uk-flex" style={{ backgroundImage: 'url(https://s3.amazonaws.com/sib-resources/images/catalogo/destacadas/png/f-mamifero.png)' }} data-uk-height-viewport="offset-top: true">
          {this.props.children}
        </div>
        {this.props.footer}
      </div>
    );
  }
}

export default OauthPage;
