/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { map } from 'lodash';


class References extends Component {

  reference_editors(x){
    const as = x.editors;
    if (as===undefined || as.length===0){
      return null;
    }
    if (as.length===1){
      return as[0] + ' (ed)';
    }
    if (as.length===2){
      return (as[0] + ' & ' + as[1] + ' (eds)');
    }
    if (as.length===3){
      return (as[0] + ', ' + as[1] + ' & ' + as[2] + ' (eds)');
    }
    return as[0] + ' (eds) et al.';
  }

  reference_authors(x){
    const as = x.authors;
    if (as===undefined || as.length===0){
      return null;
    }
    if (as.length===1){
      return as[0];
    }
    if (as.length===2){
      return (as[0] + ' & ' + as[1]);
    }
    if (as.length===3){
      return (as[0] + ', ' + as[1] + ' & '+as[2]);
    }
    if (as.length===4){
      return (as[0] + ', ' + as[1] + ', ' + as[2] + ' & '+as[3]);
    }
    return as[0] + ' et al.';
  }

  reference_book(x, k){
    const autores = this.reference_authors(x);
    return (<li key={k}>
      {autores!==null && autores}
      {autores===null && this.reference_editors(x)}&nbsp;
      {x.year.substring(0, 4) + '.'}&nbsp;
      <i>{x.title && x.title + '.'}</i>&nbsp;
      {x.serie}&nbsp;
      {x.publisher}&nbsp;
      {x.accessed && '[Consultado '+x.accessed+'].'}
      {x.isbn && 'ISBN: ' + x.isbn + '.'}
    </li>);
  }

  reference_book_section(x, k){
    return (<li key={k}>
      {this.reference_authors(x)}&nbsp;
      {x.year.substring(0, 4) + '.'}&nbsp;
      {x.title && x.title + '.'}&nbsp;
      {this.reference_editors(x)!==null &&
        <span>En {this.reference_editors(x)}</span>
      }&nbsp;
      <i>{x.source}</i>&nbsp;
      {x.serie}&nbsp;
      {x.chapter}&nbsp;
      {x.publisher}&nbsp;
      {x.accessed && '[Consultado '+x.accessed+'].'}
      {x.isbn && 'ISBN: ' + x.isbn + '.'}
    </li>);
  }

  reference_article(x, k){
    return (<li key={k}>
      {this.reference_authors(x)}&nbsp;
      {x.year && x.year.substring(0, 4) + '.'}&nbsp;
      {x.title && x.title + '.'}&nbsp;
      <i>{x.source}</i>&nbsp;
      {x.volume}&nbsp;
      {x.pages && 'p. '+x.pages}&nbsp;
      {x.link}&nbsp;
      {x.doi}&nbsp;
      {x.accessed && '[Consultado ' + x.accessed + '].'}
      {x.issn && 'ISSN: ' +x.issn + '.'}
    </li>);
  }

  reference_thesis(x, k){
    return (<li key={k}>
      {this.reference_authors(x)}&nbsp;
      {x.year.substring(0, 4) + '.'}&nbsp;
      <i>{x.title && x.title + '.'}</i>&nbsp;
      {x.institution && x.institution + '.'}&nbsp;
      {x.pages && 'p. ' + x.pages}&nbsp;
      {x.link && 'Disponible en: ' + x.link}&nbsp;
      {x.accessed && '[Consultado ' + x.accessed + '].'}
    </li>);
  }

  reference_report(x, k){
    return (<li key={k}>
      {this.reference_authors(x)}&nbsp;
      {x.year.substring(0, 4) + '.'}&nbsp;
      <i>{x.title && x.title + '.'}</i>&nbsp;
      {x.institution && x.institution + '.'}&nbsp;
      {x.pages && 'p. ' + x.pages}&nbsp;
      {x.link && 'Disponible en: ' + x.link}&nbsp;
      {x.accessed && '[Consultado ' + x.accessed + '].'}
    </li>);
  }

  reference_working_paper(x, k){
    return (<li key={k}>
      {this.reference_authors(x)}&nbsp;
      {x.year.substring(0, 4) + '.'}&nbsp;
      <i>{x.title && x.title + '.'}</i>&nbsp;
      {x.institution && x.institution + '.'}&nbsp;
      {x.pages && 'p. ' + x.pages}&nbsp;
      {x.link && 'Disponible en: ' + x.link}&nbsp;
      {x.accessed && '[Consultado "+x.accessed+"].'}
    </li>);
  }

  reference_miscellany(x, k){
    return (<li key={k}>
      {this.reference_authors(x)}&nbsp;
      {x.year && x.year.substring(0, 4) + '.'}&nbsp;
      <i>{x.title && x.title + '.'}</i>&nbsp;
      {x.institution && x.institution + '.'}&nbsp;
      {x.city && x.city + '.'}&nbsp;
      {x.link && 'Disponible en: ' + x.link}&nbsp;
      {x.accessed && '[Consultado ' + x.accessed + '].'}
    </li>);
  }


  render() {
    const x = this.props.references;

    let lista = map(x, (v, k)=>{
      if (v && v.type && this['reference_' + v.type] !== undefined){
        //console.log("Reference: ", v.type, v, k)
        return this['reference_' + v.type](v, k);
      }else{
        // console.log("No existe 1 ", v)
        // if (v)
        //  console.log("No existe 2 ", v.type)
      }
    });
    //console.log("La lista de referencias es: ", lista)
    return lista
  }
}

export default References;
