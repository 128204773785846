/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import map from 'lodash/map';
import StackGrid from 'react-stack-grid';

import Card from '../molecules/Card';

class SearchGrid extends Component {

  // When the size of the item is changed...
  componentDidMount(){
    this.count = 0;
    this.interval = setInterval(() => {
      // console.log("Actualizando")
      if(this.grid!==undefined){
        // console.log("Grid está definido, actualizando ... ["+this.count+"]")
        this.grid.updateLayout();
        this.count=this.count+1;
        if (this.count>10){
          clearInterval(this.interval);
        }
      }
    }, 1500);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    // <div style={{"display":"block", "overflow":"auto"}}>
    return (
      <div className="uk-clearfix uk-overflow-hidden" uk-height-viewport="expand: true">
        <StackGrid columnWidth={210} monitorImagesLoaded gutterWidth={15} gutterHeight={15}
          gridRef={grid => this.grid = grid}
          className="uk-clearfix uk-overflow-hidden"
        >
          {
            map(this.props.data, (value, key) => (
              <Card key={key} data={value} />
            ))
          }
        </StackGrid>
      </div>
    );
  }
}

export default SearchGrid;
