import INITIAL_STATE from '../store/initialState';

const rolReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

export default rolReducer;
