/* eslint-disable no-unused-vars */
import fetch from 'isomorphic-fetch';

import { URL_API } from '../config/const';
import { getToken } from '../auth';


function http(method, data) {
  const headers = {
    'Content-Type': 'application/json',
  };

  const token = getToken();
  if (token) headers['Authorization'] = token;

  const config = {
    method,
    headers,
    body: JSON.stringify(data),
  };

  return config;
}

export function saveNewItem(id, body, type) {
  body._id = '';
  body.created = new Date();
  body.version = -1;
  body.state = 'accepted';
  body.id_record = id;
  body.id_user = JSON.parse(localStorage.getItem('catalogo-user'))._id;
  body.element = type;

  return fetch(`${URL_API}/record/${id}/${type}`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(body),
  }).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}
