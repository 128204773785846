/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// import map from 'lodash/map'

import * as UtilService from '../../services/UtilService';

class Amenaza extends Component {

  getAmenaza(data){
    let x = UtilService.route(data, ['threatStatusValue']);
    x = x.replace(')', '');
    const ps = x.split(' (');

    return ps;
  }

  render() {
    const { data, small } = this.props;
    const a = this.getAmenaza(data);

    if (small) {
      return (
        <div className="uk-overlay uk-padding-remove uk-position-top-right uk-background-primary uk-text-default uk-text-bold uk-text-small">
          {a[1]}
        </div>
      );
    }

    return (
      <div>
        {a[1] &&
        <span className="uk-background-primary uk-text-default uk-text-bold uk-text-small" style={{ padding: 3, marginRight: 1 }}>
          {a[1]}
        </span>
        }
        {a[0] &&
        <span className="uk-background-primary uk-text-default uk-text-bold uk-text-small" style={{ padding: 3 }}>
          {a[0]}
        </span>
        }
      </div>
    );
  }
}

export default Amenaza;
