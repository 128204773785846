/* eslint-disable react/prop-types */
import React, { Component } from 'react';

import Navbar from './Navbar';
import Subnav from '../molecules/Subnav';

class Header extends Component {

  render() {
    return (
      <div id="navbar" className="uk-box-shadow-small uk-background-default" data-uk-sticky="sel-target: !nav; cls-active: uk-navbar-sticky">
        <Navbar
          sidebar={this.props.withSidebar}
          menu={() => {
            this.props.menu();
          }}
        />
        <Subnav />
      </div>
    );
  }
}

export default Header;
