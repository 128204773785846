/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { map } from 'lodash';

import IgnoreCard from './IgnoreCard';
import PlinianClass from './PlinianClass';

class TablesWithoutStructure extends Component {
  render() {
    return (
      <div>Hello from TablesWithoutStructure!</div>
    );
  }
}

function util(k, x, campo, columns) {
  // console.log("----------------------- Texto sin estructurar + TABLAS")
  // console.log(k)
  // console.log(x)
  // console.log(campo)
  // console.log(columns)

  const t = k.substring(0, k.length - 13);
  let u;
  if (x[t + 'Unstructured'] !== undefined) {
    u = (<p>{x[t + 'Unstructured']}</p>);
  }
  // console.log("Se va a enviar a la tabla")
  // console.log(t)
  const es = map(x, (dato, key) => {
    // console.log("Tablas sin estructura: tabla: ", dato[t + "Atomized"])
    const appliesTo = PlinianClass.appliesTo(k, dato[t + 'Atomized']['appliesTo']);
    const apendiceCITES = PlinianClass.apendiceCITES(k, dato[t + 'Atomized']['apendiceCITES']);
    const e = <div className="uk-margin" key={key}>{appliesTo}{apendiceCITES}{IgnoreCard.util(k, dato[t + 'Atomized'][campo], [], false)}</div>;
    return e;
  });

  return (<div>{u}{es}</div>);
}

TablesWithoutStructure.util = util;

export default TablesWithoutStructure;
