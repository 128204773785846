/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';

class GenericPage extends Component {
  render() {
    return (
      <div className={cx('uk-scope', this.props.className)}>
        <Helmet>
          <title>{this.props.titlep}</title>
        </Helmet>
        {this.props.header}
        <div
          className={this.props.background ? 'uk-backtop uk-background-image uk-background-cover uk-background-fixed' : 'uk-background-muted'}
          data-uk-height-viewport="offset-top:true;"
          style={{ backgroundImage: `url(${this.props.background})` }}
        >
          {this.props.children}
        </div>
        {this.props.footer}
      </div>
    );
  }
}

export default GenericPage;
