import React, { Component } from 'react';
import { map, findIndex, forEachRight } from 'lodash';
import nanoid from 'nanoid';

class AdvancedSearch extends Component {

  filtersIni = {
    'Taxonomía': [
      {
        name: 'Reino',
        groups: [
          { name: 'Animales', q: 'kingdom=Animalia', checked: false },
          { name: 'Plantas', q: 'kingdom=Plantae', checked: false },
          { name: 'Hongos', q: 'kingdom=Fungi', checked: false },
          { name: 'Bacterias', q: 'kingdom=Bacteria', checked: false },
          { name: 'Protozoos', q: 'kingdom=Protozoa', checked: false },
          { name: 'Arquea', q: 'kingdom=Archaea', checked: false },
          { name: 'Virus', q: 'kingdom=Viruses', checked: false },
        ],
      },
      {
        name: 'Grupo de animales',
        groups: [
          { name: 'Mamíferos', q: 'class=Mammalia', checked: false },
          { name: 'Aves', q: 'class=Aves', checked: false },
          { name: 'Reptiles', q: 'class=Reptilia', checked: false },
          { name: 'Anfibios', q: 'class=Amphibia', checked: false },
          { name: 'Peces', q: 'class=Actinopterygii&class=Actinopteri&class=Sarcopterygii&class=Elasmobranchii&class=Chondrichthyes', checked: false },
          { name: 'Insectos', q: 'class=Insecta', checked: false },
          { name: 'Escarabajos', q: 'order=Coleoptera', checked: false },
          { name: 'Mariposas', q: 'order=Lepidoptera', checked: false },
          { name: 'Hormigas', q: 'family=Formicidae', checked: false },
          { name: 'Abejas', q: 'family=Apidae&family=Colletidae&family=Halictidae', checked: false },
          { name: 'Moscas y zancudos', q: 'order=Diptera', checked: false },
          { name: 'Arácnidos', q: 'class=Arachnida', checked: false },
          { name: 'Moluscos', q: 'phylum=Mollusca', checked: false },
          { name: 'Decápodos', q: 'order=Decapoda', checked: false },
          { name: 'Equinodermos', q: 'phylum=Echinodermata', checked: false },
          { name: 'Esponjas', q: 'phylum=Porifera', checked: false },
          { name: 'Corales y afines', q: 'class=Anthozoa&class=Hydrozoa', checked: false },
        ],
      },
      {
        name: 'Grupo de plantas',
        groups: [
          { name: 'Orquídeas', q: 'family=Orchidaceae', checked: false },
          { name: 'Magnolias y afines', q: 'family=Magnoliaceae&family=Podocarpaceae&family=Myristicaceae', checked: false },
          { name: '', q: '', checked: false },
          { name: 'Palmas', q: 'family=Arecaceae', checked: false },
          { name: 'Frailejones', q: 'genus=Carramboa,Coespeletia,Espeletia,Espeletiopsis,Libanothamnus,Paramiflos,Ruilopezia,Tamania', checked: false },
          { name: 'Cactus', q: 'family=Cactaceae', checked: false },
          { name: 'Bromelias, labiadas y pasifloras', q: 'family=Bromeliaceae&family=Labiatae&family=Passifloraceae', checked: false },
          { name: 'Fanerógamas', q: 'family=Chrysobalanaceae,Dichapetalaceae,Lecythidaceae', checked: false },
          { name: 'Helechos y afines', q: 'class=Polypodiopsida&class=Lycopodiopsida&class=Equisetopsida&class=Psilotopsida&class=Marattiopsida', checked: false },
          { name: 'Zamias', q: 'family=Zamiaceae', checked: false },
          { name: 'Musgos y afines', q: 'phylum=Bryophyta&phylum=Hepaticophyta&phylum=Anthocerophyta&phylum=Marchantiophyta', checked: false },
        ],
      },
    ],
    'Departamento *': [
      { name: 'Amazonas', q: 'department=CO-AMA', checked: false },
      { name: 'Antioquia', q: 'department=CO-ANT', checked: false },
      { name: 'Arauca', q: 'department=CO-ARA', checked: false },
      { name: 'Archipiélago de San Andrés, Providencia y Santa Catalina', q: 'department=CO-SAP', checked: false },
      { name: 'Atlántico', q: 'department=CO-ATL', checked: false },
      { name: 'Bogotá, D.C.', q: 'department=CO-DC', checked: false },
      { name: 'Bolívar', q: 'department=CO-BOL', checked: false },
      { name: 'Boyacá', q: 'department=CO-BOY', checked: false },
      { name: 'Caldas', q: 'department=CO-CAL', checked: false },
      { name: 'Caquetá', q: 'department=CO-CAQ', checked: false },
      { name: 'Casanare', q: 'department=CO-CAS', checked: false },
      { name: 'Cauca', q: 'department=CO-CAU', checked: false },
      { name: 'Cesar', q: 'department=CO-CES', checked: false },
      { name: 'Chocó', q: 'department=CO-CHO', checked: false },
      { name: 'Córdoba', q: 'department=CO-COR', checked: false },
      { name: 'Cundinamarca', q: 'department=CO-CUN', checked: false },
      { name: 'Guainía', q: 'department=CO-GUA', checked: false },
      { name: 'Guaviare', q: 'department=CO-GUV', checked: false },
      { name: 'Huila', q: 'department=CO-HUI', checked: false },
      { name: 'La Guajira', q: 'department=CO-LAG', checked: false },
      { name: 'Magdalena', q: 'department=CO-MAG', checked: false },
      { name: 'Meta', q: 'department=CO-MET', checked: false },
      { name: 'Nariño', q: 'department=CO-NAR', checked: false },
      { name: 'Norte de Santander', q: 'department=CO-NSA', checked: false },
      { name: 'Putumayo', q: 'department=CO-PUT', checked: false },
      { name: 'Quindío', q: 'department=CO-QUI', checked: false },
      { name: 'Risaralda', q: 'department=CO-RIS', checked: false },
      { name: 'Santander', q: 'department=CO-SAN', checked: false },
      { name: 'Sucre', q: 'department=CO-SUC', checked: false },
      { name: 'Tolima', q: 'department=CO-TOL', checked: false },
      { name: 'Valle del Cauca', q: 'department=CO-VAC', checked: false },
      { name: 'Vaupés', q: 'department=CO-VAU', checked: false },
      { name: 'Vichada', q: 'department=CO-VID', checked: false },
    ],
    'Multimedia': [
      { name: 'Imagen', q: 'multimedia=image', checked: false },
      { name: 'Video', q: 'multimedia=video', checked: false },
      { name: 'Sonido', q: 'multimedia=sound', checked: false },
    ],
    'Grupo temático': [
      {
        name: 'Amenaza global',
        groups: [
          { name: 'No evaluada (NE)', q: 'threatUICN=NE', checked: false },
          { name: 'Datos insuficientes (DD)', q: 'threatUICN=DD', checked: false },
          { name: 'Preocupación menor (LC)', q: 'threatUICN=LC', checked: false },
          { name: '', q: '', checked: false },
          { name: 'Casi amenazada (NT)', q: 'threatUICN=NT', checked: false },
          { name: 'Vulnerable (VU)', q: 'threatUICN=VU', checked: false },
          { name: 'En peligro (EN)', q: 'threatUICN=EN', checked: false },
          { name: 'En peligro crítico (CR)', q: 'threatUICN=CR', checked: false },
          { name: 'Extinto en vida silvestre (EW)', q: 'threatUICN=EW', checked: false },
          { name: 'Extinto (EX)', q: 'threatUICN=EX', checked: false },
        ],
      },
      {
        name: 'Amenaza nacional',
        groups: [
          { name: 'Vulnerable (VU)', q: 'threatMADS=VU', checked: false },
          { name: 'En peligro (EN)', q: 'threatMADS=EN', checked: false },
          { name: 'En peligro crítico (CR)', q: 'threatMADS=CR', checked: false },
        ],
      },
      {
        name: 'Usos',
        groups: [
          { name: 'Productos forestales no maderables', q: 'usos=PFNM', checked: false },
          { name: 'Otros usos', q: 'usos=Otros', checked: false },
        ],
      },
      {
        name: 'Evaluación de riesgo y extinción',
        groups: [
          { name: 'Evaluación de riesgo y extinción', q: 'assessment=Evaluación de riesgo y extinción', checked: false },
        ],
      },
      {
        name: 'CITES',
        groups: [
          { name: 'Apéndice I', q: 'threatCITES=Apéndice I', checked: false },
          { name: 'Apéndice II', q: 'threatCITES=Apéndice II', checked: false },
          { name: 'Apéndice III', q: 'threatCITES=Apéndice III', checked: false },
        ],
      },
      {
        name: 'Exótica',
        groups: [
          { name: 'Exótica', q: 'exotic=true', checked: false },
          { name: 'Exótica con alto riesgo de Invasión', q: 'invasive=true', checked: false },
        ],
      },
      // {
      //   name: 'Endémicas',
      //   groups: [
      //     { name: 'Aves', q: 'class=Aves&endemic=Colombia', checked: false },
      //     { name: 'Peces de agua dulce', q: 'class=Actinopterygii&class=Actinopteri&class=Sarcopterygii&class=Elasmobranchii&class=Chondrichthyes&endemic=Colombia', checked: false },
      //     { name: 'Mamíferos', q: 'class=Mammalia&endemic=Colombia', checked: false },
      //   ],
      // },
      {
        name: 'Ecosistema',
        groups: [
          { name: 'Páramos', q: 'habitat=Páramos', checked: false },
          { name: 'Humedales', q: 'habitat=Humedales_Ciénagas', checked: false },
        ],
      },
    ],
  };


  handleCheck(e, d) {
    e.preventDefault();
    const { search, filters } = this.state;
    // console.log('d', d);
    // const item = findIndex(search, (o) => { return o.name === d.name; });
    // console.log('item', item);
    // if (item >= 0) {
    //   this.search.splice(item, 1);
    // } else {
    //   this.search.unshift(d);
    // }
    // let query = '';
    // forEachRight(this.search, (value, key) => {
    //   if (key === this.search.length - 1) {
    //     query += value.q;
    //   } else {
    //     query += `&${value.q}`;
    //   }
    // });
    const searchFilters = search.filter((item) => item.q === d.q);
    const newSearchFilters = (searchFilters.length > 0) ? search.filter((item) => item.q !== d.q) : [{ ...d, checked: !d.checked }, ...search];
    const query = newSearchFilters.reduce((param, { q }) => {
      return (param === '') ? q : param + '&' + q;
    }, '');

    Object.keys(filters).map(key => {
      filters[key] = filters[key].map((filter) => {
        if (typeof filter.groups !== 'undefined') {
          return {
            ...filter,
            groups: filter.groups.map((item) => {
              if(d.q === item.q) {
                return {
                  ...item,
                  checked: !item.checked,
                };
              } else {
                return item;
              }
            }),
          };
        } else if(d.q === filter.q) {
          return {
            ...filter,
            checked: !filter.checked,
          };
        } else {
          return filter;
        }
      });
    });

    window.history.replaceState('', '', query !== '' ? `/search/full?${query}` : '/');
    this.setState({ url: query ? `/search/full?${query}` : '', search: newSearchFilters, filters });

    // if(searchFilters.length > 0) {
    //   document.getElementById(e.target.id).removeAttribute('checked');
    // } else {
    //   console.log('entro por aca ');
    //   document.getElementById(e.target.id).setAttribute('checked', 'checked');
    // }
  }

  handleReset(e) {
    this.setState({ url: '', search: [], filters: Object.assign({}, this.filtersIni) });
    window.history.replaceState('', '', '/');
    e.preventDefault();
  }

  constructor(props) {
    super(props);
    this.state = { url: '', search: [], filters: Object.assign({}, this.filtersIni) }
  }

  componentDidMount() {
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.match.params.category !== this.props.match.params.category) {
  //     this.fetchCategoriesNews()
  //   }
  // }

  render() {
    const { url, filters } = this.state;
    return (
      <div id="navbar-menu" className="uk-modal-container uk-flex-top" data-uk-modal>
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <button className="uk-modal-close-default" type="button" data-uk-close />
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Filtros de búsquedas</h2>
          </div>
          <div className="uk-modal-body uk-column-1-2@s uk-column-1-4@m uk-column-divider">
            {
              Object.keys(filters).map(key => {
                return (<div key={nanoid()} className="uk-flex uk-flex-column">
                  <div className={`uk-flex uk-flex-column uk-margin-small ${key !== 'Taxonomía' && 'uk-margin-top'}`}>
                    <h5 className="uk-margin-small-left uk-margin-small uk-text-bold">{key}</h5>
                    <div className="uk-width-1-4 uk-background-primary" style={{ height: 2 }} />
                  </div>
                  {
                    filters[key].map((filter) => {
                      if (typeof filter.groups !== 'undefined') {
                        return (
                          <div key={nanoid()} className="uk-flex uk-flex-column uk-margin-small">
                            <span className="uk-text-bold uk-margin-small">{filter.name}</span>
                            {
                              filter.groups.map((item) => {
                                if(item.name !== '' && item.checked) {
                                  return (<label key={nanoid()}><input id={nanoid()} className="uk-checkbox" type="checkbox" value={item.q} onChange={(e) => this.handleCheck(e, item)} checked /> {item.name} </label>);
                                } else if(item.name !== '' && !item.checked) {
                                  return (<label key={nanoid()}><input id={nanoid()} className="uk-checkbox" type="checkbox" value={item.q} onChange={(e) => this.handleCheck(e, item)} /> {item.name} </label>);
                                } else {
                                  return (<br key={nanoid()} />);
                                }
                              })
                            }
                          </div>);
                      } else if(filter.name !== '' && filter.checked) {
                        return (<label key={nanoid()}><input id={nanoid()} className="uk-checkbox" type="checkbox" value={filter.q} onChange={(e) => this.handleCheck(e, filter)} checked /> {filter.name} </label>);
                      } else if(filter.name !== '' && !filter.checked) {
                        return (<label key={nanoid()}><input id={nanoid()} className="uk-checkbox" type="checkbox" value={filter.q} onChange={(e) => this.handleCheck(e, filter)} /> {filter.name} </label>);
                      } else {
                        return (<br key={nanoid()} />);
                      }
                    })
                  }
                </div>);
              })
            }
          </div>
          <div className="uk-modal-footer uk-text-right">
            <button className="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Cancelar</button>
            <button className="uk-button uk-button-default uk-margin-small-right" type="button" onClick={(e) => this.handleReset(e)}>Limpiar</button>
            {url && <a className="uk-button uk-button-primary uk-text-bold" href={url} >Buscar</a>}
          </div>
        </div>
      </div>
    );
  }
}

AdvancedSearch.id = '#navbar-menu';

export default AdvancedSearch;
