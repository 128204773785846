import { fromJS } from 'immutable';
import * as FiltersActions from '../typesActions';

const INITIAL_STATE = fromJS({
  isLoading: false,
  error: '',
  filters: {
    query: '',
    count: 0,
    search: [],
  },
  data: [],
});

const filtersReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FiltersActions.FILTERS_SEARCH_INIT: {
      return state
        .set('isLoading', true)
        .set('error', '');
    }

    case FiltersActions.FILTERS_SEARCH_SUCCESS: {
      console.log('reducer FETCHING_IUCN_SUCCESS', payload.data);
      let { data } = payload;
      data = ((data === null) || (typeof data === 'undefined')) ? [] : data;
      return state
        .set('isLoading', false)
        .set('data', data)
        .set('error', '');
    }

    case FiltersActions.FILTERS_SEARCH_ERROR: {
      return state
        .set('isLoading', false)
        .set('data', payload);
    }

    case FiltersActions.FILTERS_CHANGE: {
      const { value } = payload;
      const filters = (typeof state.get('filters').query === 'undefined') ? state.get('filters').toJS() : state.get('filters');
      console.log('reducer FILTERS_CHANGE', value);
      let newSearch = filters.search;
      const esta = filters.search.filter(({ id }) => id === value.id).length;
      if( esta === 1 ) {
        newSearch = filters.search.filter(({ id }) => id !== value.id);
      } else if( esta === 0 ) {
        newSearch = [ ...filters.search, { id: value.id } ];
      }
      const newQuery = newSearch.reduce((acum, { id }) => {
        return (acum === '') ? id : acum + '&' + id;
      }, '');

      return state
        .set('filters', { ...filters, query: newQuery, search: newSearch, count: newSearch.length });
    }

    default: {
      return state;
    }
  }
};

export default filtersReducer;
