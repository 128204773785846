/* eslint-disable react/prop-types */
import React, { Component } from 'react';

class FText extends Component {

  constructor(props) {
    super(props);
    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(event) {
    this.props.onDataChange({ path: this.props.path, value: event.target.value });
  }

  render() {
    return (
      <div>
        <h5>{this.props.value.title}</h5>
        <textarea className="uk-textarea" onKeyUp={this.updateValue}></textarea>
      </div>
    );
  }
}

export default FText;
