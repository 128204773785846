import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Style Components */
import { Paragraph, TableStyled } from '../../pages/styled';

const Distribuccion = ({ data }) => {
  const { distribution } = data;
  const { country, range, elevationLower, elevationUpper, eoo, aoo, locationsNumber } = distribution;

  return (
    <div className="uk-margin">
      <div id={'distribuccion'} className={'uk-card uk-card-default uk-panel uk-padding uk-text-break'}>
        <div className="uk-flex uk-flex-column">
          <span className={'uk-h3 uk-text-tertiary uk-text-bold'}>{'Distribución'}</span>
        </div>

        <div className="uk-padding-small type_hierarchy">
          <div className="uk-child-width-1-1">
            <li>
              <div className="uk-child-width-1-3 uk-grid" data-uk-grid="">
                <span className="uk-text-bold uk-first-column">País de presencia:</span>
                <span>{country}</span>
              </div>
            </li>
            <li>
              <div className="uk-child-width-1-2 uk-grid" data-uk-grid="">
                <span className="uk-text-bold uk-first-column">Descripción del rango:</span>
              </div>
            </li>
            <Paragraph>{range}</Paragraph>
          </div>
        </div>

        <div className="uk-flex uk-flex-column">
          <div className="uk-overflow-auto uk-margin-bottom">
            <table className="uk-table uk-table-striped">
              <thead className="head">
                <tr>
                  <th uk-tooltip="Extensión de la presencia (EOO) es una medida estandarizada del área dentro del cual existen todas las ocurrencias de un ecosistema tipo.">Extensión de presencia (EOO) km2</th>
                  <th uk-tooltip="El área de ocupación (AOO) es una medida estandarizada del área que es ocupada por un ecosistema tipo.">Área de ocupación (AOO) km2</th>
                  <th>Elevación mínima en metros</th>
                  <th>Elevación máxima en metros</th>
                </tr>
              </thead>
              <tbody className="body">
                <tr>
                  <td>{eoo && eoo.toLocaleString()}</td>
                  <td>{aoo && aoo.toLocaleString()}</td>
                  <td>{elevationLower && elevationLower.toLocaleString()}</td>
                  <td>{elevationUpper && elevationUpper.toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="uk-padding-small type_hierarchy">
          <div className="uk-child-width-1-1">
            <li>
              <div className="uk-child-width-1-2 uk-grid" data-uk-grid="">
                <span
                  className="uk-text-bold uk-first-column"
                  uk-tooltip="Grupos distintivos en la población (global), ya sea geográficamente o por otro criterio, y entre los cuales existen escasos intercambios demográficos o genéticos (típicamente, uno o menos individuos o gametos migratorios exitosos al año) (UICN 2001, 2012); una subpoblación puede, o no, estar restringida a una región."
                >
                  Subpoblaciones:
                </span>
                <span>{locationsNumber}</span>
              </div>
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

Distribuccion.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.get('iucnReducer').get('data'),
});


export default connect(
  mapStateToProps,
  null
)(withRouter(Distribuccion));
