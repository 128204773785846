/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import dayjs from 'dayjs';

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

import Home from '../pages/Home';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Recover from '../pages/Recover';
import Reset from '../pages/Reset';
import Search from '../pages/Search';
import File from '../pages/File';
import Edit from '../pages/Edit';
import FileLegacy from '../pages/FileLegacy';
import Album from '../pages/Album';
import Organization from '../pages/Organization';
import User from '../pages/User';

import Admin from '../pages/Admin';
import Profile from '../pages/Profile';

import Static from '../pages/Static';
import NotFound from '../pages/NotFound';

import store from '../store';
import 'dayjs/locale/es';

UIkit.use(Icons);

dayjs.locale('es');

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <Router>
          <GlobalStyle />
          <Switch>
            <Route exact path="/" exact component={Home} />
            <Route exact path="/oauth/sign-in" component={SignIn} />
            <Route exact path="/oauth/sign-up" component={SignUp} />
            <Route exact path="/oauth/recover" component={Recover} />
            <Route exact path="/oauth/reset/:id" component={Reset} />
            <Route exact path="/admin" component={Admin} />
            <Route path="/desktop/:tab?" component={Profile} />
            <Route path="/search/:type" component={Search} />
            <Route path="/file/:id/:tab?" component={File} />
            <Route path="/edit/:id/:element" component={Edit} />
            <Route path="/fichas/:id" component={FileLegacy} />
            <Route path="/album/:id" component={Album} />
            <Route path="/organization/:id" component={Organization} />
            <Route path="/user/:username/:tab?" component={User} />
            <Route path="/static/:id" component={Static} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
