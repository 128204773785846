/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { map, compact, flattenDeep, findIndex } from 'lodash';

import { T } from '../../../services/UtilService';

class ArrayListText extends Component {
  render() {
    return (
      <div>Hello from ArrayListText!</div>
    );
  }
}

function util(k, xs) {
  const rs = map(xs, (x, key) => {
    const ignorar = ['_id', 'ancillaryData'];
    let lista = map(x, (d, k) => {
      if ((findIndex(ignorar, (i) => { return (i === k); })) === -1) return k;
    });
    lista = compact(flattenDeep(lista));

    const r = map(lista, (l, k) => {
      if(typeof x[l] === 'string' && x[l] !== '')
        return (<div key={k}><h3>{T(l)}</h3><p>{x[l]}</p></div>);
    });
    return <div>{r}<hr className="uk-divider-icon"/></div>;
  });

  return (<div>{rs}</div>);
}

ArrayListText.util = util;

export default ArrayListText;
