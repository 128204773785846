/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';

import GenericPage from '../components/templates/GenericPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';

import Summary from '../components/organisms/album/Summary';
import Species from '../components/organisms/album/Species';
import Community from '../components/organisms/album/Community';

import Slider from '../components/molecules/Slider';
import CommentList from '../components/organisms/CommentList';
import Loading from '../components/atoms/Loading';

import * as UtilService from '../services/UtilService';
import * as AlbumService from '../services/AlbumService';

class Album extends Component {
  constructor() {
    super();
    this.state = {
      select: 0,
      related: null,
      data: null,
    };
  }

  componentWillMount() {
    AlbumService.getId(this.props.match.params.id).then(data => {
      // console.log('Consultando album');
      // console.log(data);
      this.setState({ data });
    });

    this.activeTab(this.props.match.params.tab);
  }

  activeTab(tab, changeUrl = true) {
    let tabIndex, title, url;
    switch (tab) {
      case 'details':
        tabIndex = 1;
        title = 'Especies';
        url = '/album/' + this.props.match.params.id + '/species';
        break;
      case 'Comunidad':
        tabIndex = 2;
        title = 'Multimedia';
        url = '/album/' + this.props.match.params.id + '/community';
        break;

      case 'summary':
      default:
        tabIndex = 0;
        title = 'Resumen';
        url = '/album/' + this.props.match.params.id + '/summary';
        break;
    }

    changeUrl && window.history.replaceState({}, title + '- Catálogo de la biodiversidad', url + this.props.location.search);
    this.setState({ select: tabIndex });
  }

  getAmenaza(data){
    let x = UtilService.route(data, ['threatStatusApprovedInUse', 'threatStatus', 0, 'threatStatusAtomized', 'threatCategory', 'measurementValue']);
    x = x.replace(')', '');
    const ps = x.split(' (');

    return ps;
  }

  render() {
    return (<div>
      {(this.state.data && <GenericPage
        titlep="Catálogo de la biodiversidad"
        header={<Header />}
        footer={<Footer />}
        background={UtilService.route(this.state.data, ['imagen'])}
      >
        <div className="uk-background-linear uk-section">
          <div className="uk-container uk-width-5-6@l uk-width-2-3@xl" style={{ marginTop: '60vh' }}>
            <div className="uk-flex uk-flex-right">
              <div className="uk-width-1-5 uk-margin-small uk-overlay-primary" style={{ padding: '5px 10px' }}>&copy; Ivo Antusek</div>
            </div>
            <div className="uk-card uk-card-default">
              <div className="uk-card-body">
                <i>
                  <span className="uk-text-tertiary uk-margin-remove uk-h1">{UtilService.route(this.state.data, ['nombre'])}</span>
                </i>
              </div>
              {
                this.state.data.referencia &&
                <div className="uk-overlay uk-padding-remove uk-position-top-right">
                  <span className="uk-background-primary uk-text-default uk-text-bold uk-text-small" style={{ padding: 3, marginRight: 1 }}>
                             ★
                  </span>
                  <span className="uk-background-primary uk-text-default uk-text-bold uk-text-small" style={{ padding: 3 }}>
                    REFERENCIA
                  </span>
                </div>
              }
              <div className="uk-card-footer">
                <ul data-uk-switcher={`connect: .uk-switcher; active: ${this.state.select}`} data-uk-grid="">
                  <button onClick={() => this.activeTab('summary')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 0 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Resumen</button>
                  <button onClick={() => this.activeTab('details')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 1 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Especies</button>
                  <button onClick={() => this.activeTab('multimedia')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 2 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Comunidad</button>
                </ul>
              </div>
            </div>
          </div>
          <ul className="uk-switcher">
            <Summary data={this.state.data} />
            <Species data={this.state.data} />
            <Community data={this.state.data} />
          </ul>
          <div className="uk-section uk-padding-remove uk-background-tertiary uk-margin-top uk-flex uk-flex-center">
            <span className="uk-text-default" style={{ margin: 5 }} uk-icon="icon: bolt; ratio: 1.5"></span>
          </div>
          <div className="uk-section uk-section-small">
            <div className="uk-container uk-width-5-6@l uk-width-2-3@xl">
              <div className="uk-flex uk-flex-column uk-margin">
                <h2 className="uk-margin-left">
                  Fichas relacionadas
                </h2>
                <div className="uk-width-1-4 uk-width-small@s uk-background-primary" style={{ height: 2 }} />
              </div>
              <Slider data={this.state.related} />
            </div>
          </div>
          <div className="uk-section uk-section-small">
            <div className="uk-container uk-width-5-6@l uk-width-2-3@xl">
              <div className="uk-flex uk-flex-column uk-margin">
                <h2 className="uk-margin-left">
                  Comentarios
                </h2>
                <div className="uk-width-1-4 uk-width-small@s uk-background-primary" style={{ height: 2 }} />
              </div>
              <CommentList />
            </div>
          </div>
        </div>
      </GenericPage>) || <div className="uk-flex uk-flex-center uk-flex-middle uk-padding uk-text-default" data-uk-height-viewport="offset-top: true"><Loading /></div>}
    </div>
    );
  }
}

export default Album;
