import React, { Component } from 'react';

class CommentItem extends Component {
  render() {
    return (
      <article className="uk-comment uk-card uk-card-default uk-padding-small uk-visible-toggle">
        <header className="uk-comment-header uk-grid-medium uk-flex-middle" data-uk-grid="">
          <div className="uk-width-auto">
            <img width="80" src="http://localhost:3000/static/css/images/slide.png" alt="" />
          </div>
          <div className="uk-width-expand">
            <h4 className="uk-comment-title uk-margin-remove"><a className="uk-link-reset">Author</a></h4>
            <ul className="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
              <li><a>@author</a></li>
              <li><a>12 days ago</a></li>
            </ul>
          </div>
          <div className="uk-position-top-right uk-position-small uk-hidden-hover"><a className="uk-link-muted">Responder</a></div>
        </header>
        <div className="uk-comment-body">
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        </div>
      </article>
    );
  }
}

export default CommentItem;
