/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { map, isEmpty, sum, isArray, join } from 'lodash';

import Taxonomy from './filters/Taxonomy';
import Location from './filters/Location';
import Thematic from './filters/Thematic';
import Multimedia from './filters/Multimedia';
import GruposTematicos from './filters/GruposTematicos';
/*
import TaxonRank from './filters/TaxonRank';
import Habitat from './filters/Habitat';
import Elevation from './filters/Elevation';
import Depth from './filters/Depth';
import BasisOfRecord from './filters/BasisOfRecord';
import EventDate from './filters/EventDate';
import RecordedBy from './filters/RecordedBy';
import OccurrenceId from './filters/OccurrenceId';
import ProviderName from './filters/ProviderName';
import Project from './filters/Project';
import ResourceName from './filters/ResourceName';
import License from './filters/License';
import Doi from './filters/Doi';
import StrategicEcosystems from './filters/StrategicEcosystems';
import ProtectedAreas from './filters/ProtectedAreas';
import BioticRegions from './filters/BioticRegions';
import Cars from './filters/Cars';
import Multimedia from '../molecules/filters/Multimedia';
import SearchMap from './filters/SearchMap';

import GeoPais from './filters/GeoPais';
import GeoDepartamento from './filters/GeoDepartamento';
import GeoMunicipio from './filters/GeoMunicipio';
import GeoRegiones  from './filters/GeoRegiones';
*/
class Filters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clean: false,
      search: props.search,
    };
    this.child = [];
  }

  cleanAll() {
    window.location.assign('/search/basic');
    /*
    map(this.child, (value, key) => {
      if (value.state.data !== null)
        value.cleanFilters()
    })
    */
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.search !== this.props.search) {
      this.setState({ search: nextProps.search });
    }
  }

  render() {
    const { filters } = this.props;
    const { count } = filters;
    return (
      <div>
        <div className="uk-heading-divider uk-padding-small">
          <div className="uk-grid-small uk-flex-between uk-flex-middle" data-uk-grid="">
            <div className="uk-text-bold">Filtros de búsqueda</div>
            <div
              style={{ cursor: 'pointer' }}
              title="Limpiar filtros"
              data-uk-tooltip="Limpiar filtros"
              onClick={() => { this.cleanAll(); }}
            >
              <span data-uk-icon="icon: trash; ratio: 1.3" />
              {count > 0 &&
                <span className="uk-badge uk-position-top-right" style={{ marginTop: 7, marginRight: 1 }}>
                  {count}{/* {sum(Object.values(this.props.count))} */}
                </span>
              }
            </div>
          </div>
        </div>
        {map(this.props.children, (value, key) =>
          <value.type key={key} onRef={ref => (this.child[key] = ref)} search={this.state.search} {...value.props} />
        )}
      </div>
    );
  }
}

class Base extends Component {

  constructor() {
    super();
    this.state = {
      open: false,
      params: null,
    };

    this.handleDrop = this.handleDrop.bind(this);
  }

  componentWillMount() {
    if (this.props.expand && this.props.expand === true) {
      this.setState({ open: true });
    }
    // TODO: Quitar validación de propiedad opcional
    this.props.onRef && this.props.onRef(this);
  }


  handleDrop(open) {
    this.setState({
      open,
    });
  }

  render() {
    return (
      <div>
        <div className={cx('uk-padding-small', { 'uk-heading-divider': !this.state.open })} onClick={() => this.handleDrop(!this.state.open)} style={{ cursor: 'pointer' }}>
          <div className="uk-grid-small uk-flex-between" data-uk-grid="">
            <div>{this.props.title}</div>
            <div><span data-uk-icon={`icon: ${this.state.open ? 'triangle-up' : 'triangle-down'}`}></span></div>
          </div>
        </div>
        {this.props.handlerFilter && !isEmpty(this.props.handlerFilter) &&
          <div className="uk-padding-small uk-heading-divider uk-child-width-1" style={{ backgroundColor: '#f1f8f7' }} data-uk-grid>
            {
              map(this.props.handlerFilter, (value, key) =>
                <label
                  key={key}
                  onClick={(e) => { e.preventDefault(); this.props.func(value); }}
                  style={{ cursor: 'pointer', marginBottom: 5 }}
                  className="uk-grid-collapse uk-flex-between uk-flex-middle uk-text-small"
                  data-uk-grid
                >
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    defaultChecked={true}
                  />
                  <span className="uk-width-3-5 uk-text-truncate uk-margin-small-left" title={value.label}>{value.label}</span>
                  <span className="uk-width-expand uk-text-right">{isArray(value.value) ? join(value.value, ' - ') : value.value}</span>
                </label>
              )
            }
          </div>
        }
        {this.state.open &&
          <div className={cx('uk-padding-small', { 'uk-padding-remove-top': this.state.params })} style={{ backgroundColor: '#f4f4f4' }} data-uk-margin>
            {this.props.children}
          </div>
        }
      </div>
    );
  }
}

Filters.Base = Base;
Filters.Taxonomy = Taxonomy;
Filters.Location = Location;
// Filters.Thematic = Thematic;
Filters.Multimedia = Multimedia;
Filters.GruposTematicos = GruposTematicos;
/*
Filters.TaxonRank = TaxonRank;
Filters.Habitat = Habitat;
Filters.Elevation = Elevation;
Filters.Depth = Depth;
Filters.BasisOfRecord = BasisOfRecord;
Filters.EventDate = EventDate;
Filters.RecordedBy = RecordedBy;
Filters.OccurrenceId = OccurrenceId;
Filters.ProviderName = ProviderName;
Filters.Project = Project;
Filters.ResourceName = ResourceName;
Filters.License = License;
Filters.Doi = Doi;
Filters.StrategicEcosystems = StrategicEcosystems;
Filters.ProtectedAreas = ProtectedAreas;
Filters.BioticRegions = BioticRegions;
Filters.Cars = Cars;
Filters.Multimedia = Multimedia;
Filters.SearchMap = SearchMap;

Filters.GeoPais = GeoPais;
Filters.GeoDepartamento = GeoDepartamento;
Filters.GeoMunicipio = GeoMunicipio;
Filters.GeoRegiones = GeoRegiones;*/

const mapStateToProps = state => {
  const filters = (typeof state.get('filtersReducer').get('filters').query === 'undefined') ? state.get('filtersReducer').get('filters').toJS() : state.get('filtersReducer').get('filters');
  return {
    filters,
  };
};

// const mapDispatchToProps = dispatch => ({
//   onFiltersFormChange: (name, value) => dispatch(filtersFormChange(name, value)),
//   onFiltersRequest: () => dispatch(filtersRequest()),
// });

export default connect(
  mapStateToProps,
  null
)(withRouter(Filters));
