import fetch from 'isomorphic-fetch';

import { URL_API_PARTICIPATION } from '../config/const';

export function getId(id) {
  return fetch(`${URL_API_PARTICIPATION}/api/organizacion/${id}`, {
    method: 'GET',
  }).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}


export function getByUser(id) {
  return fetch(`${URL_API_PARTICIPATION}/api/organizacion/user/${id}`, {
    method: 'GET',
  }).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}
