import fetch from 'isomorphic-fetch';

import { URL_API } from '../config/const';
import { getToken } from '../auth';
import {THUMBNAIL_IMAGE_SERVICE} from '../config/const'

export function getList(path, busqueda, limite) {
  if (busqueda === '') {
    busqueda = 'kingdom=animalia&kingdom=plantae&kingdom=fungi&kingdom=bacteria&kingdom=archaea';
  }

  console.log("query final --->",`${URL_API}/record_search/${path}?${busqueda}&size=${limite}`)
  return fetch(`${URL_API}/record_search/${path}?${busqueda}&size=${limite}`, { method: 'GET' }).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}


function http(method, data) {
  const headers = {
    'Content-Type': 'application/json',
  };

  const token = getToken();
  if (token) headers['Authorization'] = token;

  const config = {
    method,
    headers,
    body: JSON.stringify(data),
  };

  return config;
}

export function getCount(path, busqueda) {

  return fetch(`${URL_API}/record_search/${path}?count=true&${busqueda}`, http('GET')).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}

export function getLastRecords() {
  console.log("getLastRecords",`${URL_API}/last_updated_records/`)
  return fetch(`${URL_API}/last_updated_records/`, http('GET')).then((response) => {
    console.log(response)
    return response.json();
  }).then((data) => {
    return data;
  });
}

export function getRelatedRecords(family) {
  return fetch(`${URL_API}/record_search/advanced_search?family=` + family + '&size=5').then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}

export function getLegacyById(id) {
  return fetch(`${URL_API}/record/legacy_id/` + id, http('GET')).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}

export function getRecordById(id) {
  return fetch(`${URL_API}/complete-record/` + id, http('GET')).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}

export function getThumb(img) {
  const ps = img.split('.');
  if( ps[ps.length - 1] === 'svg' ) {
    return img;
  }

  return 'https://miniaturas.biodiversidad.co/400x,q60/' + encodeURI(img) + '';
}

export function setField(id, body, field) {
  return fetch(`${URL_API}/record/${id}/${field}`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(body),
  }).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}

export function getField(id, field, version) {
  return fetch(`${URL_API}/record/${id}/${field}/${version}`, {
    method: 'GET',
  }).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}

export function updateVersion(id, field, version, body) {
  return fetch(`${URL_API}/record/approved_in_use/${id}/${field}/${version}`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
    body: JSON.stringify(body),
  }).then((response) => {
    return response.json();
  }).then((data) => {
    return data;
  });
}

