/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import Search from '../molecules/Search';
import { URL_LOGO } from '../../config/const';

import { logout, getUser, isAdmin } from '../../auth';

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      me: null,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  toggleMenu() {
    this.props.menu();
  }

  componentWillMount() {
    const me = getUser();
    // console.log("Intentando actualizar me con ", me)
    if (me) {
      this.setState({ me });
    }
  }

  cerrar(e) {
    e.preventDefault();
    logout();
  }

  render() {
    return (
      <nav data-uk-navbar="mode: click">
        {
          this.props.sidebar && <div className="uk-animation-slide-left uk-background-tertiary uk-navbar-left">
            <button
              uk-toggle="target: #my-id"
              className="uk-margin-small-left uk-margin-small-right uk-navbar-toggle uk-text-default"
              type="button"
              uk-navbar-toggle-icon=""
              onClick={this.toggleMenu}
            />
          </div>
        }
        <div className="uk-margin-small-left uk-navbar-left uk-visible@s">
          <button className="uk-navbar-toggle uk-hidden@s" uk-navbar-toggle-icon="" />
          <Link className="uk-logo" to="/">
            <img src={URL_LOGO} alt="" style={{ maxWidth: 'none' }} />
          </Link>
        </div>
        <div className="uk-margin-small-left uk-navbar-center uk-hidden@s">
          <Link className="uk-logo" to="/">
            <img src={URL_LOGO} alt="" style={{ maxWidth: 'none' }} />
          </Link>
        </div>
        <Search />
        {!this.state.me &&
          <div className="uk-navbar-right">
            <button className="uk-navbar-toggle uk-hidden@m" uk-search-icon="" />
            <Search.Responsive />
            <ul className="uk-navbar-nav uk-visible@s uk-text-bold">
              <li className="uk-margin-small-right"><Link to="/search/basic" >Explorar</Link></li>
              {/*<li><Link to="/oauth/sign-up">Registrarse</Link></li>*/}
              {/*<li className="uk-margin-small-right"><Link to="/oauth/sign-in">Ingresar</Link></li>*/}
            </ul>
          </div>
        }
        {this.state.me &&
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav">
              <li><a>Explorar</a></li>
              <li className="uk-inline">
                <a><span data-uk-icon="icon: comments"></span></a>
                <div className="uk-margin-remove" data-uk-drop="mode: click">
                  <div className="uk-card uk-card-default uk-overflow-auto" style={{ maxHeight: 300 }}>
                    <table className="uk-table uk-table-divider uk-table-small uk-table-hover">
                      <tbody>
                        <tr>
                          <td><img className="uk-preserve-width" width="40" src="/static/css/images/slide.png" alt="" /></td>
                          <td className="uk-text-small ">
                            Brendan Lim - ll be in your neighborhood doing errands...<br />
                            <span className="uk-text-muted">Hace 2 minutos</span>
                          </td>
                        </tr>
                        <tr>
                          <td><img className="uk-preserve-width" width="40" src="/static/css/images/slide.png" alt="" /></td>
                          <td className="uk-text-small ">
                            Brendan Lim - ll be in your neighborhood doing errands...<br />
                            <span className="uk-text-muted">Hace 2 minutos</span>
                          </td>
                        </tr>
                        <tr>
                          <td><img className="uk-preserve-width" width="40" src="/static/css/images/slide.png" alt="" /></td>
                          <td className="uk-text-small ">
                            Brendan Lim - ll be in your neighborhood doing errands...<br />
                            <span className="uk-text-muted">Hace 2 minutos</span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="uk-text-center"><button className="uk-button uk-button-text uk-button-small">Ver todas</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </li>
              <li>
                <a>
                  <img className="uk-preserve-width" width="40" src="/static/css/images/slide.png" alt="" />
                  <span data-uk-icon="icon: triangle-down"></span>
                </a>
                <div className="uk-navbar-dropdown uk-margin-remove uk-padding-small">
                  <ul className="uk-nav uk-navbar-dropdown-nav">
                    {isAdmin() &&
                      <li><Link to="/admin"><span className="uk-margin-small-right" data-uk-icon="icon: cog"></span>Administración</Link></li>
                    }
                    <li><Link to="/desktop/profile"><span className="uk-margin-small-right" data-uk-icon="icon: user"></span>Perfil</Link></li>
                    <li><Link to="/desktop/notifications"><span className="uk-margin-small-right" data-uk-icon="icon: bell"></span>Notificaciones</Link></li>
                    <li className="uk-nav-divider"></li>
                    <li><Link to="" onClick={(e) => this.cerrar(e)}><span className="uk-margin-small-right" data-uk-icon="icon: sign-out"></span>Cerrar sesión</Link></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        }
      </nav>
    );
  }
}

export default Navbar;
