import { fromJS } from 'immutable';
import * as IucnActions from '../typesActions';

const INITIAL_STATE = fromJS({
  iucnForm: {
    id: '',
  },
  loading: false,
  error: '',
  data: {},
});

const iucnReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case IucnActions.FETCHING_IUCN_REQUEST: {
      return state
        .set('loading', true);
    }

    case IucnActions.FETCHING_IUCN_SUCCESS: {
      console.log('reducer FETCHING_IUCN_SUCCESS', payload.data);
      let { data } = payload;
      data = ((data === null) || (typeof data === 'undefined')) ? {} : data;
      return state
        .set('loading', false)
        .set('data', data);
    }

    case IucnActions.FETCHING_IUCN_FAILURE: {
      return state
        .set('loading', false)
        .set('error', payload.data);
    }

    case IucnActions.SET_IUCN_FORM_CHANGE: {
      const { name, value } = payload;
      console.log('reducer SET_IUCN_FORM_CHANGE', name, value);
      return state
        .set('iucnForm', { ...state.get('iucnForm').toJS(), [name]: value });
    }

    default: {
      return state;
    }
  }
};

export default iucnReducer;
