/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';

class OrganizationList extends Component {
  render() {
    const data = this.props;
    const largo = 200;

    return (<div>
      { map(data.organizations, (v, k)=>
        <div className="uk-grid-small" data-uk-grid="" key={k}>
          <div className="uk-width-1-3" >
            <Link to={'/organization/'+v._id}><img className="uk-width-1-1" src={v.logo} alt="" /></Link>
          </div>
          <div className="uk-width-2-3" key={k}>
            <Link to={'/organization/'+v._id}>
              <h4>{v.nombre}</h4>
              {v.descripcion.length>largo &&
              <p>{v.descripcion.substring(0, largo)} ...</p>
              }
              {v.descripcion.length<=largo &&
              <p>{v.descripcion}</p>
              }
            </Link>
          </div>
        </div>
      ) }
    </div>);
  }
}

export default OrganizationList;
