/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { USER } from '../config/const';

import GenericPage from '../components/templates/GenericPage';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import Form from '../components/organisms/file/Form';
import Slider from '../components/molecules/Slider';
import * as CatalogService from '../services/CatalogService';
import Loading from '../components/atoms/Loading';

import * as UtilService from '../services/UtilService';

class Edit extends Component {
  constructor() {
    super();
    this.state = {
      select: 0,
      related: null,
      data: null,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem(USER));
    const { match } = this.props;

    if (!user || !user._id) {
      const path = '/file/' + match.params.id + '/summary';
      window.location.href = path;
    }

    this.cargarPagina(match.params.element);
  }

  componentWillReceiveProps(nextProps) {
    this.cargarPagina(nextProps.match.params.element);

    const path = '/edit/' + nextProps.match.params.id + '/' + nextProps.match.params.element;
    window.location.href = path;
  }

  cargarPagina(id) {
    CatalogService.getRecordById(this.props.match.params.id).then(data => {
      this.setState({ data });

      CatalogService.getRelatedRecords(data.hierarchy[0].family).then(related => {
        this.setState({ related });
      });

      this.activeTab(id);
    });
  }

  activeTab(tab, changeUrl = true) {
    let tabIndex, title, url;
    switch (tab) {
      case 'summary':
        tabIndex = 0;
        title = 'Resumen';
        url = '/file/' + this.props.match.params.id + '/summary';
        this.props.history.push(url);
        break;
      case 'details':
        tabIndex = 1;
        title = 'Detalles';
        url = '/file/' + this.props.match.params.id + '/details';
        this.props.history.push(url);
        break;
      case 'multimedia':
        tabIndex = 2;
        title = 'Multimedia';
        url = '/file/' + this.props.match.params.id + '/multimedia';
        this.props.history.push(url);
        break;
      default:
        tabIndex = 3;
        title = 'Editar';
        url = '/edit/' + this.props.match.params.id + '/' + tab;
        break;
    }

    changeUrl && window.history.replaceState({}, title + '- Catálogo de la biodiversidad', url + this.props.location.search);
    this.setState({ select: tabIndex });
  }

  goTo(form) {
    const { history, match } = this.props;
    history.push('/edit/' + match.params.id + '/' + form);
  }

  getAmenaza(data) {
    let x = UtilService.route(data, ['threatStatusApprovedInUse', 'threatStatus', 0, 'threatStatusAtomized', 'threatCategory', 'measurementValue']);
    x = x.replace(')', '');
    const ps = x.split(' (');

    return ps;
  }

  render() {
    return (
      <div>
        {(this.state.data) ? (
          <GenericPage
            titlep="Catálogo de la biodiversidad"
            header={<Header />}
            footer={<Footer />}
            background={UtilService.route(this.state.data, ['imageInfo', 'mainImage'])}
          >
            <div className="uk-background-linear uk-section">
              <div className="uk-container uk-width-5-6@l uk-width-2-3@xl" style={{ marginTop: '60vh' }}>
                <div className="uk-flex uk-flex-right">
                  <div className="uk-width-1-5 uk-margin-small uk-overlay-primary" style={{ padding: '5px 10px' }}>&copy; {UtilService.route(this.state.data, ['imageInfo', 'rightsHolder'])}</div>
                </div>
                <div className="uk-card uk-card-default">
                  <div className="uk-card-body">
                    <i>
                      <span className="uk-text-tertiary uk-margin-remove uk-h1">{UtilService.route(this.state.data, ['taxonRecordNameApprovedInUse', 'taxonRecordName', 'scientificName', 'canonicalName', 'simple'])}</span>
                    </i>
                    <span className="uk-margin-left" style={{ fontWeight: 200 }}>{UtilService.route(this.state.data, ['taxonRecordNameApprovedInUse', 'taxonRecordName', 'scientificName', 'canonicalAuthorship', 'simple'])}</span>
                    {UtilService.route(this.state.data, ['taxonRecordNameApprovedInUse', 'taxonRecordName', 'scientificName', 'publishedln', 'simple']) !== '' &&
                      <span className="uk-color-primary uk-text-large" style={{ margin: '0px 5px' }} uk-icon="icon: info" title={UtilService.route(this.state.data, ['taxonRecordNameApprovedInUse', 'taxonRecordName', 'scientificName', 'publishedln', 'simple'])}></span>
                    }
                  </div>
                  <div className="uk-overlay uk-padding-remove uk-position-top-right">
                    {this.getAmenaza(this.state.data)[1] &&
                      <span className="uk-background-primary uk-text-default uk-text-bold uk-text-small" style={{ padding: 3, marginRight: 1 }}>
                        {this.getAmenaza(this.state.data)[1]}
                      </span>
                    }
                    {this.getAmenaza(this.state.data)[0] &&
                      <span className="uk-background-primary uk-text-default uk-text-bold uk-text-small" style={{ padding: 3 }}>
                        {this.getAmenaza(this.state.data)[0]}
                      </span>
                    }
                  </div>
                  <div className="uk-card-footer">
                    <ul data-uk-switcher={`connect: .uk-switcher; active: ${this.state.select}`} data-uk-grid="">
                      <button onClick={() => this.activeTab('summary')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 0 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Resumen</button>
                      <button onClick={() => this.activeTab('details')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 1 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Detalles</button>
                      <button onClick={() => this.activeTab('multimedia')} className={`uk-button uk-button-link uk-text-tertiary uk-text-bold ${this.state.select === 2 && 'uk-text-primary'}`} style={{ textDecoration: 'none' }}>Multimedia</button>
                      <div className="uk-inline">
                        <button type="button" className="uk-button uk-button-link uk-text-tertiary uk-active uk-text-primary uk-text-bold" style={{ textDecoration: 'none' }}>Editar</button>
                        <div uk-dropdown="" className="uk-dropdown uk-dropdown-bottom-left">
                          <ul className="uk-nav uk-dropdown-nav">
                            <li><a href="javascript:void(0);" onClick={ () => this.goTo('lifeForm') }>LifeForm</a></li>
                            <li><a href="javascript:void(0);" onClick={ () => this.goTo('invasiveness') }>Invasiveness</a></li>
                            <li><a href="javascript:void(0);" onClick={ () => this.goTo('behavior') }>Behavior</a></li>
                            <li><a href="javascript:void(0);" onClick={ () => this.goTo('dispersal') }>Dispersal</a></li>
                            <li><a href="javascript:void(0);" onClick={ () => this.goTo('legislation') }>Legislation</a></li>
                          </ul>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <ul className="uk-switcher">
                <div>a</div>
                <div>b</div>
                <div>c</div>
                <Form
                  data={this.state.data}
                  element={this.props.match.params.element}
                  id={this.props.match.params.id}
                  history={this.props.history}
                />
              </ul>
              <div className="uk-section uk-padding-remove uk-background-tertiary uk-margin-top uk-flex uk-flex-center">
                <span className="uk-text-default" style={{ margin: 5 }} uk-icon="icon: bolt; ratio: 1.5"></span>
              </div>
              <div className="uk-section uk-section-small">
                <div className="uk-container uk-width-5-6@l uk-width-2-3@xl">
                  <div className="uk-flex uk-flex-column uk-margin">
                    <h2 className="uk-margin-left">
                      Fichas relacionadas
                    </h2>
                    <div className="uk-width-1-4 uk-width-small@s uk-background-primary" style={{ height: 2 }} />
                  </div>
                  <Slider data={this.state.related} link={`/search/full?family=${this.state.data.hierarchy[0].family}`} />
                </div>
              </div>
            </div>
          </GenericPage>
        ) : ( <div className="uk-flex uk-flex-center uk-flex-middle uk-padding uk-text-default" data-uk-height-viewport="offset-top: true"><Loading /></div> )
        }
      </div>
    );
  }
}

export default connect(
  null,
  null
)(withRouter(Edit));
