import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div `
.boo-wrapper {
  text-align: center;
}

.linea {
  margin: 0 auto;
}

.no{
    font-size: 8rem;
    font-weight: 600;
    color: #666666;
}

.sorry {
  font-weight: 600;
}

.ilustracion {
    font-size: 0.7em;
    font-style: italic;
}
`;


class NotFoundBoo extends React.Component {

  render() {
    return (
      <Wrapper>
        <div className="container animated fadeIn uk-container-center">
          <div className="boo-wrapper">
            <h1 className="no">404</h1>
            <div className="linea uk-container-center uk-width-1-4 uk-width-small@s uk-background-primary" style={{ height: '2px' }}></div>
            <img src="/static/css/images/404.png" alt="404" />
            <p className="sorry">¡Lo sentimos!</p>
            <p>
              Esta página no existe. Por favor intenta de nuevo con nuestro buscador.
            </p>
            <p>
              Si el problema persiste, escríbenos a <a className="uk-text-primary" href="mailto:sib@humboldt.org.co">sib@humboldt.org.co</a>
            </p>
            <p className="ilustracion">
              Ilustración: Javier Gamboa - CC BY-SA 4.0
            </p>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default NotFoundBoo;
