/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { map } from 'lodash';
// import { T } from '../../services/UtilService';
import FString from '../atoms/FString';
import FNumber from '../atoms/FNumber';
import FText from '../atoms/FText';
import FBoolean from '../atoms/FBoolean';
import FList from '../atoms/FList';
import FDate from '../atoms/FDate';

class AutoForm extends Component {

  constructor(props) {
    super(props);
    this.updateData = this.updateData.bind(this);
  }

  updateData(data) {
    if (this.props.onDataChange) {
      this.props.onDataChange(data);
    }
  }

  render() {
    this.formData = {};
    let pathOrg = [];
    if (this.props.path !== undefined)
      pathOrg = this.props.path;

    return <div className="contenedor">
      <h3>{this.props.title}</h3>
      {map(this.props.type, (value, key) => {
        const path = pathOrg.slice(0);
        path.push(key);

        switch (value.type) {
          case 'object': return <AutoForm data={this.props.data} type={value.struct} path={path} onDataChange={ this.updateData } />;
          case 'string': return <FString data={this.props.data} value={value} key={key} path={path} onDataChange={ this.updateData } />;
          case 'number': return <FNumber data={this.props.data} value={value} key={key} path={path} onDataChange={ this.updateData } />;
          case 'text': return <FText data={this.props.data} value={value} key={key} path={path} onDataChange={ this.updateData } />;
          case 'boolean': return <FBoolean data={this.props.data} value={value} key={key} path={path} onDataChange={ this.updateData } />;
          case 'list': return <FList data={this.props.data} value={value} key={key} path={path} onDataChange={ this.updateData } />;
          case 'date': return <FDate data={this.props.data} value={value} key={key} path={path} onDataChange={ this.updateData } />;
          case 'array': return <div><h4>{value.title}</h4>{map(value.arr, (v, k) => (
            <div>
              <AutoForm data={this.props.data} type={v} key={k} path={path} onDataChange={ this.updateData } />
            </div>
          ))}</div>;
          default: return <div key={key}> Campo no encontrado {key} {value.type} </div>;
        }
      }
      )
      }</div>;
  }
}

export default AutoForm;
